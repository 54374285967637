import DataTable from "./../../../components/DataTable";
import { toDateTimeFormat } from "../../../utils";

export default function HoldTable(props) {
  const options = {
    columns: HoldTableColmns,
    dataSource: props.data,
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    sx: {
      height: 510,
    },
  };
  return <DataTable {...options}></DataTable>;
}

const HoldTableColmns = [
  {
    headerName: "Laboratory",
    field: "Laboratory",
    width: 120,
  },
  {
    headerName: "Tray",
    field: "Tray",
    minWidth: 55,
  },
  {
    headerName: "SampleNumber",
    field: "SampleNumber",
    minWidth: 130,
  },
  {
    headerName: "Hold",
    field: "HoldType",
    flex: 1,
    minWidth: 165,
  },
  {
    headerName: "Added",
    field: "Added",
    valueFormatter: ({ value }) => toDateTimeFormat(value),
    minWidth: 165,
  },
  {
    headerName: "Until",
    field: "HoldUntil",
    valueFormatter: ({ value }) => toDateTimeFormat(value),
    minWidth: 165,
  },
];
