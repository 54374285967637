import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";

function PaperComponent(props) {

  return (
    <Draggable
      handle="#draggable-dialog-title"
      // handle={"#" + props.children.props.value.titleId}
      cancel={'[className*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function AppDialog({
  open,
  maxWidth,
  sx,
  children,
  title,
  handleClose,
  fullWidth,
}) {

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      PaperComponent={PaperComponent}
      sx={sx}
      fullWidth={fullWidth}
      aria-labelledby="draggable-dialog-title"
      id="draggable-dialog-title"
    // id={title}
    >
      <DialogTitle
        style={{ cursor: "move" }}
      >
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item sx={{ alignSelf: "center" }}>
            <Typography> {title}</Typography>
          </Grid>
          <Grid item style={{ float: "right" }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              color="inherit"
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
}
