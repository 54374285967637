import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RootProvider } from "./rootContext";
import { customTheme } from "./customTheme";
import { ThemeProvider } from "@mui/material";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const pca = new PublicClientApplication(msalConfig);

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <ThemeProvider theme={customTheme}>
        <RootProvider>
          <App />
        </RootProvider>
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);
