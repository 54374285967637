import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import { FLEET_APIS } from "../../../common/apis";
import { useState } from "react";
import SearchComponentModels from "../../master-data/components-model/SearchComponentModels";

const steps = [
  "Select action",
  "Select replacement Component",
  "Action summary",
];

export default function ManageComponentModels({
  SelectedUnit: Unit,
  refreshGrid,
  setManageUnitOpen,
}) {

  const { post } = useFetch();

  const [activeStep, setActiveStep] = useState(0);
  const [SelectedUnit, setSelectedUnit] = useState({});
  const [skipped, setSkipped] = useState(new Set());
  const [processResponse, setProcessResponse] = useState("Process completed sccessfully.");
  const [selectedOption, setSelectedOption] = useState("deleteOriginal");

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 2) {

      PerformOperation();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  async function PerformOperation() {
    if (selectedOption == "deleteOriginal") {
      const data = {
        OriginalComponentMakeId: Unit.ComponentMakeId,
        ReplacementComponentMakeId: SelectedUnit.ComponentMakeId,
        ActionToTake: 2,
        Screen: "Component Model Management",
      };
      const result = await post(FLEET_APIS.MANAGE_COMPONENT, data);
      if (result.length > 2) {
        setProcessResponse(result);
      }
    } else {
      const data = {
        OriginalComponentMakeId: Unit.ComponentMakeId,
        ReplacementComponentMakeId: SelectedUnit.ComponentMakeId,
        ActionToTake: 1,
        Screen: "Component Model Management",
      };
      const result = await post(FLEET_APIS.MANAGE_COMPONENT, data);
      if (result.length > 2) {
        setProcessResponse(result);
      }
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSelectedUnit({});
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleRadioGroupChange = (event) => {
    setSelectedOption(event.target.value);
    //setSelectedOption(undefined)
  };

  const handleReset = () => {
    setActiveStep(0);
    setManageUnitOpen(false);
    refreshGrid();
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="deleteOriginal"
              name="manageAction"
              //value={selectedOption}
              onChange={handleRadioGroupChange}
            >
              <FormControlLabel
                value="deleteOriginal"
                control={<Radio />}
                label="Replace all instances of the Model with a different one, and DELETE the original model."
              />
              <FormControlLabel
                value="keepOriginal"
                control={<Radio />}
                label="Replace all instances of the Model with a different one, but KEEP the original model."
              />
            </RadioGroup>
          </FormControl>
        );
      case 1:
        return (
          <SearchComponentModels rowSelected={(row) => setSelectedUnit(row)} />
        );
      case 2:
        return (
          <>
            <TableContainer component={Paper} sx={{ mt: 6, mb: 4 }}>
              <Table
                sx={{ minWidth: 400 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightblue", color: "blue" }}
                    >
                      Source
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightgreen", color: "green" }}
                    >
                      Replacement
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Make
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightblue", color: "blue" }}
                    >
                      {Unit.ComponentMake}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightgreen", color: "green" }}
                    >
                      {SelectedUnit.ComponentMake}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Model
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightblue", color: "blue" }}
                    >
                      {Unit.ComponentModel}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightgreen", color: "green" }}
                    >
                      {SelectedUnit.ComponentModel}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      UnitMakeId
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightblue", color: "blue" }}
                    >
                      {Unit.ComponentMakeId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightgreen", color: "green" }}
                    >
                      {SelectedUnit.ComponentMakeId}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <Box
      sx={{
        padding: "10px",
        minWidth: "35vw",
        minHeight: "22vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stepper activeStep={activeStep} sx={{ mr: 2 }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: "-35%" }}>{processResponse}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button onClick={handleReset} sx={{ ml: "auto", mr: 1 }}>
              Close
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography className={ClassNames.instructions} sx={{ mt: 4 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              onClick={handleNext}
              disabled={activeStep === 1 && !SelectedUnit.ComponentMakeId}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
