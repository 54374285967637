const GetStepNames = (id) =>
  ({
    1: { long: "Register", mid: "Registration", short: "Reg" },
    2: { long: "Laboratory", mid: "Laboratory", short: "Lab" },
    3: { long: "Quality-Check", mid: "QualityCheck", short: "Qual" },
    4: { long: "Auto-Evaluate", mid: "AutoEvaluate", short: "Auto" },
    5: { long: "Evaluate", mid: "Evaluation", short: "Eval" },
    6: { long: "Approval", mid: "Approval", short: "Appr" },
    7: { long: "Exporting", mid: "Exporting", short: "Exp" },
    8: { long: "Completed", mid: "Completed", short: "Comp" },
  }[id] || { long: "Unknown", mid: "Unknown", short: "Unkwn" });
export default GetStepNames;
