import { Button, Grid, Typography } from "@mui/material"
import TextBox from "../../components/TextBox"
import { MANAGEMENT_APIS } from "../../common/apis";
import useFetch from "../../hooks/useFetch";
import { useEffect } from "react";
import { useState } from "react";

const EditBillingRates = ({ cancel }) => {

    const { get } = useFetch();
    const [internalCustomer, setInternalCustomer] = useState(0);
    const [externalCustomer, setExternalCustomer] = useState(0);

    const getBillingRates = async () => {
        const bills = await get(MANAGEMENT_APIS.BILLING_RATES)

        let ec = bills.filter(bill => bill.IsExternal === true);
        if (ec?.length > 0) {
            const val = ec[0]?.Cost
            setExternalCustomer(val)
        }
        let ic = bills.filter(bill => bill.IsExternal === false);
        if (ic?.length > 0) {
            const val = ic[0]?.Cost
            setInternalCustomer(val)
        }
    }

    const handleSaveClick = async () => {
        await get(`${MANAGEMENT_APIS.EDIT_BILLING_RATES}?InternalCost=${internalCustomer}&ExternalCost=${externalCustomer}`)
        cancel && cancel()
    }

    const handleChange = (e) => {
        let val = e.target.value;
        var regex = /[0-9]|\./;
        if (!regex.test(e.target.value)) { return null }
        if (val < 0) { return null; }
        if (val.length > 6) { return null }
        if (e.target.name === "internal") {
            setInternalCustomer(e.target.value)
        }
        if (e.target.name === "external") {
            setExternalCustomer(e.target.value)
        }
    }

    useEffect(() => {
        getBillingRates();
    }, [])

    return (
        <Grid
            container
            spacing={2}
            sx={{ minWidth: "30vw", minHeight: "25vh", }}
        >
            <Grid item xs={12} sx={{ padding: 1, }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography>Please provide the new billing rates (per hour)</Typography>
                        <Grid
                            container
                            spacing={2}
                            mt={1}  >
                            <Grid item xs={6}>
                                <TextBox
                                    name="internal"
                                    type="number"
                                    label="Internal Customer"
                                    placeholder="0"
                                    value={internalCustomer}
                                    onChange={handleChange}
                                    inputProps={{ min: 1, max: 999999 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextBox
                                    name="external"
                                    type="number"
                                    label="External Customer"
                                    placeholder="0"
                                    value={externalCustomer}
                                    onChange={handleChange}
                                    inputProps={{ min: 1, max: 999999 }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} >
                        <Button sx={{ mt: 1, float: "right" }} onClick={handleSaveClick}>Save</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EditBillingRates;