import { Box, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { Fragment } from "react";

export default function DropDown({
  id,
  name,
  label,
  value,
  selectOptions,
  errorMsg,
  required,
  sx,
  ...options
}) {
  return (
    <>
      <InputLabel htmlFor={id}>
        <Box sx={{ display: "inline", color: options?.labelColor || "" }}> {label}</Box>
        <Box sx={{ display: "inline", px: 0.5, float: "right", color: (options?.label2Color || "#a54aa5"), backgroundColor: options?.label2bgColor || "" }}> {options?.label2 || ""} </Box>
      </InputLabel>

      <Select
        {...options}
        id={id}
        name={name}
        value={value}
        displayEmpty
        error={!!errorMsg}
        sx={sx || {
          [`.MuiSelect-select`]: {
            backgroundColor: "#f9fb5840",
            color: "black",
            [`.MuiSvgIcon-root-MuiSelect-icon`]: {
              color: "black",
            },
          },
          [`.MuiSelect-icon`]: {
            color: "black",
          },
        }}
      >
        {!selectOptions.find((x) => x.value === 0) && !required && (
          <MenuItem value={0} key={"select-option-0"}>
            Select
          </MenuItem>
        )}
        {selectOptions.map((op) => (
          <MenuItem value={op.value} key={op.value}>
            {op.text}
          </MenuItem>
        ))}
      </Select >
      {errorMsg && (
        <FormHelperText error={!!errorMsg}>{errorMsg}</FormHelperText>
      )
      }
    </>
  );
}
