import { Card, Grid, Typography, } from "@mui/material";
import { useEffect, useState } from "react";
import AddCriticalLogsTable from "./AddCriticalLogsTable";
import useFetch from "./../../../../hooks/useFetch";
import { CRITICAL_LOGS_APIS } from "../../../../common/apis";
import FormFooter from "../../../../components/FormFooter";
import Form, { FormActions } from "../../../../components/Form";
import { useReducer } from "react";
import AppDialog from "./../../../../components/AppDialog";
import { Call, Check, Delete } from "@mui/icons-material";
import appResources from "../../../../app-resources/en/Resources.json";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { useRoot } from "../../../../rootContext";
import ConfirmModal from "../../../../components/ConfirmModal";
import { useWindowPopups } from "../../../../components/useWindowPopups";

export default function AddCriticalLogs({ dataObj }) {

  const { get, post } = useFetch();
  const { CanEvaluate, CanDoDataMaintenance } = useRoot();

  const [data, dispatch] = useReducer(EventReducer, dataObj);
  const [criticalLogData, setCriticalLogData] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const HandleNewWindow = (actionName, data) => {
    useWindowPopups(actionName, data)
  }

  const actions = {
    saveFluid: async function () {
      await post(CRITICAL_LOGS_APIS.SAVE_CRITICAL_LOG, {
        ...data,
        CriticalLogId: data.CriticalLogId ? data.CriticalLogId : null,
        SampleNumber: data.SampleNumber ? data.SampleNumber : "",
        IsWin: data.IsWin ? data.IsWin : false,
        Comments: data.Comments ? data.Comments : "",
        Contact: data.Contact ? data.Contact : "",
        Email: data.Email ? data.Email : "",
        Actions: data.CorrectiveAction ? data.CorrectiveAction : "",
      });

      dispatch({
        type: FormActions.RESET_FORM,
        payload: {
          SampleNumber: data.SampleNumber,
          _key: Date.now(),
        },
      });
      loadCriticalLogs();
    },

    newFluid: function () {
      dispatch({
        type: FormActions.RESET_FORM,
        payload: {
          SampleNumber: data.SampleNumber,
          _key: Date.now(),
        },
      });
    },

    deleteFluid: function () {
      setAlertTitle(appResources.DELETE_CRITICAL_LOG_ENTRY);
      setOpenDialog(true);
      setAlertMessage(appResources.DELETE_CRITICAL_LOG_CONFIRMATION_MSG);
    },

    alertDeleteFluid: function () {
      (async function () {
        try {
          await get(`${CRITICAL_LOGS_APIS.DELETE_CRITICAL_LOG}?CriticalLogId=${data.CriticalLogId}`);
          setOpenDialog(false);
          loadCriticalLogs();
          //  onPropChange(responseData);
        } catch (error) {
          console.err(error);
        }
      })();
      dispatch({
        type: FormActions.RESET_FORM,
        payload: {
          SampleNumber: data.SampleNumber,
        },
      });
      setOpenDialog(false);
    },

    contactClick: function () {
      HandleNewWindow("access-permission-overview", { SampleNumber: data?.SampleNumber, selectCheck: true, ScreenName: data?.SampleNumber })
    },
  };

  useEffect(function () {
    (async function () {
      let parseData = {};
      let formDatas = localStorage.getItem("add-critical-logs")

      if (formDatas !== null && formDatas !== "null" && formDatas !== undefined) {
        parseData = JSON.parse(formDatas)
        dispatch({
          payload: { SampleNumber: parseData?.SampleNumber, },
          type: EventActions.LOAD_DATA,
        });
        return loadCriticalLogs(parseData?.SampleNumber);

      }
      loadCriticalLogs();
    })();
  }, []);

  //Load critical logs
  async function loadCriticalLogs(sn) {
    const responseData = await get(`${CRITICAL_LOGS_APIS.CRITICAL_LOGS_SAMPLE}?SampleNumber=${sn || data.SampleNumber}`);
    setCriticalLogData(responseData);
  }

  //Row Select
  function rowsSelectHandler(rowData) {
    if (rowData) {
      dispatch({
        payload: {
          ...rowData,
          SampleNumber: data.SampleNumber,
          Contact: rowData.Contact,
          Telephone: rowData.Telephone,
          Comments: rowData.Comments,
          CorrectiveAction: rowData.CorrectiveAction,
          Email: rowData.Email,
          _key: Date.now(),
        },
        type: EventActions.LOAD_DATA,
      });
    }
  }

  function handleRowData(rowData) {
    dispatch({
      payload: {
        ...data,
        ...rowData,
        SampleNumber: data.SampleNumber,
        Contact: rowData.DisplayName,
        Email: rowData.Username,
        Telephone: rowData?.Mobile || rowData?.Telephone,
      },
      type: EventActions.LOAD_DATA,
    });
    localStorage.removeItem("apo-resp");
  }

  function handleStorage(event) {
    let tr = JSON.parse(localStorage.getItem("apo-resp-" + data?.SampleNumber))
    if (tr === undefined || tr === null) return;
    handleRowData(tr)
  }

  useEffect(() => {
    window.onstorage = (e) => { handleStorage(e); };
    return () => {
      window.onstorage = null;
    }
  })

  return (
    <>
      <Card sx={{ p: 1, minWidth: "90vw" }}>
        <Grid container>
          <Typography variant="subtitle2" gutterBottom >
            Add a critical log by providing the information below
          </Typography>
        </Grid>
        <Form
          sections={FormSections(data)}
          data={data}
          spacing={2}
          dispatch={dispatch}
          key={(data && data._key)}
        />
        {(CanEvaluate || CanDoDataMaintenance) && <FormFooter
          buttons={GetFormButtons(data, actions)}
          hideDivider
          gmt={1}
        />}
      </Card>

      {criticalLogData ? (
        <>
          <Typography variant="subtitle2">
            Total Records: <b> {criticalLogData && criticalLogData.length}</b>
          </Typography>
          <AddCriticalLogsTable
            actions={[
              {
                action: rowsSelectHandler,
                label: "Edit",
              },
            ]}
            data={criticalLogData}
            rowsSelected={rowsSelectHandler}
          />
        </>
      ) : (
        <Typography
          sx={{ textAlign: "center", fontWeight: "bold", marginY: "90px" }}
        >
          Loading...
        </Typography>
      )}
      <AppDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        title={alertTitle}
        maxWidth={"100vw"}
      >
        {alertTitle === "Delete Critical Log Entry" && (
          <ConfirmModal
            handleClose={() => {
              setOpenDialog(false);
            }}
            alertAction={actions.alertDeleteFluid}
            message={alertMessage}
            buttonText={"Delete"}
            buttonText2={"Cancel"}
          />
        )}
      </AppDialog>
    </>
  );
}
const GetFormButtons = (
  data,
  { contactClick, deleteFluid, newFluid, saveFluid }
) => [
    {
      variant: "outlined",
      label: "Contact",
      onClick: contactClick,
      startIcon: <Call />,
    },
    {
      variant: "outlined",
      label: "Delete",
      onClick: deleteFluid,
      startIcon: <Delete />,
      disabled: data && !data.CriticalLogId,
    },
    {
      variant: "outlined",
      label: "New",
      onClick: newFluid,
      startIcon: <PersonRemoveIcon />,
    },
    {
      label: "Save",
      onClick: saveFluid,
      startIcon: <Check />,
      disabled: (data?.Comments === undefined || data?.Comments?.length < 3),
    },
  ];

function FormSections() {
  return [
    {
      xsCol: 12,
      fields: [
        {
          name: "SampleNumber",
          label: "Sample Number",
          xsCol: 2,
        },
        { name: "IsWin", label: "Win", xsCol: 2, type: "switch" },
        { name: "Contact", label: "Spoke To", xsCol: 2 },
        { name: "Telephone", label: "Telephone", xsCol: 2 },
        { name: "Email", label: "Email", xsCol: 4 },
      ],
    },
    {
      xsCol: 6,
      fields: [
        {
          name: "Comments",
          label: "Comments",
          type: "textarea",
          xsCol: 12,
        },
      ],
    },
    {
      xsCol: 6,
      fields: [
        {
          name: "CorrectiveAction",
          label: "Corrective Action",
          type: "textarea",
          xsCol: 12,
        },
      ],
    },
  ];
}
const EventActions = {
  LOAD_DATA: "load-data",
  RESET_DATA: "reset-data",
};
function EventReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    const _key = Date.now();
    return { ...action.payload, _key };
  }
  // if (action.type === EventActions.RESET_DATA) {
  //   return { _key: Date.now(), SampleNumber: state.SampleNumber };
  // }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  return state;
}
