import { useState } from "react";
import AppDialog from "../../../components/AppDialog";
import { useWindowPopups } from "../../../components/useWindowPopups";
import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";

export default function PrimaryContactOverviewFormTable({
  data,
  rowSelected,
  setOpenDialog,
  ...props
}) {
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
  });
  const options = {
    ...props,
    columns: GetColumns(
      editClick,
      accessClick,
      reportsClick,
      subscriptionsClick,
      primaryClick,
      deleteClick
    ),
    dataSource: (data || []).map((x, i) => ({
      ...x, keyProp: i
    })),
    sx: { height: "70dvh" },
    getRowId: (originalRow) => (originalRow?.keyProp || ""),

    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Primary" ? {
          backgroundColor: (row?.original?.Primary !== null ? "green !important" : ""),
          color: (row?.original?.Primary !== null ? "white !important" : ""),
          py: 0,
          borderLeft: "1px solid #d0d0d0",
          height: "22px",
        } : cell.column.columnDef.accessorKey === "Source" ? {
          backgroundColor: (
            row?.original?.IsKomatsu
              ? "#140a9a !important"
              : row?.original?.IsPortalUser
                ? "#ff4500 !important"
                : row?.original?.IsLabcheckUser
                  ? "#228b22 !important"
                  : ""
          ),
          color: "white",
          border: "0.5px solid #d0d0d0",
          py: 0,
          height: "22px",
        } : {
          py: 0, height: "22px", borderLeft: "1px solid #d0d0d0"
        }
      }
    },
    muiTableBodyRowProps: ({ cell, column, row, table }) => {
      return {
        sx: { height: "22px", py: 0, borderLeft: "1px solid #d0d0d0" }
      }
    },
  };

  return (
    <>
      <NewDataTable {...options} />
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        title={dialog.title}
        maxWidth="100vw"
      >
      </AppDialog>
    </>
  );

  function HandleNewWindow(actionName, data) {
    useWindowPopups(actionName, data)
  }

  function editClick(row) {
    HandleNewWindow("edit-contact", row)
  }


  function accessClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 2,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }

  function reportsClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 3,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }

  function subscriptionsClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 4,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }

  function primaryClick(row) {
    HandleNewWindow("contact-primary-mapping", {
      ContactId: row?.ContactId,
      PropId: 6,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
    });
  }
  function deleteClick(row) {
  }
}
const GetColumns = (
  editClick,
  accessClick,
  reportsClick,
  subscriptionsClick,
  primaryClick,
  deleteClick
) => [
    {
      header: "Edit",
      size: 55,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              minWidth={"50px"}
              row={row}
              onClick={() => editClick(row?.original)}
              label="Edit"
            />
          </>
        );
      },
    },
    {
      header: "Access",
      size: 60,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              minWidth={"55px"}
              row={row}
              onClick={() => accessClick(row?.original)}
              label="Access"
            />
          </>
        );
      },
    },
    {
      header: "Reports",
      size: 60,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              minWidth={"55px"}
              row={row}
              onClick={() => reportsClick(row?.original)}
              label="Reports"
            />
          </>
        );
      },
    },
    {
      header: "Subscriptions",
      size: 85,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              row={row}
              minWidth={"80px"}
              onClick={() => subscriptionsClick(row?.original)}
              label="Subscriptions"
            />
          </>
        );
      },
    },
    {
      header: "Primary",
      size: 60,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              minWidth={"55px"}
              row={row}
              onClick={() => primaryClick(row?.original)}
              label="Primary"
            />
          </>
        );
      },
    },
    {
      header: "Email",
      accessorKey: "Username",
      size: 200,
    },
    {
      header: "Name",
      accessorKey: "DisplayName",
      size: 200,
    },

    {
      header: "Reports From",
      accessorKey: "ReportRankFrom",
      size: 120,
    },

    {
      header: "Site",
      accessorKey: "Site",
      size: 100,
    },

    {
      header: "Source",
      accessorKey: "Source",
      size: 70,
    },

    {
      header: "Mobile",
      accessorKey: "Mobile",
      size: 104,
    },
    {
      header: "Telephone",
      accessorKey: "Telephone",
      size: 97,
    },
    {
      header: "Role",
      accessorKey: "ContactRole",
      size: 60,
    },
    {
      header: "Archived",
      accessorKey: "Archived",
      size: 69,
    },
    {
      header: "Company",
      accessorKey: "Company",
      size: 150,
    },
    {
      header: "Delete",
      size: 65,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              minWidth={"55px"}
              row={row}
              onClick={deleteClick}
              label="Delete"
            />
          </>
        );
      },
    },
  ];
