import { Card, Grid, Typography } from "@mui/material";
import { useReducer, } from "react";
import Form, { FormActions } from "./Form";
import FormFooter from "./FormFooter";
import moment from "moment";

const SubscriptionReports = () => {

    const [data, dispatch] = useReducer(SearchFilterReducer, defaultProducts);

    const formActions = { newContact: function (e) { }, };

    return (
        <Card sx={{ p: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}  >
                    <Typography >
                        Select the report for the product.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Form
                        sections={GetFormSections()}
                        data={data}
                        dispatch={dispatch}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormFooter
                        buttons={GetFormButtons(formActions)}
                    />
                </Grid>
            </Grid>
        </ Card>
    );
}

export default SubscriptionReports;

function GetFormSections() {
    return [
        {
            fields: [
                {
                    name: "From",
                    label: "From",
                    type: "date",
                    xsCol: 6,
                },
                {
                    name: "To",
                    label: "To",
                    type: "date",
                    xsCol: 6,
                },
                {
                    group: [
                        {
                            name: "History",
                            label: "History",
                            type: "switch",
                            xsCol: 3,
                        },
                        {
                            name: "Format",
                            label: "Format",
                            type: "dropdown",
                            xsCol: 4,
                            required: true,
                            selectOptions: [
                                { Id: 0, value: 0, text: "XLSX" },
                                { Id: 1, value: 1, text: "XML" }
                            ],
                        },

                    ],
                },
                {
                    name: "periodic",
                    label: "periodic",
                    type: "switch",
                    xsCol: 3,
                },
                {
                    name: "Period",
                    label: "Period",
                    type: "dropdown",
                    xsCol: 4,
                    required: true,
                    selectOptions: [
                        { Id: 0, value: 0, text: "Monthly" },
                        { Id: 1, value: 1, text: "Yearly" }
                    ],
                },
                {
                    name: "Type",
                    label: "Type",
                    type: "dropdown",
                    xsCol: 4,
                    required: true,
                    selectOptions: [
                        { Id: 0, value: 0, text: "Summary Report" },
                        { Id: 1, value: 1, text: "Report" }
                    ],
                },
            ],
        },
    ];
}

const defaultProducts = {
    From: new moment().subtract(30, 'd'),
    To: new moment().subtract(30, 'd'),
};

const EventActions = {
    LOAD_DATA: "load-data",
};

function SearchFilterReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { ...state, [action.payload.name]: action.payload.value };
    }
    if (action.type === FormActions.RESET_FORM) {
        return { ...action.payload };
    }
    if (action.type === EventActions.LOAD_DATA) {
        return { ...action.payload };
    }
    return state;
}

const GetFormButtons = ({ newContact }) => [
    {
        label: "Export",
        onClick: newContact,
    },
];