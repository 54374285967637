import Button from "@mui/material/Button";
import { FormActions } from "../../../components/Form";
import { ContactIcon, ExcelIcon } from "../../../icons/ButtonIcons";

export function FormSections(searchContactScope, handleExportContacts) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          name: "Filter",
          label: "Filter",
          xsCol: 2,
        },
        { name: "Komatsu", label: "Komatsu", type: "switch", },
        { name: "Other", label: "Other", type: "switch", },
        {
          name: "showAllAlerts",
          label: "Show All Alerts Levels, or",
          type: "switch",
        },
        {
          name: "AlertsFrom",
          label: "Alerts From",
          xsCol: 1,
          type: "number",
          min: 2,
          max: 5,
        },
        { name: "Product", label: "Product", type: "switch" },
        { name: "Parent", label: "Parent", type: "switch" },

        { name: "SiteSet", label: "Site Set", type: "switch" },
        { name: "Customer", label: "Customer", type: "switch" },
        { name: "Site", label: "Site", type: "switch" },
        { name: "Unit", label: "Unit", type: "switch" },
        { name: "Component", label: "Component", type: "switch" },
        {
          group: [
            // {
            //   component: function () {
            //     return (
            //       <Button sx={{ mt: "23px" }} variant={"outlined"}
            //         startIcon={<ClearIcon />}>
            //         Reset
            //       </Button>
            //     );
            //   },
            // },
            {
              component: function () {
                return (
                  <Button
                    variant={"outlined"}
                    sx={{ mt: "23px" }}
                    startIcon={<ExcelIcon />}
                    onClick={handleExportContacts}
                  >
                    Excel
                  </Button>
                );
              },
            },
            {
              component: function () {
                return (
                  <Button
                    sx={{ mt: "23px" }}
                    startIcon={<ContactIcon />}
                    onClick={searchContactScope}
                  >
                    Search Contact
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}
export const EventActions = {
  LOAD_DATA: "load-data",
};
export function EventReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return action.payload;
  }
  return state;
}
export const SearchFilterDefaults = {
  Komatsu: true,
  showAllAlerts: true,
  AlertsFrom: 2,
  Other: true,
  Product: true,
  Parent: true,
  SiteSet: true,
  Site: true,
  Unit: true,
  Customer: true,
  Component: true,
};
