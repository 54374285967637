import { useEffect, useReducer } from "react";
import { Card, Typography } from "@mui/material";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import { CONTACTS_APIS } from "../../../common/apis";
import Form, { FormActions } from "../../../components/Form";

export default function SecondaryContact({ contactId, cancel }) {

    const { get, post } = useFetch();

    const [data1, dispatch1] = useReducer(SearchFilterReducer, SearchFilterDefaults);
    const [data2, dispatch2] = useReducer(SearchFilterReducer, SearchFilterDefaults);
    const [data3, dispatch3] = useReducer(SearchFilterReducer, SearchFilterDefaults);
    const [data4, dispatch4] = useReducer(SearchFilterReducer, SearchFilterDefaults);

    useEffect(() => {
        (async function () {
            const contactData = await get(`${CONTACTS_APIS.CONTACT_ALTERNATIVE}?contactId=${contactId}`);
            (Object.entries(contactData[0])?.length > 0) && dispatch1({
                type: ContactActions.LOAD_DATA,
                payload: { ...contactData[0] },
            });
            (Object.entries(contactData[1])?.length > 0) && dispatch2({
                type: ContactActions.LOAD_DATA,
                payload: { ...contactData[1] },
            });
            (Object.entries(contactData[2])?.length > 0) && dispatch3({
                type: ContactActions.LOAD_DATA,
                payload: { ...contactData[2] },
            });
            (Object.entries(contactData[3])?.length > 0) && dispatch4({
                type: ContactActions.LOAD_DATA,
                payload: { ...contactData[3] },
            });
        })()
    }, [])

    const handleSave = async () => {
        const resp = await post(CONTACTS_APIS.ADD_CONTACT_ALTERNATIVE, [
            { ...data1, Priority: parseInt(data1?.Priority), ContactId: contactId },
            { ...data2, Priority: parseInt(data2?.Priority), ContactId: contactId },
            { ...data3, Priority: parseInt(data3?.Priority), ContactId: contactId },
            { ...data4, Priority: parseInt(data4?.Priority), ContactId: contactId },
        ]
        );
        cancel && cancel()
    }

    return (
        <>
            <Card sx={{ p: 1 }}>
                <Typography mt={2} mb={1} sx={{ fontWeight: 800 }}>Secondary 1</Typography>
                <Form
                    sections={FormSections}
                    data={data1}
                    dispatch={dispatch1}
                    key={data1 && data1?.AlternativeId}
                />

                < Typography mt={2} mb={1} sx={{ fontWeight: 800 }}>Secondary 2</Typography>
                <Form
                    sections={FormSections}
                    data={data2}
                    dispatch={dispatch2}
                    key={data2 && data2?.AlternativeId}
                />

                <Typography mt={2} mb={1} sx={{ fontWeight: 800 }}>Secondary 3</Typography>
                <Form
                    sections={FormSections}
                    data={data3}
                    dispatch={dispatch3}
                    key={data3 && data3?.AlternativeId}
                />

                <Typography mt={2} mb={1} sx={{ fontWeight: 800 }}>Secondary 4</Typography>
                <Form
                    sections={FormSections}
                    data={data4}
                    dispatch={dispatch4}
                    key={data4 && data4?.AlternativeId}
                />

                <Typography my={1} >AU +61 and 1 area code and 8 digits</Typography>
                <Typography my={1} >NZ Mobile +642 and 7 to 9 digits &ensp; &emsp; NZ +64 and 1 area code and 7 digits</Typography>
                <Typography my={1} >NC +687 and 6 digits</Typography>
                <Typography my={1} >Secondary 4</Typography>

                <FormFooter footerText={`ContactId: ${contactId || ""}`} buttons={GetFormButtons(handleSave)} />
            </Card>
        </>
    );
}

const GetFormButtons = (handleSave) => [
    { variant: "contained", label: "Save", onClick: handleSave },
];

const FormSections = [
    {
        xsCol: 12,
        fields: [
            { name: "Forename", label: "Forename", xsCol: 3 },
            { name: "Surname", label: "Surname", xsCol: 3 },
            { name: "Mobile", label: "Mobile", xsCol: 3 },
            { name: "Telephone", label: "Telephone", xsCol: 3 },
            { name: "Position", label: "Position", xsCol: 6 },
            { name: "Priority", label: "Priority", xsCol: 2, type: "number", min: 1, max: 9 },
            { name: "IsDeleted", label: "Delete", xsCol: 1, type: "switch" },
        ],
    },
];

const ContactActions = {
    LOAD_DATA: "load-data",
};

function SearchFilterReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { ...state, [action.payload.name]: action.payload.value };
    }
    if (action.type === ContactActions.LOAD_DATA) {
        return { ...action.payload, };
    }
    return state;
}

const SearchFilterDefaults = {
    Forename: "",
    Mobile: "",
    Position: "",
    Priority: 5,
    Surname: "",
    Telephone: ""
}