import { Box, Button, Card } from "@mui/material";
import { useCallback, useEffect, useReducer, useState } from "react";
import Form, { FormActions } from "../components/Form";
import FormFooter from "../components/FormFooter";
import ComponentReducer, { ComponentActions, } from "./reducers/ComponentReducer";
import useFetch from "../hooks/useFetch";
import useMasterData from "../hooks/useMasterData";
import { ValidateComponent } from "../validation/schemas/ComponentSchema";
import { DETAILS_APIS } from "../common/apis";
import { useRoot, } from "../rootContext";
import DropDownAdvance from "../components/DropDownAdvance";
import { getStartLetter } from "../utils";
import GroupDialog from "../components/GroupDialog";
import { DialogTypes, useScreenPopups } from "../components/useScreenPopups";
import DropDown from "../components/DropDown";

function EditComponent({
  siteId,
  productId,
  customerId,
  cancel,
  unitId,
  compId,
  fluidTypeId,
  registrationDone,
  startLetter,
  setExpandTreeData,
  handleSearchTree,
}) {

  const [data, dispatch] = useReducer(ComponentReducer, {
    DefaultFluidTypeId: "1",
    ComponentTypeId: "1",
    LocationId: "1",
  });
  const { CanDoDataMaintenance } = useRoot();
  const { get, post } = useFetch();
  const { getMasterData } = useMasterData();

  const [formErrors, setFormErrors] = useState({});
  const [dropdownData, setDropdownData] = useState([]);
  const [dialogType, setDialogType] = useState({ open: false });
  const [localData, setLocalData] = useState({});

  const actions = {
    save: async function () {
      if (compId || data?.ComponentId) {
        setFormErrors({});
        ValidateComponent(data).then(async function ({ data: req, errors }) {
          if (req) {
            let result = await post(DETAILS_APIS.UPDATE_COMPONENT, {
              // ...data,
              ApplyToAllSamples: data?.SetToALLSamples || false,
              CIN: data?.CIN,
              Capacity: data?.Capacity,
              ComponentTypeId: data?.ComponentTypeId,
              ComponentMakeId: data?.ComponentMakeId,
              ComponentId: data?.ComponentId,
              Component: data?.Component,
              ComponentFluidTypeId: data?.DefaultFluidTypeId,
              Serial: data?.Serial,
              LocationId: data?.LocationId,
              Notes: data?.ComponentNotes,
              DefaultFluidId: data?.DefaultFluidId,
              DefaultTestSetId: data?.DefaultTestSetId,
              IsNonFluid: data?.IsNonFluid,
              IsArchived: data?.ComponentArchived,
              ApplyToAllTestSet: data?.ApplyToAllTestSet,
              UnitId: data?.UnitId,
              UserId: data?.UserId,
              Screen: "Edit Component",
            });
            if (result > 0 || result?.length > 0) {
              const treeData = { ProductId: data?.ProductId, StartLetter: data?.StartLetter, CustomerId: data?.CustomerId, SiteId: data?.SiteId, UnitId: data?.UnitId, ComponentId: data?.ComponentId, refreshCheck: true }
              setExpandTreeData && setExpandTreeData(treeData)
              handleSearchTree && handleSearchTree(treeData, null, true)

              localStorage.setItem("component-resp", JSON.stringify({ TestSetId: data?.DefaultTestSetId, }));
              localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
              window.dispatchEvent(new Event('storage'));

              cancel && cancel();
              if (window.location.pathname === '/add-new-component' || window.location.pathname === '/edit-component') {
                localStorage.setItem("edit-component-resp", JSON.stringify({ ...treeData }));
                window.dispatchEvent(new Event('storage'));
                localStorage.removeItem("edit-component");
                window.close()
              }
              return;
            }

            if (result !== null && (result < 1 || result?.length < 1)) {
              return;
            }
            registrationDone && registrationDone(data.SampleNumber);
            return;
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      } else {
        setFormErrors({});
        ValidateComponent(data).then(async function ({ data: req, errors }) {
          if (req) {
            let result = await post(DETAILS_APIS.ADD_COMPONENT, {
              ...data,
              UnitId: unitId || localData?.UnitId,
              IsArchived: !!data.ComponentArchived,
              ComponentFluidTypeId: data.DefaultFluidTypeId,
              Notes: data.ComponentNotes,
              ApplyToAllSamples: !!data.SetToALLSamples,
              IsNonFluid: !!data.IsNonFluid,
              Screen: "Add Component",
            });
            registrationDone && registrationDone(data.SampleNumber);
            if (result > 0 || result?.length > 0) {
              if (result > 0) {
                let slIndex = 0;
                if ((startLetter === undefined || startLetter === "") && localData?.StartLetter === undefined) {
                  const cn = data?.Customer || localData?.Customer
                  let name = cn?.trim();
                  slIndex = Number(getStartLetter(name[0]));
                }
                else {
                  slIndex = startLetter || localData?.StartLetter
                }

                const treeData = {
                  ProductId: productId || localData?.ProductId,
                  StartLetter: slIndex,
                  CustomerId: customerId || localData?.CustomerId,
                  SiteId: siteId || localData?.SiteId,
                  UnitId: unitId || localData?.UnitId,
                  ComponentId: result,
                  refreshCheck: true
                }

                setExpandTreeData && setExpandTreeData(treeData)
                handleSearchTree && handleSearchTree(treeData, null, true)
                localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
                window.dispatchEvent(new Event('storage'));
              }

              cancel && cancel();
              if (window.location.pathname === '/add-new-component' || window.location.pathname === '/edit-component') {
                const treeData = {
                  ProductId: productId || localData?.ProductId,
                  CustomerId: customerId || localData?.CustomerId,
                  SiteId: siteId || localData?.SiteId,
                  UnitId: unitId || localData?.UnitId,
                  ComponentId: result,
                }
                localStorage.setItem("edit-component-resp", JSON.stringify({ ...treeData }));
                window.dispatchEvent(new Event('storage'));
                localStorage.removeItem("edit-component");
                window.close()
              }
              return;
            }
            return;
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      }
    },
    openDialog: function (type) {
      setDialogType({ type, open: true });
    },
    cancel: cancel,
  };

  function recordSelected(row) {
    if (row.ComponentName) {
      dispatch({
        payload: {
          ...data,
          Component: row.ComponentName,
        },
        type: ComponentActions.LOAD_DATA,
      });
    }
  }

  const handleDDChange = (e, val, name) => {
    dispatch({
      type: FormActions.INPUT_CHANGED,
      payload: { name: name, value: val }
    });
  }

  const handelFluidTypeChange = async (e) => {
    const masterData = await getMasterData("TestSetsForUnitFluidTypes,Fluids", `&unitId=${data?.UnitId || 1}&fluidTypeId=${e?.target?.value || 1}`);
    setDropdownData({ ...dropdownData, ...masterData } || {});
    dispatch({
      payload: {
        ...data,
        DefaultFluidTypeId: e?.target?.value,
      },
      type: ComponentActions.LOAD_DATA,
    });
  }

  const handleComponentModels = async (row) => {
    if (row?.ComponentMakeId > 0) {
      const resp = await getMasterData("ComponentModels");
      setDropdownData({ ...dropdownData, ComponentModels: resp?.ComponentModels });
      dispatch({
        payload: {
          ...data,
          ComponentMakeId: row?.ComponentMakeId,
        },
        type: ComponentActions.LOAD_DATA,
      });
    }
  };


  const dialogOptions = useScreenPopups({
    data: data || {},
    cancel: () => setDialogType({ open: false }),
    dialogType: dialogType.type,
    recordSelected: recordSelected,
    fetchComponentModels: handleComponentModels,
  });

  const handleKeyDown = (event) => {
    if (event.key === "F2") {
      event.preventDefault();
      (dialogType.open === false) && actions.clear();
    }
    else if (event.key === "F11") {
      event.preventDefault();
      (dialogType.open === false) && actions.openDialog(DialogTypes.STANDARD_NAMES);
    }
  }

  useEffect(() => {
    (async () => {
      let lData = localStorage.getItem("edit-component");
      if (lData !== null) {
        lData = JSON.parse(lData);
        setLocalData(lData);
        if (lData?.ComponentId > 0) {
          setLocalData(lData);
          const data = await get(`/details/component?compId=${lData?.ComponentId || 1}`, { skipBlocker: true });
          setDropdownData(data || {});
          dispatch({
            type: ComponentActions.LOAD_DATA,
            payload: { ...data?.Component, DefaultFluidTypeId: data?.Component?.DefaultFluidTypeId || 1, },
          });
        }
        else {
          if (lData?.UnitId) {
            const masterData = await getMasterData("ComponentFluidTypes,ComponentTypes,ComponentModels,ComponentLocations,TestSetsForUnitFluidTypes,Fluids", `&unitId=${lData?.UnitId}&fluidTypeId=${lData?.FluidTypeId || 1}`);
            setDropdownData(masterData || {});
          }
          else {
            const masterData = await getMasterData("ComponentFluidTypes,ComponentTypes,ComponentModels,ComponentLocations,TestSetsForUnitFluidTypes,Fluids", `fluidTypeId=1`);
            return setDropdownData(masterData || {});
          }
        }
      }

      if (unitId) {
        const masterData = await getMasterData(
          "ComponentFluidTypes,ComponentTypes,ComponentModels,ComponentLocations,TestSetsForUnitFluidTypes,Fluids", `&unitId=${unitId || 1}&fluidTypeId=${fluidTypeId || 1}`
        );
        setDropdownData(masterData || {});
      }
      if (compId) {
        const data = await get(`/details/component?compId=${compId !== undefined ? compId : ""}&FluidTypeId=${fluidTypeId || 1}`, { skipBlocker: true });
        setDropdownData(data || {});
        dispatch({
          type: ComponentActions.LOAD_DATA,
          payload: data && data.Component,
        });
      }
    })();
  }, []);

  const handleCloseButton = () => {
    localStorage.removeItem("edit-component");
  }

  useEffect(function () {
    window.addEventListener("beforeunload", handleCloseButton);
    return function () {
      window.removeEventListener("beforeunload", handleCloseButton);
    };
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Card sx={{ padding: 1 }}>
      <Form
        sections={FormSections(dropdownData, data, handleDDChange, CanDoDataMaintenance, actions, handelFluidTypeChange)}
        data={data}
        spacing={2}
        dispatch={dispatch}
        errors={formErrors}
        key={data._ts_key}
      />
      <FormFooter
        buttons={GetFormButtons(data, actions, CanDoDataMaintenance)}
        footerText={`ProductId: ${productId || data?.ProductId || localData?.ProductId || ""
          }, CustomerId: ${customerId || localData?.CustomerId || data?.CustomerId || ""}, SiteId: ${siteId || localData?.SiteId || data?.SiteId || ""
          }, UnitId: ${unitId || localData?.UnitId || data?.UnitId || ""},  ComponentId: ${data.ComponentId || ""
          } `}
      />
      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={() => setDialogType({ open: false })}
      />
    </Card>
  );
}

export default EditComponent;

const GetFormButtons = (data, { cancel, save }, CanDoDataMaintenance) => [
  {
    hidden: !CanDoDataMaintenance,
    variant: "contained",
    label: "Save",
    onClick: save,
  },
];

function ComponentsDropDown({ id, dataList, handleChange, defaultValue }) {

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" sx={{ minWidth: "280px", maxWidth: "280px" }} {...props} key={option.ComponentMakeId}>
        {option.ComponentMakeFormatted || "Select"}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options
      : options
        .filter((x) =>
          x.ComponentMakeFormatted.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.ComponentMakeFormatted || "Select";
  }, []);

  return (
    <DropDownAdvance
      id={id}
      label="Model"
      renderOptions={renderOptions}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(e, val) => handleChange(e, val?.ComponentMakeId || null, "ComponentMakeId")}
      options={dataList || []}
      defaultValue={dataList.find((x) => x.ComponentMakeId === defaultValue) || "Select"}
    />
  );
}

function FluidsDropDown({ id, dataList, handleChange, defaultValue }) {

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" sx={{ minWidth: "280px", maxWidth: "280px" }} {...props} key={option.FluidId}>
        {option.FluidFormatted || "Select"}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options
      : options
        .filter((x) =>
          x.FluidFormatted.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.FluidFormatted || "Select";
  }, []);

  return (
    <DropDownAdvance
      id={id}
      label="Fluid"
      renderOptions={renderOptions}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(e, val) => handleChange(e, val?.FluidId || null, "DefaultFluidId")}
      options={dataList || []}
      defaultValue={dataList.find((x) => x.FluidId === defaultValue) || "Select"}
    />
  );
}

function FormSections(dropdownData, data, handleDDChange, CanDoDataMaintenance, actions, handelFluidTypeChange) {
  return [
    {
      fields: [
        { name: "Component", label: "Component", xsCol: 3 },
        {
          alignSelf: "end",
          xsCol: 3,
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={() => actions.openDialog(DialogTypes.STANDARD_NAMES)}
              >
                Standard Names (F11)
              </Button>
            );
          },
        },
        {
          name: "ComponentArchived",
          label: "Archived - no website",
          type: "switch",
          xsCol: 3,
        },
        {
          name: "IsNonFluid",
          label: "Non-Fluid Component",
          type: "switch",
          xsCol: 3,
        },
        {
          name: "ComponentTypeId",
          label: "Comp. Type",
          type: "dropdown",
          xsCol: 3,
          selectOptions: (dropdownData.ComponentTypes || []).map((x) => ({
            value: x.ComponentTypeId,
            text: x.ComponentType,
          })),
          sx: {
            [`.MuiSelect-select`]: {
              backgroundColor: data?.ComponentTypeId == '1' ? "red !important" : "",
              color: data?.ComponentTypeId == '1' ? "white !important" : "",
              [`.MuiSvgIcon-root-MuiSelect-icon`]: {
                color: data?.ComponentTypeId == '1' ? "" : "white !important",
              },
            },
            [`.MuiSelect-icon`]: {
              color: data?.ComponentTypeId == '1' ? "white !important" : "",
            },
          },
        },
        {
          xsCol: 3,
          component: function () {
            return (
              <DropDown
                label={"Fluid Type"}
                value={data?.DefaultFluidTypeId}
                name="DefaultFluidTypeId"
                onChange={handelFluidTypeChange}
                selectOptions={(
                  dropdownData.FluidTypes ||
                  dropdownData.ComponentFluidTypes ||
                  []
                ).map((x) => ({
                  value: x.FluidTypeId,
                  text: x.FluidType,
                }))}
              />
            );
          },
        },
        {
          name: "LocationId",
          label: "Location",
          type: "dropdown",
          xsCol: 3,
          selectOptions: (dropdownData.ComponentLocations || []).map((x) => ({
            value: x.LocationId,
            text: x.Location,
          })),
        },
        { name: "Serial", label: "Serial", xsCol: 3 },
        {
          xsCol: 3,
          component: function () {
            return (
              <ComponentsDropDown
                id={"ComponentMakeId"}
                dataList={dropdownData.ComponentModels || []}
                handleChange={(e, val, text) => handleDDChange(e, val, text)}
                defaultValue={data?.ComponentMakeId}
              />
            );
          },
        },
        {
          alignSelf: "end",
          xsCol: 3,
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={() => actions.openDialog(DialogTypes.EDIT_COMPONENT_MODEL)}
              >
                New Model
              </Button>
            );
          },
        },
        { name: "Capacity", label: "Capacity  (in litres)", xsCol: 3 },
        { name: "CIN", label: "CIN", xsCol: 3 },
      ],
    },
    {
      fields: [
        {
          name: "DefaultTestSetId",
          label: "Test Set",
          type: "dropdown",
          xsCol: 3,
          selectOptions: (
            dropdownData.TestSetsForUnitFluidTypes ||
            dropdownData.TestSets ||
            []
          ).map((x) => ({
            value: x.TestSetId,
            text: x.TestSet,
          })),
        },
        {
          xsCol: 3,
          component: function () {
            return (
              <FluidsDropDown
                id={"DefaultFluidId"}
                dataList={dropdownData.Fluids || []}
                handleChange={(e, val, text) => handleDDChange(e, val, text)}
                defaultValue={data?.FluidId || data?.DefaultFluidId}
              />
            );
          },
        },
        CanDoDataMaintenance && {
          alignSelf: "end",
          xsCol: 2,
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={() => actions.openDialog(DialogTypes.NEW_FLUID)}
              >
                New Fluid
              </Button>
            );
          },
        },
        CanDoDataMaintenance && {
          name: "SetToALLSamples",
          label: "Apply Test Set to ALL Samples",
          type: "switch",
          xsCol: 2,
        },
        CanDoDataMaintenance && {
          name: "FluidToALLSamples",
          label: "Apply Fluid to ALL Samples",
          type: "switch",
          xsCol: 2,
        },
        {
          name: "ComponentNotes",
          label: "Comments",
          type: "textarea",
          rows: "9",
          xsCol: 12,
        },
      ],
    },
  ];
}
