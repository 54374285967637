import React from 'react'
import NewDataTable from '../../../components/NewDataTable'

function InsiteModelTable({ feedbackData }) {

    const options = {
        columns: GetColumns(),
        dataSource: (feedbackData || []).map((x, i) => ({ ...x, keyProp: i, })),
        getRowId: (originalRow) => originalRow?.keyProp,
        enablePagination: false,
        sx: { height: "80dvh" },
        enableColumnActions: false,

        muiTableBodyProps: {
            sx: (theme) => ({
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
                    { backgroundColor: "inherit" },
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                    { backgroundColor: "inherit" },
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
                    { backgroundColor: "#e2f2ff" },
                // backgroundColor: "inherit",//
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                    { backgroundColor: "#e2f2ff" },
            }),
        },
    }

    return (
        <NewDataTable {...options} />
    )
}

export default InsiteModelTable;

function GetColumns() {
    return [
        {
            header: "Make",
            accessorKey: "Make",
            size: 150,
        },
        {
            header: "Model",
            accessorKey: "Model",
            size: 150,
        },
    ];
}
