import renderCellExpand from "../../../../components/DatatableCellExpand";
import { toDateTimeFormat1, } from "../../../../utils";
import DataTable, { DataGridAction } from "./../../../../components/DataTable";

export default function AddCriticalLogsTable({ actions, data, rowsSelected, ...props }) {
  const options = {
    ...props,
    columns: GetColumns(actions),
    dataSource: data,
    rowIdProperty: "CriticalLogId",
    isDisableColumnFilter: true,
    sx: { height: "40dvh" },
  };
  return <DataTable {...options}></DataTable>;
}

function GetColumns(actions) {
  return [
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      width: 80,
      hide: !actions || !actions.length,
      renderCell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <DataGridAction
              row={row}
              onClick={ac.action}
              label={ac.label}
              startIcon={ac.startIcon}
            />
          );
        });
      },
    },
    {
      headerName: "Technician",
      field: "Evaluator",
      minWidth: 120,
      renderCell: renderCellExpand,
    },

    {
      headerName: "Contact",
      field: "Contact",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Telephone",
      field: "Telephone",
      minWidth: 100,
    },
    {
      headerName: "Email",
      field: "Email",
      minWidth: 180,
    },
    {
      headerName: "Win",
      field: "IsWin",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      cellClassName: (params) => {
        if (!params.value) {
          return "";
        }
        return "cell-fg-wt";
      },
      width: 60,
    },
    {
      headerName: "Created",
      field: "Created",
      valueFormatter: ({ value }) => toDateTimeFormat1(value),
      minWidth: 110,
    },
    {
      headerName: "Created By",
      field: "CreatedBy",
      minWidth: 110,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Modified",
      field: "Modified",
      minWidth: 110,
      valueFormatter: ({ value }) => toDateTimeFormat1(value),
    },
    {
      headerName: "Modified By",
      field: "ModifiedBy",
      minWidth: 110,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Comments",
      field: "Comments",
      minWidth: 220,
      renderCell: renderCellExpand,
      flex: 1,
    },
    {
      headerName: "Corrective Action",
      field: "CorrectiveAction",
      minWidth: 130,
      renderCell: renderCellExpand,
    },
  ]
};
