
import React, { useEffect, useState } from 'react'
import NewDataTable, { NewDataGridAction } from '../../../components/NewDataTable';

function InsiteMachineTable({ selectData, feedbackData }) {

    const [sCheck, setSCheck] = useState(false);

    useEffect(() => {
        if (window.location.pathname !== "/master-data/view-komatsu-machines") {
            setSCheck(true)
        }
    }, []);

    const options = {
        columns: GetColumns(selectData),
        dataSource: (feedbackData || []).map((x, i) => ({ ...x, keyProp: i, })),
        getRowId: (originalRow) => originalRow?.keyProp,
        enablePagination: false,
        sx: { height: "75dvh" },
        enableColumnActions: false,

        muiTableBodyProps: {
            sx: (theme) => ({
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
                    { backgroundColor: "inherit" },
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                    { backgroundColor: "inherit" },
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
                    { backgroundColor: "#e2f2ff" },
                // backgroundColor: "inherit",//
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                    { backgroundColor: "#e2f2ff" },
            }),
        },
        state: { columnVisibility: { Select: sCheck } },

    }

    return (
        <NewDataTable {...options} />
    )
}
export default InsiteMachineTable

function GetColumns(selectData) {
    return [
        {
            header: "Select",
            enableHiding: true,
            sortable: false,
            size: 50,
            Cell: function ({ row, value }) {
                return (
                    <NewDataGridAction
                        row={row?.original}
                        onClick={() => selectData(row?.original)}
                        label={"Select"}
                    />
                );
            },
        },
        {
            header: "Make",
            accessorKey: "Make",
            size: 100,
        },
        {
            header: "Model",
            accessorKey: "Model",
            size: 100,
        },
        {
            header: "Serial",
            accessorKey: "Serial",
            size: 100,
        },
        {
            header: "Fleet Number",
            accessorKey: "FleetNo",
            size: 150,
        },
        {
            header: "SMR",
            accessorKey: "SMR",
            size: 150,
        },
        {
            header: "SMR Date",
            accessorKey: "SmrDate",
            size: 150,
        },
        {
            header: "Customer",
            accessorKey: "Customer",
            size: 300,
        },
    ];
}