import { FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import { useReducer, useState, } from "react";
import Form, { FormActions } from "../../../../components/Form";
import FormFooter from "../../../../components/FormFooter";
import { useUser } from "../../../../rootContext";
import useFetch from "../../../../hooks/useFetch";
import { CONTACTS_APIS } from "../../../../common/apis";
import { useEffect } from "react";

export default function ContactSubscriptionMapping({ contactId, customerData, handleClose, getData }) {

  const { get, post } = useFetch();
  const user = useUser();

  const [data, dispatch] = useReducer(SearchFilterReducer, defaultReducerData);
  const [selectedoption, setSelectedOption] = useState("1");
  const [reportsList, setReportsList] = useState([])

  useEffect(() => {
    (async function () {
      const temp = await get(CONTACTS_APIS.SUBSCRIPTION_REPORTS);
      if (temp?.length > 0) {
        setReportsList(temp)
      }
    })();
  }, [])

  const payload = {
    ContactId: contactId,
    ReportId: data?.ReportId,
    ProductId: customerData?.ProductId,
    ParentId: customerData?.ParentId,
    CustomerId: customerData?.CustomerId,
    SiteId: customerData?.SiteId,
    UnitId: customerData?.UnitId,
    ComponentId: customerData?.ComponentId,
    SiteSetId: customerData?.SiteSetId,
    IsMonthly: selectedoption === "2" ? true : false,
    IsFortnightly: selectedoption === "3" ? true : false,
    IsWeekly: selectedoption === "1" ? true : false,
    UserId: user?.UserId,
    Screen: "Contact Subscriptions Mapping",
  }

  const formActions = {
    handleSave: async function (e) {
      await post(CONTACTS_APIS.ADD_CONTACT_SUBSCRIPTION, payload);
      handleClose && handleClose()
      getData && getData()
    },
    handleDelete: function (e) { },
  };

  const handleRadioGroupChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Grid container sx={{ maxWidth: "30vw" }}>
        <Grid item xs={12}>
          <Typography>
            Select the reporting options and alert level for this mapping
          </Typography>
        </Grid>
        <Grid item xs={12}>

          <Form
            sections={GetFormSections(reportsList, selectedoption, handleRadioGroupChange, customerData)}
            data={data}
            dispatch={dispatch}
            key={data._key}
          />

          <FormFooter
            footerText={`ContacttId:${contactId}`}
            buttons={GetFormButtons(formActions)}
          />
        </Grid>
      </Grid>
    </>
  );
}

function GetFormSections(reportsList, selectedoption, handleRadioGroupChange, customerData) {
  return [
    {
      fields: [
        {
          group: [
            {
              xsCol: 4,
              component: function () {
                return <FormControlLabel
                  control={
                    <Radio
                      checked={selectedoption === "1"}
                      onChange={handleRadioGroupChange}
                      value="1"
                      name={"OutstandingRerunsOnly"}
                      size="small"
                    />
                  }
                  sx={{ mt: "2px" }}
                  label={"Weekly"}
                />;
              },
            },
            {
              xsCol: 4,
              component: function () {
                return <FormControlLabel
                  control={
                    <Radio
                      checked={selectedoption === "2"}
                      onChange={handleRadioGroupChange}
                      value="2"
                      name={"OutstandingRerunsOnly"}
                      size="small"
                    />
                  }
                  sx={{ mt: "2px" }}
                  label={"Monthly"}
                />;
              },
            },
          ],
        },
        {
          group: [
            {
              name: "ReportId",
              label: "Report",
              type: "dropdown",
              xsCol: 6,
              required: true,
              selectOptions: (reportsList || [])
                .map((x) => ({
                  value: x.ReportId,
                  text: x.FullReport,
                })),
            },
          ],
        },
        {
          xsCol: 4,
          component: function () {
            return (
              <>
                <Typography>{customerData?.Product || ""}</Typography>
                <Typography>{customerData?.Customer || ""}</Typography>
                <Typography>{customerData?.Site || ""}</Typography>
                <Typography>{customerData?.Unit || ""}</Typography>
                <Typography>{customerData?.Component || ""}</Typography>
              </>
            )
          },
        },
      ],
    },
  ];
}


const defaultReducerData = {
  ReportId: 12,
  _key: Date.now()
};

const EventActions = {
  LOAD_DATA: "load-data",
};

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}

const GetFormButtons = ({ handleDelete, handleSave }) => [
  // {
  //   label: "Delete",
  //   onClick: handleDelete,
  //   color: "danger"
  // },
  {
    label: "Save",
    onClick: handleSave,
  },
];
