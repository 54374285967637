import { Card, Grid, Typography } from "@mui/material";
import { useEffect, } from "react";
import { useReducer, useState } from "react";
import moment from "moment";
import useFetch from "../hooks/useFetch";
import Form, { FormActions } from "../components/Form";
import { DETAILS_APIS, MANAGEMENT_APIS, MASTER_DATA_APIS } from "../common/apis";
import FormFooter from "../components/FormFooter";
import { toIsoFormat } from "../utils";

export default function SpecialReport() {

  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );

  const { get, post } = useFetch();
  const [laboratories, setLaboratories] = useState();
  const [reportTypes, setReportTypes] = useState([]);

  //call api for search audit data
  async function saveClick() {
    await post(MANAGEMENT_APIS.EDIT_MESSAGE, {
      ...data,
    });
  }

  const getUnitReport = async (id) => {
    // let responseTrayData = await get(`${MANAGEMENT_APIS.UNIT_ADHOC_REPORT}?unitId=${id}`);
    let responseTrayData = await get(`${DETAILS_APIS.UNIT}?unitId=${id}`);
    dispatch({
      type: FormActions.LOAD_DATA,
      payload: { ...responseTrayData?.Unit },
    });
  }

  const getComponentReport = async (id) => {
    // let responseTrayData = await get(`${MANAGEMENT_APIS.COMPONENT_ADHOC_REPORT}?componentId=${id}`);
    let responseTrayData = await get(`${DETAILS_APIS.COMPONENT}?compId=${id}`);
    dispatch({
      type: FormActions.LOAD_DATA,
      payload: { ...responseTrayData?.Component },
    });
  }

  useEffect(() => {
    (async function () {
      const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Laboratories`);
      const reportTypesList = await get(`${MANAGEMENT_APIS.ADHOC_REPORT_TYPES}`);
      setReportTypes(reportTypesList);
      setLaboratories(response.Laboratories || []);
    })()
    let le = JSON.parse(localStorage.getItem("special-report"))
    if (le?.UnitId > 0) {
      if (le?.ComponentId > 0) {
        getComponentReport(le?.ComponentId)
      }
      else {
        getUnitReport(le?.UnitId)
      }
    }
  }, []);

  return (
    <Card sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(data, laboratories, reportTypes)}
            data={data}
            dispatch={dispatch}
            key={data._ts_key}
          />
        </Grid>

        <Grid item xs={12}>
          <FormFooter buttons={GetFormButtons(saveClick)} />
        </Grid>
      </Grid>
    </Card>
  );
}

function GetFormSections(data, laboratories, reportTypes) {
  return [
    {
      fields: [
        {
          xsCol: 12,
          component: function () {
            return (
              <Typography >
                {`${data?.Product || ""}`}  {`${data?.Customer || ""}`} {`${data?.Site || ""}`}
              </Typography>
            );
          },
        },
        {
          xsCol: 12,
          group: [
            {
              xsCol: 9,
              component: function () {
                return (
                  <Typography >
                    {`${data?.Unit || ""}`}  {`(${data?.UnitModelFormatted || ""})`} {`${data?.Component || ""}`}
                  </Typography>
                );
              },
            },
            {
              name: "IsShowOnWebsite",
              label: "Show On Website",
              xsCol: 3,
              type: "switch",
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            {
              name: "ReportType",
              label: "Report Type",
              type: "dropdown",
              xsCol: 3,
              required: true,
              selectOptions: (reportTypes || [])
                ?.map((x) => ({
                  value: x.AdHocReportTypeId,
                  text: x.AdHocReportType,
                })),
            },
            {
              name: "IsArchived",
              label: "Archived",
              xsCol: 3,
              type: "switch",
            },
          ]
        },
        {
          xsCol: 9,
          group: [
            {
              name: "ReferenceDate",
              label: "Reference",
              xsCol: 4,
              type: "datetime",
              disablePast: true,
            },
          ]
        },
        {
          xsCol: 3,
          justifySelf: "right",
          group: [
            {
              name: "UnitHours",
              label: "Unit Hours",
              xsCol: 12,
            },
          ]
        },
        {
          xsCol: 9,
          group: [
            {
              name: "LaboratoryId",
              label: "Laboratory",
              type: "dropdown",
              xsCol: 4,
              required: true,
              selectOptions: (laboratories || [])
                ?.filter((x) => x?.LaboratoryId !== 0)
                ?.map((x) => ({
                  value: x.LaboratoryId,
                  text: x.Laboratory,
                })),
            },
          ]
        },
        {
          xsCol: 3,
          justifySelf: "right",
          group: [
            {
              name: "ComponentHours",
              label: "Component Hours",
              xsCol: 12,
            },
          ]
        },
        // {
        //   xsCol: 12,
        //   group: [

        //   ],
        // },
        {
          xsCol: 12,
          group: [
            {
              name: "JobNo",
              label: "Job No",
              xsCol: 3,
            },
            {
              name: "NewVersion",
              label: "New Version",
              xsCol: 2,
              type: "number",
            },
            {
              name: "BillingRate",
              label: "Billing Rate",
              xsCol: 2,
              type: "number",
            },
            {
              name: "Hours",
              label: "Hours",
              xsCol: 2,
              type: "number",
            },
            {
              xsCol: 3,
              alignSelf: "flex-end",
              component: function () {
                return (
                  <Typography  >
                    ${(data?.BillingRate * data?.Hours) || "0"}
                  </Typography>
                );
              },
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            {
              xsCol: 3,
              alignSelf: "flex-end",
              component: function () {
                return (
                  <input
                    type="file"
                  />
                );
              },
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            {
              name: "Release",
              label: "Release",
              xsCol: 2,
              type: "switch",
            },
            {
              name: "Export",
              label: "Export",
              xsCol: 2,
              type: "switch",
              disabled: true
            },
          ],
        },
        {
          xsCol: 12,
          name: "Comments",
          label: "Comments",
          type: "textarea",
          rows: 5
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return { ...SearchFilterDefaults, ...action.payload, _ts_key: Date.now() };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...SearchFilterDefaults, _ts_key: Date.now() };
  }
  return state;
}

const SearchFilterDefaults = {
  ReferenceDate: toIsoFormat(new moment()),
  LaboratoryId: 1,
  ReportType: 1,
};

const GetFormButtons = ({ saveClick }) => [
  {
    label: "Save",
    onClick: saveClick,
  },
];
