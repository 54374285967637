import DataTable, { DataGridAction } from "../../components/DataTable";
import { toDateTimeFormat } from "../../utils";

export default function ViewEventsTable({ data, rowSelected, addNew, ...props }) {
    const options = {
        ...props,
        sx: { height: 600 },
        columns: GetColumns(addNew),
        dataSource: data,
        rowIdProperty: "EventLogId",
        isDisableColumnFilter: true,
        rowsSelected: function (r) {
            r.length && rowSelected(r[0]);
        },
    };
    return <DataTable {...options}></DataTable>;
}

function GetColumns(addNew) {
    return [
        {
            headerName: "Edit",
            field: "Edit",
            sortable: false,
            width: 85,
            renderCell: function ({ row }) {
                return (
                    <DataGridAction
                        minWidth={"70px"}
                        row={row}
                        onClick={() => addNew(row)}
                        label={"Edit"}
                    />
                );
            },
        },
        {
            headerName: "Scope",
            field: "Scope",
            width: 100,
        },
        {
            headerName: "Item",
            field: "EventItem",
            width: 150,
        },
        {
            headerName: "Type",
            field: "EventType",
            width: 150,
            cellClassName: ({ row }) => {
                if (row.BackColour === "ForestGreen") {
                    return "cell-fg-wt";
                }
                if (row.BackColour === "DimGray") {
                    return "cell-dg-wt";
                }
                if (row.BackColour === "Red") {
                    return "cell-rd-wt";
                }
                if (row.BackColour === "Maroon") {
                    return "cell-mn-wt";
                }
                if (row.BackColour === "Purple") {
                    return "cell-pr-wt";
                }
                return "";
            },
        },
        {
            headerName: "Occurred",
            field: "EventDate",
            valueFormatter: ({ value }) => toDateTimeFormat(value),
            width: 180,
        },
        {
            headerName: "Hours",
            field: "UnitHours",
            width: 100,
        },
        {
            headerName: "Meter",
            field: "MeterReading",
            width: 100,
        },
        {
            headerName: "Event",
            field: "Event",
            width: 200,
            flex: 1,
        },
        {
            headerName: "Created By",
            field: "AddedBy",
            width: 100,
        },
        {
            headerName: "Created",
            field: "Added",
            width: 120,
            valueFormatter: ({ value }) => toDateTimeFormat(value),
        },
    ];
}