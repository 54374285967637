import { Button, Card, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useReducer, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Form, { FormActions } from "../../../components/Form";
import { TRAY_APIS } from "../../../common/apis";
import { SearchIcon } from "../../../icons/ButtonIcons";
import { useUser } from "../../../rootContext";
import InsiteMachineTable from "./InsiteMachineTable";

export default function InsiteMachineSearch({ selectData, userData }) {
  const user = useUser();

  const SearchFilterDefaults = {
    Make: "KOMATSU",
    Model: "",
    Serial: userData?.SerialOriginal || userData?.Serial,
    FleetNumber: "",
    userName: user.Username,
  };

  const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);

  const { post, } = useFetch();

  const [feedbackData, setFeedbackData] = useState([]);

  async function search() {
    let resData = await post(TRAY_APIS.MACHINE_INSITE, { ...data, });
    if (resData) {
      setFeedbackData(resData);
    }
  }

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (data?.Make) {
          search();
        }
      }
    }, [data]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1, minWidth: "80vw" }}>
            <Form
              sections={GetFormSections(search, data)}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            sx={{ marginLeft: "5px", marginTop: "10px" }}
          >
            Total Records: <b>{feedbackData && feedbackData.length}</b>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <InsiteMachineTable
            feedbackData={feedbackData}
            selectData={selectData}
          />
        </Grid>
      </Grid>
    </>
  );
}

function GetFormSections(search, data) {
  return [
    {
      fluid: true,
      fields: [
        {
          xsCol: 12,
          group: [
            {
              name: "Make",
              label: "Make",
            },
            {
              name: "Model",
              label: "Model",
            },
            {
              name: "Serial",
              label: "Serial",
            },
            {
              name: "FleetNumber",
              label: "Fleet No.",
            },

            {
              alignContent: "end",
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={search}
                    disabled={
                      data?.Make == "" &&
                        data?.Model == "" &&
                        data?.Serial == "" &&
                        data?.FleetNumber == ""
                        ? true
                        : false
                    }
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
