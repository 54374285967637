import { useTheme } from "@emotion/react";
import { Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../components/DataTable";
import Form, { FormActions } from "../../components/Form";
import useFetch from "../../hooks/useFetch";
import EditLaboratory from "./EditLaboratory";
import AppDialog from "../../components/AppDialog";
import { MASTER_DATA_APIS } from "../../common/apis";

export default function Laboratories({ }) {
  const [data, dispatch] = useReducer(SearchFilterReducer);
  const curTheme = useTheme();
  const { get } = useFetch();

  const [labData, setLabData] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [editLaboratoryDialogueOpen, setEditLaboratoryDialogueOpen] = useState(false);
  //Call api for user dropdown data
  useEffect(() => {
    document.title = "Laboratories";
    (async function () {
      let resData = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Laboratories`, {});
      if (resData) {
        setLabData(resData.Laboratories);
      }
    })();
  }, []);
  //use memo
  const filteredData = useMemo(
    () =>
      data?.ShowSystemLaboratories
        ? labData
        : (labData || []).filter((x) => {
          return !x.IsForImporting;
        }),
    [data, labData]
  );
  const tblOptions = {
    columns: GetColumns(editClick),
    dataSource: (filteredData || []).map((x) => ({
      ...x,
      keyProp: x.LaboratoryId,
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "laboratories",
    sx: { height: 300 },
  };
  function editClick(row) {
    setRowData(row);
    setEditLaboratoryDialogueOpen(true);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1, minWidth: "40vw" }}>
            <Form
              sections={GetFormSections()}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData?.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={editLaboratoryDialogueOpen}
        handleClose={() => setEditLaboratoryDialogueOpen(false)}
        title="Edit Laboratory for CMS Brisbane"
        maxWidth="xl"
      >
        <EditLaboratory formData={rowData} />
      </AppDialog>
    </>
  );
}

function GetColumns(editClick) {
  return [
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </>
        );
      },
    },
    {
      headerName: "Laboratory",
      field: "Laboratory",
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Importing",
      field: "IsForImporting",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 70,
    },
    {
      headerName: "Default",
      field: "IsDefault",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 70,
    },
    {
      headerName: "Emails",
      field: "Emails",
      renderCell: ({ row }) =>
        [row.Email1, row.Email2, row.Email3].filter((x) => x).length,
      minWidth: 100,
    },
    {
      headerName: "Mobiles",
      field: "Mobiles",
      renderCell: ({ row }) =>
        [row.Mobile1, row.Mobile2, row.Mobile3].filter((x) => x).length,
      minWidth: 100,
    },
  ];
}
function GetFormSections() {
  return [
    {
      fields: [
        {
          name: "ShowSystemLaboratories",
          label: "Show System Laboratories",
          xsCol: 12,
          type: "switch",
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
