import { Card, Grid, Typography } from "@mui/material";
import { useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../components/DataTable";
import Form, { FormActions } from "../../components/Form";
import { SearchIcon } from "../../icons/ButtonIcons";
import FormFooter from "../../components/FormFooter";
import { CONTACTS_APIS } from "../../common/apis";
import useFetch from "../../hooks/useFetch";
import { ValidateContactSearch } from "../../validation/schemas/SearchContactSchema";

export default function SearchPortalUsers({ handleSelectCustomer }) {

  const { post } = useFetch();

  const [data, dispatch] = useReducer(SearchFilterReducer, defaultData);
  const [formErrors, setFormErrors] = useState({});
  const [labData, setLabData] = useState([]);
  const [isValidSearch, setIsValidSearch] = useState(false);

  function ValidateEmail() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(data?.Email)) {
      return (true)
    }
    return (false)
  }

  const handleSearchCustomers = async (payload) => {
    const resp = await post(CONTACTS_APIS.SEARCH_CUSTOMERS_CONTACTS, payload);
    setLabData(resp)
  }

  const actions = {
    searchContact: function (e) {
      setFormErrors({});
      if (
        (data && data.Email && data.Email.trim()) ||
        (data && data.FirstName && data.FirstName.trim()) ||
        (data && data.LastName && data.LastName.trim())
      ) {
        setIsValidSearch(false);
      } else {
        setIsValidSearch(true);
      };
      let validCheck = ValidateEmail();

      const payload = {
        Mail: data?.Email,
        FirstName: data?.FirstName,
        LastName: data?.LastName,
        ValidEmail: validCheck,
      }
      ValidateContactSearch(data).then(async function ({ data: req, errors }) {
        if (req) {
          handleSearchCustomers(payload)
        }
        if (errors) {
          setFormErrors(errors);
        }

      })
    },
    clear: function () {
      dispatch({
        type: FormActions.RESET_FORM
      })
    }
  };

  const selectClick = (row) => {
    handleSelectCustomer && handleSelectCustomer(row)
  }

  const tblOptions = {
    columns: GetColumns(selectClick),
    dataSource: (labData || []).map((x, i) => ({
      ...x, keyProp: i,
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    sx: { height: 600 },
  };

  return (
    <>
      <Card sx={{ p: 1, minWidth: "80vw" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Form
              sections={GetFormSections()}
              data={data}
              dispatch={dispatch}
              errors={formErrors}
              key={data?._key}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: -5.5 }}>
            <FormFooter buttons={GetFormButtons(actions)} />
          </Grid>
        </Grid>
      </Card>

      <Grid container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography >
            Total Records: <b> {labData && labData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(selectClick) {
  return [
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      width: 80,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={selectClick} label="Select" />
          </>
        );
      },
    },
    {
      headerName: "Username",
      field: "Username",
      minWidth: 150,
      flex: 1
    },
    {
      headerName: "Source",
      field: "Source",
      width: 100,
    },
    {
      headerName: "Display",
      field: "DisplayName",
      width: 100,
    },
    {
      headerName: "Telephone",
      field: "Telephone",
      width: 100,
    },
    {
      headerName: "Mobile",
      field: "Mobile",
      width: 100,
    },
    {
      headerName: "State",
      field: "State",
      width: 100,
    },
    {
      headerName: "Country",
      field: "Country",
      width: 100,
    },
    {
      headerName: "Portal",
      field: "PortalIsNz",
      width: 100,
    },
    {
      headerName: "Dynamics",
      field: "ExternalId",
      width: 100,
    },
    {
      headerName: "Role",
      field: "PortalCmsRole",
      width: 100,
    },
    {
      headerName: "Status",
      field: "PortalLockedDisabled",
      width: 100,
    },
    {
      headerName: "Last Activity",
      field: "Modified",
      width: 150,
    },
  ];
}
function GetFormSections() {
  return [
    {
      fields: [
        {
          name: "Email",
          label: "Email",
          xsCol: 3,
        },
        {
          name: "FirstName",
          label: "First Name",
          xsCol: 3,
        },
        {
          name: "LastName",
          label: "Last Name",
          xsCol: 3,
        },
      ],
    },
  ];
}

const defaultData = { Mail: "", FirstName: "", LastName: "", }

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...defaultData, _key: Date.now() };
  }
  return state;
}

const GetFormButtons = ({ searchContact, clear }) => [
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
    startIcon: <SearchIcon />,
  },
  {
    label: "Search",
    onClick: searchContact,
    startIcon: <SearchIcon />,
  },
];
