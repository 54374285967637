import { Box, Card, Grid, Typography } from "@mui/material";
import logo from "../Image/kowa.svg";
import appResources from "../app-resources/en/Resources.json";

export default function UserNotFound({ account }) {
  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
            component="img"
            sx={{
              height: 30,
              m: 1,
            }}
            alt="KOWA"
            src={logo}
          />
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ m: 2, fontSize: 14 }}>
            User {account.name} (email: {account.username})
          </Typography>
        </Grid>
      </Grid>

      <h3 style={{ color: "red", textAlign: "center" }}>
        {appResources.USER_NOT_FOUND}
      </h3>
    </Card>
  );
}
