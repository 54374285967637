import { Grid } from "@mui/material";
import { useCallback, useReducer, } from "react";
import { DETAILS_APIS } from "../../../common/apis";
import useMasterData from "../../../hooks/useMasterData";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import { Typography } from "@mui/material";
import { useRoot, useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import { useState } from "react";
import { useEffect } from "react";
import AppDialog from "../../../components/AppDialog";
import ManageFluids from "./ManageFluids";

export default function NewFluid({
  fetchFluidsDetails,
  rowManageSelected,
  getFluidControlsData,
  isEdit,
  cancel,
}) {

  const { post } = useFetch();
  const { getMasterData } = useMasterData();
  const { showInfoToast, showErrorToast } = useToast();
  const { CanDoDataMaintenance } = useRoot()
  const [data, dispatch] = useReducer(FluidReducer, rowManageSelected || defaultFilter);
  const [errMessage, setErrMessage] = useState("")
  const [masterData, setMasterData] = useState({});
  const [openModal, setOpenModal] = useState(false);

  async function fetchFluidType() {
    const temp = await getMasterData("FluidTypes", ``);
    setMasterData(temp || {});
  }

  const handleSave = async () => {
    const response = await post(DETAILS_APIS.UPDATE_FLUID, {
      ...data,
      Screen: "Fluids",
    });
    if (response > 0) {
      getFluidControlsData && getFluidControlsData();
      cancel && cancel();
    } else {
      showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
    }
  }

  const handleSaveNew = async () => {
    const response = await post(DETAILS_APIS.ADD_FLUID, {
      ...data,
      Screen: "Fluids",
    });
    if (response > 0) {
      fetchFluidsDetails && fetchFluidsDetails(0);
      getFluidControlsData && getFluidControlsData()
      cancel && cancel();
    } if (response === -1) {
      setErrMessage("This Fluid name already exists.")
    }
  }

  const actions = {
    save: async function () {
      if (data?.FluidLevel1 === "" || data?.FluidLevel1 === undefined) {
        return showInfoToast(appResources.NAME_ATLEAST_LEVEL1_MSG);
      }
      if (data?.FluidId && isEdit === true) {
        handleSave()
      } else {
        handleSaveNew()
      }
    },
    handleSaveNew: handleSaveNew,
    manage: function () {
      setOpenModal(true)
    },
    close: cancel,
  };

  const handleKeyDown = useCallback(
    (event) => {
      event.stopPropagation();

      if (event.key === "F1") {
        event.preventDefault();
        (openModal === false) && actions.close()
      }
      else if (event.key === "F11") {
        event.preventDefault();
        (openModal === false && CanDoDataMaintenance === true) && actions.manage()
      }
      else if (event.key === "F12") {
        event.preventDefault();
        (CanDoDataMaintenance === true && openModal === false) && actions.save()
      }
    }, [openModal, data, CanDoDataMaintenance]
  );

  useEffect(() => {
    fetchFluidType();
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Grid container  >
      <Grid item xs={12}>
        <Form
          sections={GetFormSections(masterData, isEdit, errMessage)}
          data={data}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item xs={12}>
        <FormFooter
          buttons={GetFormButtons(isEdit, data, actions, CanDoDataMaintenance)}
        />
      </Grid>
      <AppDialog
        open={openModal}
        title="Manage Fluid"
        maxWidth="md"
        handleClose={() => setOpenModal(false)}
      >
        <ManageFluids
          SelectedFluid={data}
          fetchFluidsDetails={fetchFluidsDetails}
          cancel={() => setOpenModal(false)}
        />
      </AppDialog>
    </Grid>
  );
}

function GetFormSections(data, isEdit, errMessage) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          hidden: isEdit && isEdit,
          component: () => (
            <Typography>Please add the details for the new fluid</Typography>
          ),
        },
        {
          xsCol: 12,
          hidden: !(errMessage?.length > 0),
          component: () => (
            <Typography sx={{ color: "red" }}>{errMessage}</Typography>
          ),
        },
        {
          xsCol: 12,
          component: () => (
            <Typography sx={{ color: "rgb(145,17,146)" }}>
              Use KOWA naming standards as manufacturer names are inconsistent
              <br />
              When a new fluid is to be created check with another person first,
              as a safety measure
            </Typography>
          ),
        },
        {
          xsCol: 1,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              Type
            </Typography>
          ),
        },
        {
          xsCol: 3,
          name: "FluidTypeId",
          // label: "Type",
          type: "dropdown",
          selectOptions: (data.FluidTypes || []).map((x) => ({
            value: x.FluidTypeId,
            text: x.FluidType,
          })),
          defaultValue: 0,
        },
        {
          xsCol: 8,
          component: () => <Typography></Typography>,
        },
        {
          xsCol: 1,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              Name
            </Typography>
          ),
        },
        {
          xsCol: 6,
          name: "FluidLevel1",
          // label: "Level 1 (e.g. make)",
        },
        {
          xsCol: 5,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              Level 1 (e.g. make)
            </Typography>
          ),
        },
        {
          xsCol: 1,
          component: () => <Typography></Typography>,
        },
        {
          xsCol: 6,
          name: "FluidLevel2",
          // label: "Level 2 (e.g. type)",
        },
        {
          xsCol: 5,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              Level 2 (e.g. type)
            </Typography>
          ),
        },
        {
          xsCol: 1,
          component: () => <Typography></Typography>,
        },
        {
          xsCol: 11,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              For multigrades, use a DASH -, e.g. 15W-40; 85W-140
              <br />
              For single grades, use a W, e.g. 10W
              <br />
              For ISO grades, use the grade only, e.g. 68
            </Typography>
          ),
        },

        {
          xsCol: 1,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            ></Typography>
          ),
        },
        {
          xsCol: 6,
          name: "FluidLevel3",
          // label: "Level 3 (e.g. grade)",
        },
        {
          xsCol: 5,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              Level 3 (e.g. grade)
            </Typography>
          ),
        },
      ],
    },
  ];
}

const GetFormButtons = (isEdit, data, { manage, save, handleSaveNew, close }, CanDoDataMaintenance,) => [
  {
    hidden: !(isEdit && CanDoDataMaintenance),
    variant: "outlined",
    label: "Manage (F11)",
    onClick: manage,
  },
  {
    hidden: !(isEdit && CanDoDataMaintenance),
    variant: "outlined",
    label: "Save New ",
    onClick: handleSaveNew,
  },
  CanDoDataMaintenance && {
    variant: "contained",
    label: "Save (F12)",
    onClick: save,
  },
  {
    variant: "outlined",
    label: "Close (F1)",
    onClick: close,
  },
];

const EventActions = {
  LOAD_DATA: "load-data",
};

function FluidReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
const defaultFilter = {
  FluidTypeId: 1,
};
