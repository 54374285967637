import { Button, Grid, Typography, } from "@mui/material";
import { useCallback, useEffect, useReducer, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import HoldTable from "./HoldTable";
import { EditIcon, } from "../../../icons/ButtonIcons";
import { TRAY_APIS } from "../../../common/apis";
import AppDialog from "../../../components/AppDialog";
import { useRoot } from "../../../rootContext";
import useQuery from "../../../hooks/useQuery";
import ConfirmModal from "../../../components/ConfirmModal";
import Form, { FormActions } from "../../../components/Form";
import { toLocalDateTime2, } from "../../../utils";

const todayDate = new Date();
const maxDateAllowed = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth(),
  todayDate.getDate() + 5
);

const currentDate = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth(),
  todayDate.getDate() + 1
);

export default function Hold({
  sampleId: sampleNumber,
  refreshGrid,
  setHoldOpen,
}) {
  const { get } = useFetch();

  const [data, dispatch] = useReducer(holdReducer, holdDefaults);

  const { CanDoDataMaintenance } = useRoot();
  const [holdTypes, setHoldTypes] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dialog, setDialog] = useState({ open: false, message: "" });

  const { urlsampleNumber } = useQuery();
  const hasData = !!dataSource.length;

  const getSampleData = async () => {
    const sampleData = await get(`${TRAY_APIS.GET_TRAY_SAMPLES_HOLD}?sampleNumber=${sampleNumber || urlsampleNumber}&showArchived=true`);
    if (sampleData.TraySamplesOnHold.length > 0) {
      dispatch({ type: HoldActions.LOAD_DATA, payload: sampleData.TraySamplesOnHold[0] });
      setDataSource(sampleData.TraySamplesOnHold);
    } else {
      dispatch({ type: HoldActions.RESET_FORM });
      setDataSource([]);
    }

    if (sampleData.HoldTypes.length > 0) {
      setHoldTypes(
        sampleData.HoldTypes.map((x) => ({
          HoldTypeId: x.HoldTypeId,
          HoldType: x.HoldType,
        }))
      );
    }
  }

  //hold sample click
  async function holdSampleClick() {
    const resp = await get(`${TRAY_APIS.SAMPLES_HOLD}?SampleNumber=${sampleNumber || urlsampleNumber}&holdTypeId=${data?.HoldTypeId}&holdUntil=${data?.HoldUntil}&holdNotes=${data?.HoldNotes}&Screen=Put on Hold`);
    if (resp >= 0) {
      getSampleData()
      setHoldOpen && setHoldOpen(false);
      refreshGrid && refreshGrid();
      setDialog && setDialog((prevState) => ({ ...prevState, open: false }));
      if (window.location.pathname === '/hold') {
        localStorage.setItem("return-to-lab-resp", JSON.stringify({ resp: true }));
        window.close();
      }
    }
  }

  //hold tray click
  async function holdTrayClick() {
    if (!sampleNumber === 0) return;
    setDialog({
      open: true,
      message: "Are you sure you want to ON HOLD all the samples?",
    });
  }

  async function holdTrayClickAlert() {
    const resp = await get(`${TRAY_APIS.SAMPLES_TRAY_HOLD}?SampleNumber=${sampleNumber || urlsampleNumber}&holdTypeId=${data.HoldTypeId}&holdUntil=${data?.HoldUntil}&holdNotes=${data?.HoldNotes}&Screen=Put on Hold from Laboratory`);
    if (resp > 0) {
      setHoldOpen && setHoldOpen(false);
      refreshGrid && refreshGrid();
      setDialog && setDialog((prevState) => ({ ...prevState, open: false }));
      if (window.location.pathname === '/hold') {
        localStorage.setItem("return-to-lab-resp", JSON.stringify({ resp: true }));
        window.close();
      }
    }
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F11" && dataSource.length > 0) {
        event.preventDefault();
        holdTrayClick();
      } else if (event.key === "F12" && dataSource.length > 0) {
        event.preventDefault();
        holdSampleClick();
      }
    },
    [dataSource]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(function () {
    getSampleData()
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ width: "80vw", mb: 2 }}>
        <Grid item xs={12}>
          {!hasData ? (
            <Typography variant="subtitle2" sx={{ color: "red" }}>
              The Tray for samples {sampleNumber} has no samples on hold
            </Typography>
          ) : (
            <Typography variant="subtitle2" style={{}}>
              Tray {dataSource[0].Tray} has{" "}
              {dataSource.filter((item) => item.HoldTypeId !== null).length} of{" "}
              {dataSource[0].TraySampleCount} samples on hold including sample{" "}
              {sampleNumber}:{dataSource[0].HoldTypeName}
            </Typography>
          )}
        </Grid>

        <Grid item xs={9}>
          <Form
            sections={FormSections(holdTypes)}
            data={data}
            spacing={2}
            dispatch={dispatch}
            key={data._key}
          />
        </Grid>

        <Grid item xs={3} sx={{ mt: 10 }} >
          <Grid container spacing={1}>

            {CanDoDataMaintenance && (
              <Grid item >
                <Button
                  item
                  variant="contained"
                  onClick={holdTrayClick}
                  disabled={data?.HoldNotes <= 0}
                >
                  Hold Tray (F11)
                </Button>
              </Grid>)}

            {CanDoDataMaintenance && !hasData && (
              <Grid item >
                <Button
                  item
                  variant="contained"
                  onClick={holdSampleClick}
                  disabled={data?.HoldNotes <= 0}
                >
                  Hold Sample (F12)
                </Button>
              </Grid>)}

            {hasData && (
              <Grid item >
                <Button
                  item
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={holdSampleClick}
                  disabled={data?.HoldNotes <= 0}
                >
                  Update
                </Button>
              </Grid>)}
          </Grid>
        </Grid>
      </Grid>

      <HoldTable
        data={dataSource}
      />

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Hold All Samples"
        maxWidth="md"
      >
        <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={holdTrayClickAlert}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}

const FormSections = (
  holdTypes
) => {
  return [
    {
      xsCol: 12,
      fields: [
        {
          name: "HoldTypeId",
          label: "Hold Type",
          type: "dropdown",
          xsCol: 3,
          selectOptions: (holdTypes || []).map((x) => ({
            value: x.HoldTypeId,
            text: x.HoldType,
          })),
        },
        {
          name: "HoldUntil", label: "Hold Until",
          xsCol: 3, type: "datetime",
          maxDate: toLocalDateTime2(maxDateAllowed),
          minDate: toLocalDateTime2(currentDate),
          disablePast: true
        },
        { name: "HoldNotes", label: "Reason", xsCol: 12, type: "textarea", },
      ]
    }]
}

const holdDefaults = {
  HoldUntil: toLocalDateTime2(currentDate),
  HoldTypeId: 2,
  HoldNotes: "",
  _key: Date.now()
}

const HoldActions = {
  LOAD_DATA: "load-data",
};

function holdReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === HoldActions.LOAD_DATA) {
    return { ...action.payload, _key: Date.now() };
  }
  if (action.type === HoldActions.RESET_FORM) {
    return { ...holdDefaults, _key: Date.now() };
  }
  return state;
}
