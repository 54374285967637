import { FormActions } from "../../components/Form";
export const UnitActions = {
  LOAD_DATA: "load-data",
};
export default function UnitReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === UnitActions.LOAD_DATA) {
    return { ...action.payload, _key: Date.now() };
  }
  return state;
}
