import { useTheme } from "@emotion/react";
import { Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import DataTable, { DataGridAction } from "../../../../components/DataTable";
import useMasterData from "../../../../hooks/useMasterData";

export default function SearchParent({ selectClick }) {
  const curTheme = useTheme();
  const { masterData } = useMasterData("ParentsAll");

  const filteredData = useMemo(
    () =>
      (masterData.ParentsAll || []).filter((x) => {
        return !x.IsArchived;
      }),
    [masterData.ParentsAll]
  );

  const tblOptions = {
    columns: GetColumns(selectClick),
    dataSource: filteredData || [],
    rowIdProperty: "ParentId",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    sx: { height: 500, minWidth: 500 },
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(selectClick) {
  return [
    {
      headerName: "Select",
      field: "Select",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={selectClick} label="Select" />
          </>
        );
      },
    },
    {
      headerName: "Parent",
      field: "Parent",
      minWidth: 200,
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 160,
      flex: 1,
    },
  ];
}
