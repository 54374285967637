import { Close, Search } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useReducer, useState } from "react";
import { CONTACTS_APIS, DETAILS_APIS, MASTER_DATA_APIS } from "../../../../common/apis";
import Form, { FormActions } from "../../../../components/Form";
import FormFooter from "../../../../components/FormFooter";
import useFetch from "../../../../hooks/useFetch";
import { useToast } from "../../../../rootContext";
import appResources from "../../../../app-resources/en/Resources.json";
import renderCellExpand from "../../../../components/DatatableCellExpand";
import NewDataTable, { NewDataGridAction } from "../../../../components/NewDataTable";

export default function SearchComponent({
  actions,
  contactId,
  propId,
  rowSelected,
  selectedUnit,
}) {
  const { showWarningToast } = useToast();
  const [componentsData, setComponentsData] = useState([]);
  const { get, post } = useFetch();
  const [data, dispatch] = useReducer(SearchFluidsFilterReducer, searchFilterDefaults);
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    (async function () {
      const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Products`);
      //Products with AllProducts added at 0 index
      const allProd = JSON.parse(JSON.stringify(response.Products));
      if (allProd) {
        allProd.unshift({
          ProductId: 0,
          Product: "All Products",
          Colour: "",
        });
      }
      setAllProducts(allProd || {});
    })();

    if (selectedUnit) {
      dispatch({
        type: FormActions.LOAD_DATA,
        payload: {
          ProductId: selectedUnit?.ProductId,
          Customer: selectedUnit?.Customer,
          Site: selectedUnit?.Site,
          Unit: selectedUnit?.Unit,
          UseStartsWith: false,
          _key: Date.now(),
        },
      });
    }
  }, [selectedUnit]);

  const tblOptions = {
    columns: GetColumns(actions),
    dataSource: componentsData,
    getRowId: (originalRow) => originalRow.ComponentId,
    enableColumnActions: false,
    sx: { height: "50dvh", },

    cellStylingHandler: "productCommonCellColour",
    rowsSelected: function (rows) {
      rowSelected && rowSelected(rows[0]);
    },

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx:
          cell.column.columnDef.accessorKey === "Product" && row.original.Product ? {
            backgroundColor: (row?.original?.ProductColour + "!important") || "",
            color: "white",
            py: 0,
            border: "0.5px solid #d0d0d0",
          } : {
            py: 0,
            border: "0.5px solid #d0d0d0",
          }
      }
    },

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
  };

  const formActions = {
    clear: function () {
      dispatch({
        payload: {
          ...searchFilterDefaults,
          _key: Date.now(),
        },
        type: FormActions.RESET_FORM,
      });
    },

    search: async function () {
      if (contactId) {
        const tempComponentData = await post(
          CONTACTS_APIS.COMPONENTS_FOR_CONTACT,
          {
            ...data,
            ContactId: contactId,
            ExcludeScoped: (propId === 2) ? true : false,
            UseStartsWith: data?.UseStartsWith === true ? true : false,
            ShowAll: true,
          }
        );
        if (tempComponentData.length > 0) {
          setComponentsData(tempComponentData);
        } else {
          showWarningToast(appResources.NO_MATCHING_COMPONENTS_WERE_FOUND_MSG);
          setComponentsData(tempComponentData);
        }
      } else {
        const tempComponentData = await post(DETAILS_APIS.SEARCH_COMPONENTS, {
          ...data,
        });
        if (tempComponentData.length > 0) {
          let filteredData = tempComponentData.filter(item => item?.ComponentId !== selectedUnit?.ComponentId);
          setComponentsData(filteredData);
        } else {
          showWarningToast(appResources.NO_MATCHING_COMPONENTS_WERE_FOUND_MSG);
          setComponentsData(tempComponentData);
        }
      }
    },
  };

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (data?.Customer || data?.Site || data?.Unit || data?.Unit || data?.Component || data?.Serial) {
          formActions?.search();
        }
      }
    }, [data]);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <Grid container size={800}>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(allProducts)}
            data={data}
            dispatch={dispatch}
            key={data && data._key}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: -5 }}>
          <FormFooter
            buttons={GetFormButtons(data, formActions)}
            hideDivider
            gmt={0}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="subtitle2">
            Total Records: <b> {componentsData && componentsData.length}</b>
          </Typography>
          <NewDataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

const GetFormButtons = (data, { clear, search }) => [
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
    startIcon: <Close />,
  },
  {
    variant: "contained",
    label: "Search",
    disabled: !(data?.Customer || data?.Site || data?.Unit || data?.Unit || data?.Component || data?.Serial),
    onClick: search,
    startIcon: <Search />,
  },
];

function GetColumns(actions) {
  return [
    {
      header: "Actions",
      size: 45,
      hide: !actions || !actions.length,
      Cell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <NewDataGridAction
              minWidth={"45px"}
              row={row?.original}
              onClick={ac.action}
              label={ac.label}
            // startIcon={ac.startIcon}
            />
          );
        });
      },
    },
    {
      header: "Product",
      accessorKey: "Product",
      size: 100,
    },
    {
      header: "Customer",
      accessorKey: "Customer",
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
      size: 200,
    },
    {
      header: "Unit",
      accessorKey: "Unit",
      size: 150,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Model",
      accessorKey: "UnitModelFormatted",
      size: 150,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Serial",
      accessorKey: "Serial",
      size: 100,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Site",
      accessorKey: "Site",
      size: 120,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Component",
      accessorKey: "Component",
      size: 125,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Predelivery",
      accessorKey: "Predelivery",
      size: 60,
      enableSorting: false,
    },
    {
      header: "Parked",
      accessorKey: "Parked",
      size: 50,
      enableSorting: false,
    },
    {
      header: "Scrapped",
      accessorKey: "Scrapped",
      size: 50,
      enableSorting: false,
    },
    {
      header: "Archived",
      accessorKey: "Archived",
      size: 50,
      enableSorting: false,
    },
  ];
}

function GetFormSections(allProducts) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 2,
          name: "ProductId",
          label: "Product",
          type: "dropdown",
          required: true,
          selectOptions: (allProducts || []).map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
        },
        {
          xsCol: 2,
          name: "Site",
          label: "Site",
        },

        {
          xsCol: 2,
          name: "Customer",
          label: "Customer",
        },
        {
          xsCol: 2,
          name: "Unit",
          label: "Unit",
        },

        {
          xsCol: 4,
          name: "UseStartsWith",
          label: "Starts With Search",
          type: "switch",
        },
        {
          xsCol: 2,
          name: "Component",
          label: "Component",
        },
        {
          xsCol: 2,
          name: "Serial",
          label: "Serial",
        },
      ],
    },
  ];
}
const searchFilterDefaults = {
  ProductId: 0,
  UseStartsWith: false,
};
function SearchFluidsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return { ...action.payload };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  return state;
}
