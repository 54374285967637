import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import SwitchInput from "../../components/SwitchInput";
import useFetch from "../../hooks/useFetch";
import { DETAILS_APIS, } from "../../common/apis";
import ViewEventsTable from "./ViewEventsTable";
import DropDown from "../../components/DropDown";
import { useScreenPopups } from "../../components/useScreenPopups";
import GroupDialog from "../../components/GroupDialog";
import { useWindowPopups } from "../../components/useWindowPopups";

export default function ViewEvents({ dataObj, startLetter, setExpandTreeData, handleSearchTree, }) {

    const { get } = useFetch();

    const [tableData, setTableData] = useState([])
    const [hideEvents, setHideEvents] = useState(true);
    const [ddList, setDDList] = useState([]);
    const [dialogType, setDialogType] = useState({ open: false });
    const [selectedId, setSelectedId] = useState(0);
    const [unitData, setUnitData] = useState({})
    const [localData, setLocalData] = useState({})

    const getUnitData = async (id) => {
        const temp = await get(`${DETAILS_APIS.EVENT_LOGS_ALL}?UnitId=${id || 0}`);
        setTableData(temp)
    }

    const getComponentData = async (id) => {
        const temp = await get(`${DETAILS_APIS.EVENT_LOGS_ALL}?ComponentId=${id || 0}`);
        setTableData(temp)
    }

    const handelFilterChange = async (e) => {
        const unitCheck = e.target.value.includes("Unit:")
        const idVal = Number(e.target.value.split(";")[1])
        setSelectedId(e.target.value);

        if (unitCheck !== true) { return getComponentData(idVal) }
        if (unitCheck === true) { return getUnitData(idVal) }
    }

    const refreshData = async (ld) => {
        const treeData = await get(`${DETAILS_APIS.SEARCH_TREE}?types=unit,component&uid=${dataObj?.UnitId || ld?.UnitId}&compid=${dataObj?.ComponentId || ld?.ComponentId || 1}&ShowArchived=${true}`, { skipBlocker: true });
        const compList = treeData?.componentsWithCount.map(item => { return ({ Id: item?.ComponentId, Name: item?.Component }) })
        const unitData = treeData?.sampleDetails?.Unit;
        setUnitData(unitData)
        setDDList([{ Id: unitData?.UnitId, Name: "Unit:" + unitData?.Unit }, ...compList,])
        localStorage.removeItem("view-event");
        if (dataObj?.ComponentId > 0 || ld?.ComponentId > 0) { setSelectedId((dataObj?.Component || treeData?.sampleDetails?.Component?.Component || "") + ";" + (dataObj?.ComponentId || ld?.ComponentId)); return getComponentData(dataObj?.ComponentId || ld?.ComponentId) }
        if (dataObj?.UnitId > 0 || ld?.UnitId > 0) { setSelectedId("Unit:" + unitData?.Unit + ";" + (dataObj?.UnitId || ld?.UnitId)); return getUnitData(dataObj?.UnitId || ld?.UnitId) }
    }

    const closeDialog = () => {
        setDialogType({ ...dialogType, open: false });
    }

    const handleEditClick = (rowData) => {
        const eventData = {
            ...rowData,
            Product: unitData?.product || unitData?.Product,
            ProductId: unitData?.ProductId || localData?.ProductId,
            Customer: unitData?.customer || unitData?.Customer,
            CustomerId: dataObj?.CustomerId || localData?.CustomerId,
            Site: dataObj?.site || unitData?.Site,
            SiteId: dataObj?.SiteId || localData?.SiteId,
            Unit: dataObj?.unit || unitData?.Unit,
            UnitId: dataObj?.UnitId || localData?.UnitId,
            ComponentId: dataObj?.ComponentId || localData?.ComponentId,
            SampleNumber: rowData?.SampleNumber || localData?.SampleNumber,
            StartLetter: startLetter || localData?.StartLetter,
        }
        HandleNewWindow("edit-event", eventData)
    }

    const dialogOptions = useScreenPopups({
        dialogType: dialogType.type,
        data: dialogType.data || localData || {},
        cancel: closeDialog,
        fetchData: refreshData,
        setExpandTreeData: setExpandTreeData,
        handleSearchTree: handleSearchTree,
    });

    const filteredTableData = useMemo(
        function () {
            return hideEvents
                ? tableData.filter((x) => x.EventTypeId !== 3)
                : tableData;
        },
        [hideEvents, tableData]
    );

    const HandleNewWindow = (actionName, data) => {
        useWindowPopups(actionName, data)
    }

    useEffect(() => {
        let le = JSON.parse(localStorage.getItem("view-event"))
        if (le?.UnitId > 0) {
            setLocalData(le);
            refreshData(le)
        } else {
            refreshData()
        }
    }, [])

    return (
        <>
            <Card container sx={{ minWidth: "90vw", p: 1 }}>
                <Grid container sx={{ my: 1 }} spacing={1}>
                    <Grid item xs={2}>
                        <DropDown
                            name="scope"
                            label="Scope"
                            selectOptions={(ddList || [])?.map((x) => ({
                                value: x.Name + ";" + x.Id,
                                text: x.Name,
                            }))}
                            value={selectedId}
                            onChange={handelFilterChange}
                            required />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                        <SwitchInput
                            label="Show All Scopes"
                        />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                        <SwitchInput
                            label="Hide Fluid Change Events"
                            value={hideEvents}
                            onChange={(e) => setHideEvents(e.target.checked)}
                        />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                        <Button
                            variant="outlined"
                            onClick={() => { HandleNewWindow("edit-event", dataObj || localData); window.close() }}
                        >New</Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        <b>{tableData?.length || 0}</b> event(s) were found for Unit/Component
                    </Typography>
                    <ViewEventsTable data={filteredTableData} addNew={handleEditClick} />
                </Grid>
            </Card>
            <GroupDialog
                {...dialogOptions}
                open={dialogType.open || false}
                close={closeDialog}
            />
        </>
    );
}
