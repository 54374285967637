import { useTheme } from "@emotion/react";
import { Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../../../components/DataTable";
import Form, { FormActions } from "../../../../components/Form";
import { CONTACTS_APIS } from "../../../../common/apis";
import useFetch from "../../../../hooks/useFetch";

export default function ProductSearch({ ContactId, propId, selectClick }) {
  const [data, dispatch] = useReducer(SearchFilterReducer, defaultProducts);
  const curTheme = useTheme();
  const { get } = useFetch();
  //get api for loading data
  const [productsData, setProductsData] = useState([]);

  useEffect(() => {
    (async function () {
      let resData = await get(`${CONTACTS_APIS.PRODUCTS_FOR_CONTACT}?ContactId=${ContactId}&ExcludeScoped=${(propId === 2) ? true : false}&ShowAll=true`, {});
      if (resData) {
        setProductsData(resData);
      } else {
        setProductsData([]);
      }
    })();
  }, []);
  //use memo
  const filteredData = useMemo(
    () =>
      data?.ShowArchived
        ? productsData
        : (productsData || []).filter((x) => {
          return !x.IsArchived;
        }),
    [data, productsData]
  );
  const tblOptions = {
    columns: GetColumns(selectClick),
    dataSource: filteredData || [],
    rowIdProperty: "ProductId",
    isDisableColumnFilter: true,
    cellStylingHandler: "product",
    sx: { height: "500px", minWidth: 500 },
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections()}
              data={data}
              dispatch={dispatch}
              key={data.ProductId}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            sx={{ marginLeft: "5px", marginTop: "7px" }}
          >
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(selectClick) {
  return [
    {
      headerName: "Select",
      field: "Select",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={selectClick} label="Select" />
          </>
        );
      },
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 200,
    },
    {
      headerName: "AutoEval",
      field: "CanAutoEvaluate",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 100,
    },
    {
      headerName: "Evaluate",
      field: "NoEvaluations",
      valueFormatter: ({ value }) => (value ? "No" : "Yes"),
      minWidth: 100,
    },
    {
      headerName: "Archived",
      field: "IsArchived",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 100,
      flex: 1,
    },
  ];
}
function GetFormSections() {
  return [
    {
      fields: [
        {
          name: "ShowArchived",
          label: "Show Archived",
          type: "switch",
        },
      ],
    },
  ];
}

const defaultProducts = {
  ShowArchived: true,
};

const EventActions = {
  LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
