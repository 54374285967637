import * as React from "react";
import { styled, } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, colors, } from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Launch from "@mui/icons-material/Launch";
import { useEffect, useState } from "react";
import { useScreenPopups, } from "./useScreenPopups";
import GroupDialog from "./GroupDialog";
import { useWindowPopups } from "./useWindowPopups";
import { WEBSITE_APIS } from "../common/apis";
import useFetch from "../hooks/useFetch";

const StyledMenu = styled((props) => (
  <Menu
    sx={{
      margin: 0,
      [`.MuiSvgIcon`]: { color: "blue" }
    }}
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 150,
    color: "#425FF6",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        color: "#425FF6",
        marginRight: theme.spacing(1.5),
      },
      " a:visited": {
        color: "#425FF6"
      },
    },
  },
}));

export default function OptionMenu(props) {
  const { get } = useFetch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openCollapse, setOpenCollapse] = useState({});
  const [sampleEmails, setSampleEmails] = useState(0);
  const [dialogType, setDialogType] = useState({ open: false });

  const open = Boolean(anchorEl);

  const dialogOptions = useScreenPopups({
    cancel: closeDialog,
    dialogType: dialogType.type,
  });

  function openDialog(actionName) {
    setDialogType({ type: actionName, open: true, });
  }

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const parentTheme = useTheme();
  const theme = createTheme(parentTheme, {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: colors.common.white,
            color: parentTheme.palette.primary.main,
            "&:hover": {
              backgroundColor: parentTheme.palette.primary.main,
              color: colors.common.white,
            },
            fontSize: "11px", lineHeight: 0, height: "26px",
            [`& .MuiButton-startIcon>*:nth-of-type(1) `]: { fontSize: "12px" },
            [`& .MuiButton-endIcon `]: { marginLeft: "0px" }
          },
        },
      },
    },
  });

  function handleOpenSettings(id) {
    setOpenCollapse({ ...openCollapse, [id]: !openCollapse[id] });
  }

  const handleModalRoute = (modal) => {
    handleClose();
    openDialog(modal);
  }

  const mouseDownHandler = (event, x, index) => {
    if (event.button === 1) {
      if (x?.items && x?.items) { handleOpenSettings(index) }
      if (x?.modal && x?.modal) { handleModalRoute(x?.modal) }
      if (x?.window && x?.window) { HandleTabRoute(x?.window) }
    }
    else {
      return;
    }
  };

  function HandleTabRoute(actionName) {
    handleClose()
    useWindowPopups(actionName, {}, true)
  }

  const HandleWindowRoute = (actionName) => {
    handleClose();
    useWindowPopups(actionName, {})
  }

  const handleStorage = () => {
    const samplesList = localStorage.getItem('emailSelectedItems');
    if (samplesList !== "null") {
      let parseData = JSON.parse(samplesList)
      if (parseData !== undefined && parseData !== null) {
        const length = parseData?.length || 0;
        setSampleEmails(length)
      }
    }
    else { setSampleEmails(0) }
  }

  useEffect(() => {
    window.addEventListener('storage', handleStorage())
    return () => window.removeEventListener('storage', handleStorage())
  })

  return (
    <>
      {props.items && (
        <div>
          <Button
            theme={theme}
            variant="contained"
            disableElevation
            onClick={handleClick}
            startIcon={props.icon}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {props.title}
          </Button>
          <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {props.items.map((x, index) => {

              if (x.roleCheck === true) { return null; }

              else return (
                <div key={index}>
                  <MenuItem
                    key={index}
                    disableRipple
                    sx={{
                      [`MuiSvgIcon`]: { color: "blue" },
                      padding: 0,
                    }}
                  >
                    <Link
                      to={x.path}
                      style={{
                        display: "block",
                        width: "100%",
                        height: "100%",
                        textDecoration: "none",
                      }}
                      onMouseDown={(e) => {
                        mouseDownHandler(e, x, index);
                        e?.stopImmediatePropagation && e?.stopImmediatePropagation();
                        e?.preventDefault && e?.preventDefault();
                      }}
                      onClick={(x?.items && x?.items)
                        ? (e) => handleOpenSettings(index)
                        : x?.modal && x?.modal
                          ? () => handleModalRoute(x?.modal)
                          : x?.window && x?.window
                            ? () => HandleWindowRoute(x?.window)
                            : () => handleClose()
                      }
                      target={x.path?.includes(".pdf") ? "_blank" : ""}
                    >
                      <Box sx={{ margin: "6px 16px 6px 16px", }}>
                        {x.icon}
                        {x.title === "Email Selected Items" ? x.title + " " + sampleEmails + " + 0 " : x.title}
                        {x?.items && <KeyboardArrowDownIcon />}
                      </Box>
                    </Link>
                  </MenuItem>

                  {x.items?.map((subItem, index) => {
                    return (
                      <MenuItem
                        key={index}
                        sx={{
                          padding: 0,
                        }}
                      >
                        <Link
                          to={subItem.path}
                          style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                            textDecoration: "none",
                          }}
                          onClick={(e) => {
                            handleClose();
                          }}
                        >
                          <Box sx={{ margin: "6px 16px 6px 30px", }}>
                            {subItem.icon}
                            {subItem.title}
                          </Box>
                        </Link>
                      </MenuItem>
                    );
                  })}
                  {props.items.length - 1 > index ? <Divider sx={{ marginTop: "0!important", marginBottom: "0!important", }} /> : <></>}
                  {/* </Collapse> */}
                </div>
              );
            })}
          </StyledMenu >
        </div >
      )
      }

      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
    </>
  );
}