import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { AccessTime } from "@mui/icons-material";
import { toIsoFormat } from "../utils";

export default function DateTime({
  id,
  name,
  label,
  value,
  selectOptions,
  onChange,
  errorMsg,
  ...options
}) {
  return (
    <>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          id={id}
          views={["year", "month", "day"]}
          name={name}
          inputFormat="DD/MM/YYYY HH:mm"
          value={moment(value)}
          // onChange={(d) => onChange(d?.toISOString(), name)}
          onChange={(d) => onChange(toIsoFormat(new moment(d)), name)}
          {...options}
          renderInput={(params) => {
            return <TextField {...params} error={!!errorMsg} />;
          }}
          componentsProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
          PaperProps={{
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  backgroundColor: "#253FC8",
                },
              },
            },
          }}
          components={{
            OpenPickerIcon: AccessTime,
          }}
          OpenPickerButtonProps={{ size: "small" }}
        />
      </LocalizationProvider>
      {errorMsg && (
        <FormHelperText error={!!errorMsg}>{errorMsg}</FormHelperText>
      )}
    </>
  );
}

export function Date({
  id,
  name,
  label,
  value,
  selectOptions,
  onChange,
  errorMsg,
  handleEnterPress,
  handleKeyUp,
  ...options
}) {
  return (
    <>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          name={name}
          inputFormat="DD/MM/YYYY"
          value={moment(value)}
          onChange={(d) => onChange(d?.format("YYYY-MM-DDTHH:mm:ss"), name)}
          {...options}
          renderInput={(params) => {
            return <TextField {...params} id={id} error={!!errorMsg} onKeyUp={(e) => handleKeyUp(e, handleEnterPress, name, value)}
            />;
          }}
          componentsProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
          PaperProps={{
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  backgroundColor: "#253FC8",
                },
              },
            },
          }}
          OpenPickerButtonProps={{ size: "small" }}
        />
      </LocalizationProvider>
      {errorMsg && (
        <FormHelperText error={!!errorMsg}>{errorMsg}</FormHelperText>
      )}
    </>
  );
}
