import React, { createContext, useContext, useState } from "react";

var RootContext = createContext(null);

export const useRoot = () => useContext(RootContext);
export const useUser = () => {
  const { user } = useContext(RootContext);
  return user;
};
export const useBlockScreen = () => {
  const { setScreenBlocked } = useContext(RootContext);
  return {
    block: () => setScreenBlocked(true),
    unblock: () => setScreenBlocked(false),
  };
};

export const useErrorBox = () => {
  const { setErrorText } = useContext(RootContext);
  return (msg) => setErrorText(msg);
};

export const useToast = () => {
  const { setToastOption } = useContext(RootContext);
  return {
    showToast: (text) =>
      setToastOption({ text, severity: "success", open: true }),
    showInfoToast: (text) =>
      setToastOption({ text, severity: "info", open: true }),
    showErrorToast: (text) =>
      setToastOption({ text, severity: "error", open: true }),
    showWarningToast: (text) =>
      setToastOption({ text, severity: "warning", open: true }),
  };
};

export const RootProvider = ({ children }) => {

  const [screenBlocked, setScreenBlocked] = useState(false);
  const [user, setUser] = useState({});
  const [errorText, setErrorText] = useState("");
  const [toastOptions, setToastOption] = useState({ text: "", open: false });

  let CanDoDataMaintenance = (
    (user?.RoleRegistration === true && user?.RoleTrainee !== true)
    || (user?.RoleEvaluation === true && user?.RoleTrainee !== true)
    || user?.RoleAdministration || user?.RoleDeveloper); //CanManageContacts

  let CanCreateTrays = (user?.RoleLaboratory || user?.RoleDeveloper || user?.RoleRegistration || user?.RoleAdministration);

  let CanRegister = (user?.RoleRegistration || user?.RoleAdministration || user?.RoleDeveloper);

  let CanQualityCheckTrays = (user?.RoleQualityCheck || user?.RoleAdministration || user?.RoleDeveloper);

  let CanLaboratoryTrays = (user?.RoleLaboratory || user?.RoleAdministration || user?.RoleDeveloper);

  let CanDevelop = user?.RoleDeveloper;

  let CanEvaluate = (user?.RoleEvaluation || user?.RoleTrainee === true || user?.RoleAdministration || user?.RoleDeveloper);

  let CanEvaluateNotTrainee = (user?.RoleEvaluation === true && user?.RoleTrainee !== true) || user?.RoleAdministration || user?.RoleDeveloper;//CanApproveSamples

  let CanEvaluateTrainee = (user?.RoleEvaluation === true && user?.RoleTrainee === true);

  let CanViewEvaluations = user?.RoleLaboratory;

  let CanDoSpecialReports = user?.RoleSpecialReports || user?.RoleAdministration || user?.RoleDeveloper;

  let CanManageMachineTemplates = user?.RoleMachineTemplates || user?.RoleAdministration || user?.RoleDeveloper;

  let CanManageTestSets = user?.RoleTestSets || user?.RoleAdministration || user?.RoleDeveloper;

  let CanImportHistory = user?.RoleImportHistory || user?.RoleAdministration || user?.RoleDeveloper;

  let CanAdministrate = user?.RoleAdministration || user?.RoleDeveloper;

  return (
    <RootContext.Provider
      value={{
        screenBlocked,
        setScreenBlocked,
        user,
        setUser,
        setErrorText,
        errorText,
        toastOptions,
        setToastOption,

        CanDoDataMaintenance,
        CanCreateTrays,
        CanRegister,
        CanQualityCheckTrays,
        CanLaboratoryTrays,
        CanDevelop,
        CanEvaluate,
        CanEvaluateNotTrainee,
        CanEvaluateTrainee,
        CanViewEvaluations,
        CanDoSpecialReports,
        CanManageMachineTemplates,
        CanManageTestSets,
        CanImportHistory,
        CanAdministrate
      }}
    >
      {children}
    </RootContext.Provider>
  );
};
