import * as yup from "yup";
import { GetErrorObject } from "../Utils";

export const ImageSchema = yup.object().shape({
  Title: yup
    .string()
    .transform((value) => value || "")
    .min(1, "Title is required.")
    .required("Title is required."),
});

export function ValidateImageSchema(input) {
  return new Promise(async function (resolve, reject) {
    try {
      ImageSchema.validate(input, { abortEarly: false })
        .then(function () {
          const temp = {
            Title: {
              ...input,
            },
          };
          resolve({ temp });
        })
        .catch(function (err) {
          const errors = GetErrorObject(err);
          resolve({ errors });
        });
    } catch (e) {
      reject(e);
    }
  });
}
