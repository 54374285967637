import { Card, Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
  useFormSections,
  RegistrationActions,
  RegistrationFormReducer,
  useFormButtons,
} from "./ER.form";
import useFetch from "../../../../hooks/useFetch";
import { useBlockScreen, useRoot, useToast } from "../../../../rootContext";
import useMasterData from "../../../../hooks/useMasterData";
import Form from "../../../../components/Form";
import FormFooter from "../../../../components/FormFooter";
import AppDialog from "../../../../components/AppDialog";
import { TRAY_APIS } from "../../../../common/apis";
import appResources from "../../../../app-resources/en/Resources.json";
import ConfirmModal from "../../../../components/ConfirmModal";
import moment from "moment";
import { toIsoFormat } from "../../../../utils";

const ScreenName = "Edit Registration";

const todayDate = new moment().subtract(14, 'day');
let minDateAllowed = toIsoFormat(todayDate);  // date object

export default function EditRegistration() {

  const { masterData } = useMasterData("Customers,ComponentLocations,FluidTypes,Measures,ServiceTypes");

  const { CanRegister } = useRoot();

  const { block, unblock } = useBlockScreen();
  const { get, post, getFile } = useFetch();
  const { showToast } = useToast();
  const [dialog, setDialog] = useState({ open: false, message: "", });
  const [data, dispatch] = useReducer(RegistrationFormReducer, { CustomerId: 0, SiteId: 0, UnitId: 0, ComponentId: 0 });
  const [componentData, setComponentData] = useState({});
  const [dataLists, setDataLists] = useState({});
  const [localData, setLocalData] = useState({});

  const [checkFlag, setCheckFlag] = useState({
    UR_UNIT_MAKE: false,
    UR_UNIT_SERIAL: false,
    UR_COMPONENT_LOCATION: false,
    UR_COMPONENT_CAPACITY: false,
    UR_COMPONENT_MAKE: false,
    UR_COMPONENT_SERIAL: false,
  });

  const getBackupData = async (regId) => {
    try {
      if (!regId) return;
      block();
      const resp = await get(`${TRAY_APIS.EDIT_REGISTRATION}?regId=${regId}`, { skipBlocker: true, });
      if (resp) {
        let sitesResp = [];
        let unitsResp = [];
        let compResp = [];

        if (resp.SitesList?.length > 0) {
          sitesResp = resp.SitesList;
        }
        if (resp.UnitsList?.length > 0) {
          unitsResp = resp.UnitsList;
        }
        if (resp.ComponentsList?.length > 0) {
          compResp = resp.ComponentsList;
        }
        setDataLists({
          ...masterData,
          Sites: sitesResp,
          Units: unitsResp,
          Components: compResp,
        })
        resp.Registration &&
          dispatch({
            type: RegistrationActions.LOAD_DATA,
            payload: {
              ...resp.Registration,
              UnitMeterChangedAtDate: minDateAllowed,
              ComponentChangedAtDate: minDateAllowed,
              ComponentRepairedAtDate: minDateAllowed,
              FluidChangedAtDate: minDateAllowed
            },
          });
        setCheckFlag({
          UR_UNIT_MAKE: resp.Registration?.UnitMakeOriginal === resp.Registration?.UnitMakeNew ? true : false,
          UR_UNIT_SERIAL: resp.Registration?.SerialOriginal === resp.Registration?.SerialNew ? true : false,
          UR_COMPONENT_LOCATION: resp.Registration?.ComponentLocationOriginal === resp.Registration?.ComponentLocationNew ? true : false,
          UR_COMPONENT_CAPACITY: resp.Registration?.CapacityOriginal === resp.Registration?.CapacityNew ? true : false,
          UR_COMPONENT_MAKE: resp.Registration?.ComponentMakeOriginal === resp.Registration?.ComponentMakeNew ? true : false,
          UR_COMPONENT_SERIAL: resp.Registration?.ComponentSerialOriginal === resp.Registration?.ComponentSerialNew ? true : false,
        })
        if (resp?.Component !== null) {
          setComponentData(resp?.Component)
        }
        return;
      }
    } finally {
      unblock();
    }
  };

  const onChange = useCallback(async function (name, value) {
    if (name === "CustomerId" && value) {
      const sitesResp = await get(`/masterData/sites?custId=${value}`);
      setDataLists({
        ...dataLists,
        Sites: sitesResp,
        Units: [],
        Components: [],
      })
      dispatch({
        type: RegistrationActions.RESET_FIELDS,
        payload: {
          CustomerId: value,
          SiteId: null,
          UnitId: null,
          ComponentId: null,
        },
      });
    } else if (name === "SiteId" && value) {
      const unitsResp = await get(`/masterData/units?siteId=${value}`);
      setDataLists({
        ...dataLists,
        Units: unitsResp,
        Components: [],
      })
      dispatch({
        type: RegistrationActions.RESET_FIELDS,
        payload: {
          SiteId: value,
          UnitId: null,
          ComponentId: null,
        },
      });
    } else if (name === "UnitId" && value) {
      const compResp = await get(`/masterData/components?unitId=${value}`);
      const {
        UnitMake,
        UnitModel,
        Serial,
      } = (masterData.Units || []).find((x) => x.UnitId === value) || {};
      setDataLists({
        ...dataLists,
        Components: compResp,
      })
      dispatch({
        type: RegistrationActions.RESET_FIELDS,
        payload: {
          UnitId: value,
          ComponentId: null,
          UnitNameOriginal: null,
          UnitMakeOriginal: UnitMake,
          UnitModelOriginal: UnitModel,
          SerialOriginal: Serial,
        },
      });
      setCheckFlag({
        ...checkFlag,
        UR_UNIT_MAKE: (UnitMake === data?.UnitMakeNew && UnitModel === data?.UnitModelNew) ? true : false,
        UR_UNIT_SERIAL: Serial === data?.SerialNew ? true : false,
      })
    } else if (name === "ComponentId" && value) {
      const {
        Component: ComponentOriginal = null,
        ComponentId: ComponentIdOriginal = null,
        ComponentMake,
        ComponentModel,
        Capacity,
        ComponentFluidTypeId,
        Location,
        LocationId,
        Serial,
      } = (masterData.Components || []).find((x) => x.ComponentId === value) ||
        {};

      dispatch({
        type: RegistrationActions.RESET_FIELDS,
        payload: {
          ComponentId: value,
          Capacity,
          ComponentOriginal,
          ComponentIdOriginal,
          ComponentMakeOriginal: ComponentMake,
          ComponentModelOriginal: ComponentModel,
          CapacityOriginal: Capacity,
          ComponentSerialOriginal: Serial,
          FluidTypeId: ComponentFluidTypeId,
          ComponentLocationOriginal: Location,
          ComponentLocationId: LocationId,
        },
      });
      setCheckFlag({
        ...checkFlag,
        UR_COMPONENT_MAKE: (ComponentMake === data?.ComponentMakeNew && ComponentModel === data?.ComponentModelNew) ? true : false,
        UR_COMPONENT_SERIAL: Serial === data?.ComponentSerialNew ? true : false,
        UR_COMPONENT_LOCATION: Location === data?.ComponentLocationNew ? true : false,
        UR_COMPONENT_CAPACITY: Capacity === data?.CapacityNew ? true : false,
      })
    }
  }, [checkFlag, data]);

  const onCustomerChanged = useCallback(function (name, value) {
    onChange(name, value);
  }, []);

  const decoupleHandler = () => {
    setDialog({
      open: true,
      title: "Decouple Registration",
      actionName: decoupleHandlerAlert,
      message: `Do you wish to decouple this registration from sample number '${data.SampleNumber}'? You will remove the linkage between this registration and the sample`,
    });
  }

  async function decoupleHandlerAlert() {
    setDialog((prevState) => ({ ...prevState, open: false }))

    const resp = await get(`/trays/decouple-reg?regId=${data.RegistrationId}&screen=${ScreenName}`);
    if (resp > 0) {
      let regData = JSON.parse(localStorage.getItem("edit-registration"));
      if (regData !== null && regData !== undefined) {
        if (regData?.regNo > 0) {
          getBackupData(regData?.regNo)
        }
      }
    }
  }

  const submitHandler = useCallback(
    async function () {
      setDialog({
        open: true,
        title: "Update Registration",
        actionName: submitHandlerAlert,
        message: "Do you wish to UPDATE the registration?",
      });
    },
    [data]
  );

  async function submitHandlerAlert() {
    const {
      UnitNameNew: Unit,
      SerialNew: UnitSerial,
      UnitMakeNew: UnitMake,
      UnitModelNew: UnitModel,
      ComponentNameNew: Component,
      ComponentSerialNew: ComponentSerial,
      ComponentMakeNew: ComponentMake,
      ComponentModelNew: ComponentModel,
      ComponentLocationNew: ComponentLocation,
      CapacityNew: ComponentCapacity,
      FluidName: Fluid,
      UnitMeterChangedAtDate: UnitMeterChangedDate,
      ComponentChangedAtDate: ComponentChangedDate,
      ComponentRepairedAtDate: ComponentRepairedDate,
      FluidChangedAtDate: FluidChangedDate,
      ...rest
    } = data;

    const DoCreate = false;

    const resp = await post("/trays/update-reg", {
      ...rest,
      DoCreate,
      Unit,
      UnitSerial,
      UnitMake,
      UnitModel,
      Component,
      ComponentSerial,
      ComponentMake,
      ComponentModel,
      ComponentLocation,
      ComponentCapacity,
      Fluid,
      UnitMeterChangedDate,
      ComponentChangedDate,
      ComponentRepairedDate,
      FluidChangedDate,
      Screen: ScreenName,
    });
    if (resp > 0) {
      showToast(appResources.REGISTRATION_UPDATED_MSG);
      setDialog({
        open: false,
      });
    }
    else {
      setDialog({
        open: false,
      });
    }
    // window.close();
  }

  const handleRegistrationClick = async () => {
    const customerDatas = masterData?.Customers?.filter(i => i?.CustomerId === data?.CustomerId);
    const siteDatas = masterData?.Sites?.filter(i => i?.SiteId === data?.SiteId);
    const unitDatas = masterData?.Units?.filter(i => i?.UnitId === data?.UnitId);
    const componentDatas = masterData?.Components?.filter(i => i?.ComponentId === data?.ComponentId);
    const customer = customerDatas?.length > 0 ? customerDatas[0] : null;
    const site = siteDatas?.length > 0 ? siteDatas[0] : null;
    const unit = unitDatas?.length > 0 ? unitDatas[0] : null;
    const component = componentDatas?.length > 0 ? componentDatas[0] : null;
    const payload = {
      ...componentData,
      ...customer,
      ...site,
      ...unit,
      ...component,
      ...data,

      CustomerNotes: data?.Notes,
      Site: site?.Site || data?.Site,
      Unit: unit?.Unit || data?.Unit,
      Component: component?.Component || data?.Component,
      DefaultFluidFormatted: data?.FluidName,
      DefaultTestSetId: componentData?.TestSetId,
      DefaultTestSet: componentData?.TestSet,
      MeterReading: data?.UnitMeter,
      ComponentReading: data?.ComponentMeter,
      FluidHours: data?.FluidMeter,
      Sampled: data?.Added,
    };

    localStorage.setItem(`edit-registration-resp${localData?.ScreenName || ""}`, JSON.stringify(payload));
    localStorage.removeItem("edit-registration");
    window.dispatchEvent(new Event('storage'));
    window.close();
  }

  async function handleDeleteRegistration() {
    setDialog({
      open: true,
      title: "Delete Registration",
      actionName: handleDeleteRegistrationAlert,
      message: "Are you sure you want to Delete?",
    });
  }

  async function handleDeleteRegistrationAlert() {
    const resp = await get(`${TRAY_APIS.DELETE_REG}?RegistrationId=${data.RegistrationId}&Screen=Edit Registration`);
    setDialog((prevState) => ({ ...prevState, open: false, }));
    window.close();
  }

  async function handleRegistrationCard() {
    await getFile(`${TRAY_APIS.REGISTRATION_CARD_REPORT}?RegistrationId=${data.RegistrationId}`);
  }

  const formActions = useMemo(() => ({ onChange }), [onChange]);
  const formSections = useFormSections(
    { onCustomerChanged, data, dispatch, componentData, CanRegister, setDataLists, decoupleHandler, checkFlag, setCheckFlag, dataLists }
  );
  const formButtons = useFormButtons({
    CanRegister,
    submitHandler,
    handleDeleteRegistration,
    handleRegistrationClick,
    handleRegistrationCard,
    sampleNo: data.SampleNumber,
  });

  useEffect(function () {
    let regData = JSON.parse(localStorage.getItem("edit-registration"));
    if (regData?.regNo > 0) {
      setLocalData(regData)

      getBackupData(regData?.regNo)
    }
  }, [masterData]);

  return (
    <>
      <Grid container direction="column">
        <Card sx={{ p: 1 }}>
          <Grid item>
            <Form
              sections={formSections}
              data={data}
              dispatch={dispatch}
              masterData={dataLists}
              key={data.SIF + data._key + data?.FluidName}
              actions={formActions}
            />
          </Grid>
          {data?.SampleNumber > 0
            ? <></>
            : <FormFooter
              buttons={formButtons}
              footerText={`RegistrationId: ${data.RegistrationId || ""}, SiteId: ${data.SiteId || ""
                }, UnitId: ${data.UnitId || ""},  ComponentId: ${data.ComponentId || ""
                } `}
            />}
        </Card>
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={dialog.title}
        maxWidth="md"
      >
        <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false, }));
          }}
          alertAction={dialog?.actionName}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}
