import * as yup from "yup";
import { GetErrorObject } from "../Utils";

export const SearchContactSchema = yup.object().shape({
    Email: yup
        .string()
        .transform((value) => value || "")
        .required("Email is required."),
});

export function ValidateContactSearch(input) {
    return new Promise(async function (resolve, reject) {
        try {
            SearchContactSchema.validate(input, { abortEarly: false })
                .then(function () {
                    const data = {
                        Email: { ...input, },
                    };
                    resolve({ data });
                })
                .catch(function (err) {
                    const errors = GetErrorObject(err);
                    resolve({ errors });
                });
        } catch (e) {
            reject(e);
        }
    });
}
