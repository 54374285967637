
import { Button } from "@mui/material";
import { MaterialReactTable } from "material-react-table";

export default function NewDataTable({
    columns,
    dataSource,
    sx,
    heightValue,
    rowStylingHandler,
    cellStylingHandler,
    ...props
}) {

    return (
        <MaterialReactTable

            muiTableContainerProps={{ sx: sx || { minHeight: heightValue || "300px", maxHeight: heightValue || '500px' } }}
            muiTablePaperProps={{
                sx: {
                    border: "1px solid #253FC8",
                    borderRadius: "5px",
                    padding: "2px",
                    [`.MuiTableRow-root:hover td`]: { backgroundColor: "#8238ba !important", color: "black" },
                    [`.MuiTableRow-root.Mui-selected td`]: {
                        backgroundColor: "#7030a0!important", color: "white"
                    }
                }
            }}

            muiTableHeadCellProps={{
                align: 'left',
                sx: {
                    border: "0.25px solid #E0E0E0 !important",
                    py: "2px",
                }
            }}
            muiTableBodyRowProps={({ row }) => ({
                sx: {
                    cursor: 'pointer',
                },
            })}
            muiTableBodyCellProps={{
                sx: {
                    border: "0.25px solid #d0d0d0 !important",
                    py: "2px!important",
                }
            }}
            initialState={{
                density: "compact",
                // columnPinning: { left: ["Cleanup"] },
            }}
            defaultColumn={{
                maxSize: 500,
                minSize: 10,
                size: 150, //default size is usually 180
            }}
            // enableRowSelection={true}
            getRowId={(row) => row?.userId}
            onRowSelectionChange={(row) => { console.log("changes", row.original) }} //connect internal row selection state to your own
            hoveredRow={""}
            enableSorting={props?.enableSorting}
            enableColumnActions={props?.enableColumnActions}
            enableFilterMatchHighlighting={true}
            columns={columns}
            data={dataSource}
            enableHiding={true}
            enableColumnFilters={false}
            showColumnFilters={false}
            columnResizeMode="onChange" //default
            enablePagination={false} //footer pagination
            enableGlobalFilter={false} //disable search feature
            enableFullScreenToggle={false} //full screen icon
            enableDensityToggle={false} //resize table
            editingMode="cell" //to edit cell
            enableTopToolbar={false} //hide top toolbar
            enableBottomToolbar={false}
            enableRowVirtualization
            rowVirtualizerProps={{
                overscan: 10, //adjust the number or rows that are rendered above and below the visible area of the table
                estimateSize: () => 100, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
            }}
            {...props}
        />
    );
}

export function NewDataGridAction({
    label = "",
    startIcon,
    onClick,
    row,
    className,
    color,
    disabled,
    minWidth,
    height,
    sx, }) {
    return (
        <Button
            variant={className || "outlined"}
            color={color}
            onClick={(e) => onClick && onClick(row, e)}
            startIcon={startIcon}
            sx={{
                height: height || "18px",
                padding: 0.125,
                minWidth: minWidth,
                backgroundColor: "white",
                ...sx || {},
            }}
            disabled={disabled || false}
        >
            {label}
        </Button >
    );
}