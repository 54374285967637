import * as yup from "yup";
import { GetErrorObject } from "../Utils";

export const MakeModelSchema = yup.object().shape({
  ComponentMake: yup
    .string()
    .transform((value) => value || "")
    .required("Component Make is required."),
  ComponentModel: yup
    .string()
    .transform((value) => value || "")
    .required("Component Model is required."),
});

export function ValidateMakeModel(input) {
  return new Promise(async function (resolve, reject) {
    try {
      MakeModelSchema.validate(input, { abortEarly: false })
        .then(function () {
          const data = {
            ComponentMake: {
              ...input,
            },
            ComponentModel: {
              ...input,
            },
          };
          resolve({ data });
        })
        .catch(function (err) {
          const errors = GetErrorObject(err);
          resolve({ errors });
        });
    } catch (e) {
      reject(e);
    }
  });
}
