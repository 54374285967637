import { FormActions } from "../../../../components/Form";
export default function ContactAccessPermissionForm() {
  return <></>;
}

export function FormSections() {
  return [
    {
      xsCol: 12,
      fields: [
        { name: "Product", label: "Products", type: "switch" },
        { name: "Parents", label: "Parents", type: "switch" },
        { name: "Customer", label: "Customers", type: "switch" },

        { name: "Sites", label: "Sites", type: "switch" },
        { name: "Units", label: "Units", type: "switch" },
        { name: "Components", label: "Components", type: "switch" },
        { name: "SiteSets", label: "Site Sets", type: "switch" },
        {
          name: "Filter",
          label: "Filter",
          xsCol: 2,
        },
      ],
    },
  ];
}
const EventActions = {
  LOAD_DATA: "load-data",
};
export function EventReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return action.payload;
  }
  return state;
}
export const ContactAccessPermissionDefaults = {
  Show: true,
  Product: true,
  Parents: true,
  SiteSets: true,
  Sites: true,
  Units: true,
  Components: true,
  Customer: true,
};
