import React, { useState } from "react";
import { Button, Grid, InputLabel, Typography } from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import { useToast, useRoot } from "../../../rootContext";
import TextBox from "../../../components/TextBox";
import { TRAY_APIS } from "../../../common/apis";
import { SaveIcon } from "../../../icons/ButtonIcons";
import appResources from "../../../app-resources/en/Resources.json";

export default function NewVersion({
  sampleId,
  setNewVersionOpen,
  newVersionAddedToast,
  cancel,
}) {
  const { CanDoDataMaintenance } = useRoot();
  const { post } = useFetch();
  const { showErrorToast } = useToast();
  const [colorCondition, setColorCondition] = useState(false);
  const unChangedValue = `New version of sample ${sampleId} created because`;

  const [reasonValue, setReasonValue] = useState(`New version of sample ${sampleId} created because`);
  const [reasonMessage, setReasonMessage] = useState(`To create a new version from sample ${sampleId}. please provide a Reason`);

  async function saveNewVersion() {
    if (reasonValue.trim().length > unChangedValue.trim().length) {
      const response = await post(TRAY_APIS.NEW_VERSION, {
        SampleNumber: sampleId,
        Reason: reasonValue,
        Screen: "New Version Form",
      });
      if (response[0].NewVersionCreated) {
        setNewVersionOpen && setNewVersionOpen(false);
        newVersionAddedToast && newVersionAddedToast();
        cancel && cancel();
      } else {
        showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
      }
    } else {
      setReasonMessage(`Please provide a reason why a new version of sample ${sampleId} is being created`);
      setColorCondition(true);
    }
  }
  return (
    <>
      <Grid container flex spacing={3} padding={0.5} sx={{
        p: 1.5,
        minWidth: "33vw",
        overflow: "auto",
      }}>
        <Grid item marginTop={"4.5px"}>
          <Typography
            style={{
              color: colorCondition ? "red" : "black",
            }}
          >
            {reasonMessage}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InputLabel style={{ marginTop: "15px" }}>Reason</InputLabel>
        <TextBox
          multiline
          rows={5}
          value={reasonValue}
          onChange={(v) => setReasonValue(v.target.value)}
        />
      </Grid>
      {CanDoDataMaintenance && <Grid container sx={{ mt: 1.5 }} justifyContent={"end"}>
        <Grid item>
          <Button
            item
            variant="contained"
            onClick={saveNewVersion}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </Grid>
      </Grid>}
    </>
  );
}
