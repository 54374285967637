import { useState } from 'react'
import OptionMenu from './optionMenu'
import useFetch from '../hooks/useFetch';
import { WEBSITE_APIS } from '../common/apis';
import { Button, colors, createTheme, Menu, MenuItem } from '@mui/material';

import InboxIcon from "@mui/icons-material/MoveToInbox";
import Launch from "@mui/icons-material/Launch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';


function TopNavbar({ menuList }) {
    const { get } = useFetch();

    const [websiteLinks, setWebsiteLinks] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleWebsitesClick = async (event) => {
        if (websiteLinks?.length > 0) {
            setAnchorEl(event.currentTarget || event.target);
        }
        else {
            const result = await get(WEBSITE_APIS.GET_WEBSITE_LINKS, { skipBlocker: true });
            if (result) {
                setWebsiteLinks(result);
            };
            setAnchorEl(event.currentTarget || event.target);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const StyledMenu = styled((props) => (
        <Menu
            sx={{
                margin: 0,
                [`.MuiSvgIcon`]: { color: "blue" }
            }}
            elevation={0}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            {...props}
        />
    ))(({ theme }) => ({
        "& .MuiPaper-root": {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 150,
            color: "#425FF6",
            boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
                padding: "4px 0",
            },
            "& .MuiMenuItem-root": {
                "& .MuiSvgIcon-root": {
                    color: "#425FF6",
                    marginRight: theme.spacing(1.5),
                },
                " a:visited": {
                    color: "#425FF6"
                },
            },
        },
    }));

    const parentTheme = useTheme();
    const theme = createTheme(parentTheme, {
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.common.white,
                        color: parentTheme.palette.primary.main,
                        "&:hover": {
                            backgroundColor: parentTheme.palette.primary.main,
                            color: colors.common.white,
                        },
                        fontSize: "11px", lineHeight: 0, height: "26px",
                        [`& .MuiButton-startIcon>*:nth-of-type(1) `]: { fontSize: "12px" },
                        [`& .MuiButton-endIcon `]: { marginLeft: "0px" }
                    },
                },
            },
        },
    });

    return (
        <>
            {menuList.map((page, i) => (
                <OptionMenu {...page} key={page.name} />
            ))}
            <div>
                <Button
                    theme={theme}
                    variant="contained"
                    disableElevation
                    onClick={handleWebsitesClick}
                    startIcon={<InboxIcon />}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Websites
                </Button>
                <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {websiteLinks.map((x) => {
                        return (
                            <MenuItem
                                key={x.LinkId}
                                disableRipple
                            >
                                {<Launch />}
                                <a
                                    href={x.Link}
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                    style={{
                                        display: "block",
                                        width: "100%",
                                        height: "100%",
                                        textDecoration: "none",
                                    }}
                                    onClick={(e) => {
                                        handleClose();
                                    }}
                                >
                                    {x.LinkText}
                                </a>
                            </MenuItem>
                        );
                    })}
                </StyledMenu>
            </div>
        </>
    )
}

export default TopNavbar;