import { useEffect, useState } from "react";
import renderCellExpand from "../../../../components/DatatableCellExpand";
import { useWindowPopups } from "../../../../components/useWindowPopups";
import NewDataTable, { NewDataGridAction } from "../../../../components/NewDataTable";

export default function AccessPermissionOverviewTable({
  data,
  rowSelected,
  selectClick,
  selectCheck,
  ...props
}) {
  const [sCheck, setSCheck] = useState(false);

  useEffect(() => {
    const val = selectCheck === true ? true : false;
    setSCheck(val)
  }, [selectCheck]);

  const options = {
    ...props,
    columns: GetColumns(
      selectClick,
      editClick,
      accessClick,
      reportsClick,
      subscriptionsClick,
      primaryClick,
    ),
    dataSource: (data || []).sort((a, b) => (a.Primary === b.Primary) ? 0 : a.Primary ? -1 : 1)
      ?.map((x, i) => ({
        ...x, keyProp: i,
      })),
    sx: { height: "70dvh" },
    getRowId: (originalRow) => (originalRow?.keyProp || ""),

    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Primary" ? {
          backgroundColor: (row?.original?.Primary !== null ? "green !important" : ""),
          color: (row?.original?.Primary !== null ? "white !important" : ""),
          py: 0,
          borderLeft: "1px solid #d0d0d0",
          height: "22px",
        } : cell.column.columnDef.accessorKey === "Source" ? {
          backgroundColor: (
            row?.original?.IsKomatsu
              ? "#140a9a !important"
              : row?.original?.IsPortalUser
                ? "#ff4500 !important"
                : row?.original?.IsLabcheckUser
                  ? "#228b22 !important"
                  : ""
          ),
          color: "white",
          border: "0.5px solid #d0d0d0",
          py: 0,
          height: "22px",
        } : {
          py: 0, height: "22px", borderLeft: "1px solid #d0d0d0"
        }
      }
    },
    muiTableBodyRowProps: ({ cell, column, row, table }) => {
      return {
        sx: { height: "22px", py: 0, borderLeft: "1px solid #d0d0d0" }
      }
    },
    state: { columnVisibility: { Select: sCheck } },
  };

  return (
    <>
      <NewDataTable {...options} />
    </>
  );

  function HandleNewWindow(actionName, data) {
    useWindowPopups(actionName, data)
  }

  function editClick(row) {
    HandleNewWindow("edit-contact", row)
  }

  function accessClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 2,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }

  function reportsClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 3,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }

  function subscriptionsClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 4,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }

  function primaryClick(row) {
    HandleNewWindow("contact-primary-mapping", {
      ContactId: row?.ContactId,
      PropId: 6,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
    });
  }
}

const GetColumns = (
  selectClick,
  editClick,
  accessClick,
  reportsClick,
  subscriptionsClick,
  primaryClick,
) => [
    {
      header: "Select",
      size: 60,
      enableHiding: true,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => selectClick(row?.original)}
            label={"Select"}
          />
        );
      },
    },
    {
      header: "Edit",
      size: 60,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => editClick(row?.original)}
            label={"Edit"}
          />
        );
      },
    },
    {
      header: "Access",
      size: 60,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => accessClick(row?.original)}
            label={"Access"}
          />
        );
      },
    },
    {
      header: "Reports",
      size: 60,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => reportsClick(row?.original)}
            label={"Reports"}
          />
        );
      },
    },
    {
      header: "Subscriptions",
      size: 80,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => subscriptionsClick(row?.original)}
            label={"Subscriptions"}
          />
        );
      },
    },
    {
      header: "Primary-",
      size: 60,
      Cell: function ({ row, value }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => primaryClick(row?.original)}
            label={"Primary"}
          />
        );
      },
    },
    {
      header: "Email",
      accessorKey: "Username",
      size: 200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Name",
      accessorKey: "DisplayName",
      size: 150,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Primary",
      accessorKey: "Primary",
      size: 60,
      valueFormatter: ({ renderedCellValue }) => renderedCellValue !== null ? "Yes" : ""
    },
    {
      header: "Mobile",
      accessorKey: "Mobile",
      size: 110,
      sortable: false,
    },
    {
      header: "Telephone",
      accessorKey: "Telephone",
      size: 110,
      sortable: false,
    },
    {
      header: "Scope",
      accessorKey: "Scope",
      size: 80,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Customer",
      accessorKey: "Customer",
      size: 100,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Site",
      accessorKey: "Site",
      size: 100,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Unit",
      accessorKey: "Unit",
      size: 100,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Component",
      accessorKey: "Component",
      size: 100,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Source",
      accessorKey: "Source",
      size: 70,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Role",
      accessorKey: "ContactRole",
      size: 80,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Archived",
      accessorKey: "Archived",
      size: 65,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Company",
      accessorKey: "Company",
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
  ];
