const Constants = {
  Unknown: "?",
  completedStep: 8,
  evalStep: 5,
  approval: 6,
  regStep: 1,
  sampleNumberColWidth: 160,
};
export default Constants;

export const LabSteps = {
  Registration: 1,
  Laboratory: 2,
  Quality: 3,
  AutoEvaluate: 4,
  Evaluate: 5,
  Approval: 6,
  Exporting: 7,
  Completed: 8,
};

export const ResultColWidths = {
  "kV@100": 40,
  "kV@40": 40,
  "04µm": 46,
  "06µm": 45,
  "14µm": 40,
  "21µm": 40,
  Topup: 80,
  SampleNumber: 123,
};

export const ScreenNames = {
  EditSite: "Edit Site from Quick Details",
};
