import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useReducer, } from "react";
import Form, { FormActions } from "../../../../components/Form";
import useFetch from "../../../../hooks/useFetch";
import { CONTACTS_APIS, } from "../../../../common/apis";
import FormFooter from "../../../../components/FormFooter";
import { SaveIcon } from "../../../../icons/ButtonIcons";
import { useToast } from "../../../../rootContext";
import appResources from "../../../../app-resources/en/Resources.json";

export default function ContactSampleReportingMapping({
  selectedRowData,
  contactId,
  setDialog,
  getData,
}) {

  const [data, dispatch] = useReducer(SearchFilterReducer, defaultReportMapping);
  const { get, post } = useFetch();
  const { showToast, showErrorToast } = useToast();

  //for new case
  useEffect(() => {
    if (!selectedRowData?.ContactLinkageId) {
      dispatch({
        type: EventActions.LOAD_DATA,
        payload: {
          ...selectedRowData,
          isEmailFrom: true,
          EmailFrom: 1,
          isSampleReportFrom: true,
          SampleReportFrom: 1,
          MachineReportFrom: 1,
          isMachineReportFrom: false,
          isExcelFrom: false,
          ExcelFrom: 1,
          isSmsFrom: false,
          SmsFrom: 4,
          isXmlFrom: false,
          XmlFrom: 1,
          isMailFrom: false,
          MailFrom: 1,
        },
      });
    }
    if (selectedRowData?.ContactLinkageId) {
      (async function () {
        let response = await get(`${CONTACTS_APIS.CONTACT_LINKAGE}?ContactLinkageId=${selectedRowData.ContactLinkageId}`);
        if (response) {
          dispatch({
            type: EventActions.LOAD_DATA,
            payload: {
              ...response,
              isEmailFrom: response.EmailFrom === null ? false : true,
              EmailFrom: response.EmailFrom === null ? 1 : response.EmailFrom,
              isSampleReportFrom: response.SampleReportFrom === null ? false : true,
              SampleReportFrom: response.SampleReportFrom === null ? 1 : response.SampleReportFrom,
              MachineReportFrom: response.MachineReportFrom === null ? 1 : response.MachineReportFrom,
              isMachineReportFrom: response.MachineReportFrom === null ? false : true,
              isExcelFrom: response.ExcelFrom === null ? false : true,
              ExcelFrom: response.ExcelFrom === null ? 1 : response.ExcelFrom,

              isSmsFrom: response.SmsFrom === null ? false : true,
              SmsFrom: response.SmsFrom === null ? 4 : response.SmsFrom,
              isXmlFrom: response.XmlFrom === null ? false : true,
              XmlFrom: response.XmlFrom === null ? 1 : response.XmlFrom,
              isMailFrom: response.MailFrom === null ? false : true,
              MailFrom: response.MailFrom === null ? 1 : response.MailFrom,
            },
          });
        }
      })();
    }
  }, [selectedRowData]);

  const formActions = {
    saveContactLinkage: async function (e) {
      const payload = {
        ContactId: contactId,
        EmailFrom: data.isEmailFrom ? data.EmailFrom : null,
        SampleReportFrom: data.isSampleReportFrom
          ? data.SampleReportFrom
          : null,
        ExcelFrom: data.isExcelFrom ? data.ExcelFrom : null,
        MachineReportFrom: data.isMachineReportFrom
          ? data.MachineReportFrom
          : null,
        SmsFrom: data.isSmsFrom ? data.SmsFrom : null,
        XmlFrom: data.isXmlFrom ? data.XmlFrom : null,
        MailFrom: data.isMailFrom ? data.MailFrom : null,

        ProductId: data?.ProductId,
        SiteSetId: data?.SiteSetId,
        ParentId: data?.ParentId,
        CustomerId: data?.CustomerId,
        SiteId: data?.SiteId,
        UnitId: data?.UnitId,
        ComponentId: data?.ComponentId,
        UserId: data?.UserId,
        Screen: "Contact Sample Report Mapping",
      };

      let response = await post(CONTACTS_APIS.SAVE_CONTACT_LINKAGE, payload);
      if (response > 0) {
        showToast(appResources.SAVED_SUCCESSFULLY_MSG);
        setDialog((prevState) => ({ ...prevState, open: false }));
        getData();
      } else {
        showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
      }
    },
  };
  return (
    <>
      <Grid container sx={{ maxWidth: "40vw" }}>
        <Grid item xs={12}>
          <Typography sx={{ marginBottom: "16px" }}>
            Select the reporting options and alert level for this mapping
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(data)}
            data={data}
            dispatch={dispatch}
            key={data?.ContactLinkageId || data?.CustomerId}
          />

          <FormFooter
            footerText={`ContactId: ${contactId || ""}, CustomerId: ${data?.CustomerId || ""}`}
            buttons={GetFormButtons(formActions)}
          />
        </Grid>
      </Grid>
    </>
  );
}

function GetFormSections(data) {
  return [
    {
      fields: [
        {
          xsCol: 12,
          group: [
            {
              xsCol: 6,
              group: [
                {
                  name: "isEmailFrom",
                  label: "Emails",
                  type: "switch",
                  xsCol: 4,
                },

                {
                  name: "EmailFrom",
                  label: "from Alert Level",
                  type: "number",
                  disabled: !data.isEmailFrom,
                  min: 1,
                  max: 5,
                  xsCol: 6,
                },

                {
                  xsCol: 1,
                  component: function () {
                    return <Typography sx={{ mt: 2 }}>with</Typography>;
                  },
                },
              ]
            },
            {
              xsCol: 6,
              group: [
                {
                  xsCol: 12,
                  group: [
                    {
                      name: "isSampleReportFrom",
                      label: "Sample Reports",
                      type: "switch",
                      xsCol: 5,
                    },
                    {
                      name: "SampleReportFrom",
                      label: "from Alert Level",
                      type: "number",
                      disabled: !data.isSampleReportFrom,
                      min: 1,
                      max: 5,
                      xsCol: 6,
                    },]
                },
                {
                  xsCol: 12,
                  group: [
                    {
                      name: "isExcelFrom",
                      label: "Excel of Samples",
                      type: "switch",

                      xsCol: 5,
                    },
                    {
                      name: "ExcelFrom",
                      label: "from Alert Level",
                      type: "number",
                      disabled: !data.isExcelFrom,
                      min: 1,
                      max: 5,
                      xsCol: 6,
                    },
                  ]
                },
                {
                  xsCol: 12,
                  group: [{
                    name: "isMachineReportFrom",
                    label: "Machine Reports",
                    type: "switch",
                    xsCol: 5,
                  },
                  {
                    name: "MachineReportFrom",
                    label: "from Alert Level",
                    type: "number",
                    disabled: !data.isMachineReportFrom,
                    min: 1,
                    max: 5,
                    xsCol: 6,
                  },
                  ],
                },
              ]
            },
            {
              xsCol: 6,
              group: [
                {
                  xsCol: 12,
                  group: [
                    {
                      name: "isSmsFrom",
                      label: "SMS",
                      type: "switch",
                      xsCol: 4,
                    },
                    {
                      name: "SmsFrom",
                      label: "from Alert Level",
                      type: "number",
                      disabled: !data.isSmsFrom,
                      min: 1,
                      max: 4,
                      xsCol: 6,
                    },
                  ]
                },
                {
                  xsCol: 12,
                  group: [{
                    name: "isXmlFrom",
                    label: "XML",
                    type: "switch",
                    xsCol: 4,
                  },
                  {
                    name: "XmlFrom",
                    label: "from Alert Level",
                    type: "number",
                    disabled: !data.isXmlFrom,
                    min: 1,
                    max: 5,
                    xsCol: 6,
                  },]
                },
                {
                  xsCol: 12,
                  group: [
                    {
                      name: "isMailFrom",
                      label: "Postal Mail",
                      type: "switch",
                      xsCol: 4,
                    },
                    {
                      name: "MailFrom",
                      label: "from Alert Level",
                      type: "number",
                      disabled: !data.isMailFrom,
                      min: 1,
                      max: 5,
                      xsCol: 6,
                    },
                  ]
                },
              ],
            },
          ],
        },
      ],
    }]
}

const defaultReportMapping = {
  isEmailFrom: true,
  isSampleReportFrom: true,
  EmailFrom: 1,
  SampleReportFrom: 1,
  ExcelFrom: 1,
  MachineReportFrom: 1,
  SmsFrom: 4,
  XmlFrom: 1,
  MailFrom: 1,
};

const EventActions = {
  LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
const GetFormButtons = ({ saveContactLinkage }) => [
  {
    label: "Save",
    onClick: saveContactLinkage,
    startIcon: <SaveIcon />,
  },
];
