import { CheckCircle, } from "@mui/icons-material";
import { Grid, Button, Typography, Card } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import useFetch from "./../../../../hooks/useFetch";
import Form, { FormActions } from "./../../../../components/Form";
import { CONTACTS_APIS } from "./../../../../common/apis";
import AppDialog from "../../../../components/AppDialog";
import appResources from "../../../../app-resources/en/Resources.json";
import { useRoot } from "../../../../rootContext";
import ConfirmModal from "../../../../components/ConfirmModal";
import SearchCustomer from "../../../quick-details/manage/search/SearchCustomer";
import SearchSite from "../../../quick-details/manage/search/SearchSite";
import NewDataTable, { NewDataGridAction } from "../../../../components/NewDataTable";

export default function ContactPrimary({ contactId }) {

  const { CanDoDataMaintenance } = useRoot();
  const { get, post } = useFetch();
  const [data, dispatch] = useReducer(SearchMappingFilterReducer, {});
  const [contactsPrimaryData, setContactsPrimaryData] = useState([]);
  const [openSelectCustomer, setOpenSelectCustomer] = useState(false);
  const [propValue, setPropValue] = useState();
  const [rowData, setRowData] = useState();
  const [dialog, setDialog] = useState({ open: false, message: "", });
  const [localData, setLocalData] = useState({});

  const filteredData = useMemo(
    () =>
      data
        ? (contactsPrimaryData || []).filter((x) => {
          let filterMatch = true;
          if (data.Filter) {
            const filterLower = data.Filter.toLowerCase();
            filterMatch =
              x.Customer?.toLowerCase().includes(filterLower) ||
              x.Site?.toLowerCase().includes(filterLower);
          }
          return filterMatch;
        })
        : contactsPrimaryData,
    [data, contactsPrimaryData]
  );

  const options = {
    columns: GetColumns(deleteClick, CanDoDataMaintenance),
    dataSource: filteredData || [],

    getRowId: (originalRow) => originalRow.SiteId,
    enableColumnActions: false,
    sx: { height: "60dvh", },

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx:
          cell.column.columnDef.accessorKey === "Product" && row.original.Product ? {
            backgroundColor: (row?.original?.ProductColour + "!important") || "",
            color: "white",
            py: 0.25,
            border: "0.5px solid #d0d0d0",
          } : {
            py: 0.25,
            border: "0.5px solid #d0d0d0",
          }
      }
    },

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },

    cellStylingHandler: "contactsPrimaryMappings",
  };

  async function getData(a) {
    const contactExportResponse = await get(`${CONTACTS_APIS.CONTACT_PRIMARIES_ASSIGNED}?contactId=${a || contactId || localData?.ContactId || 0}`);
    setContactsPrimaryData(contactExportResponse);
  }

  function addSite() {
    setOpenSelectCustomer(true);
    setPropValue(4);
  }

  function addCustomer() {
    setOpenSelectCustomer(true);
    setPropValue(3);
  }

  function deleteClick(row) {
    setDialog({
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
    setRowData(row);
  }

  async function deleteClickAlert() {
    await get(`${CONTACTS_APIS.DELETE_CONTACT_PRIMARY}?ContactId=${rowData.ContactId}&SiteId=${rowData.SiteId}&Screen=Contact Primary Mappings`);
    await getData();
    setDialog((prevState) => ({ ...prevState, open: false }));
  }

  useEffect(() => {
    if (contactId > 0) {
      getData();
    }
    else {
      let lData = JSON.parse(localStorage.getItem("contact-primary-mapping"));
      if (lData?.ContactId > 0) {
        setLocalData(lData)
        getData(lData?.ContactId, lData?.PropId);
        document.title = "Contact Primary Mappings - " + (lData?.UserName || "");
      }
    }
  }, [contactId]);

  return (
    <>
      <Grid container sx={{ minWidth: "95vw" }}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(addSite, addCustomer, CanDoDataMaintenance)}
              data={contactsPrimaryData}
              dispatch={dispatch}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ marginLeft: "5px" }}>
            {contactsPrimaryData?.length > 0 && (
              <b>Total Records: {filteredData && filteredData.length}</b>
            )}{" "}
            {contactsPrimaryData?.length === 0 && (
              <p style={{ color: "red" }}>
                The contact display test is not primary contact for any sites
              </p>
            )}
          </Typography>

          <NewDataTable {...options} />
        </Grid>
      </Grid>

      <AppDialog
        open={openSelectCustomer}
        title={propValue === 3 ? "Customer Search" : propValue === 4 ? "Site Search" : ""}
        maxWidth="lg"
        handleClose={() => setOpenSelectCustomer(false)}
      >
        {propValue === 3
          ? <SearchCustomer
            actions={[
              {
                action: async function (rowData) {
                  await post(CONTACTS_APIS.ADD_CONTACT_PRIMARY, {
                    ContactId: contactId || localData?.ContactId,
                    CustomerId: rowData.CustomerId,
                    Screen: "Contact Primary Mappings",
                  });
                  setOpenSelectCustomer(false);
                  await getData();
                },
                label: "Select",
                startIcon: <CheckCircle />,
              },
            ]}
            contactId={contactId || localData?.ContactId}
            propId={propValue}
          /> :
          propValue === 4
            ? <SearchSite
              actions={[
                {
                  action: async function (rowData) {
                    await post(CONTACTS_APIS.ADD_CONTACT_PRIMARY, {
                      ContactId: contactId || localData?.ContactId,
                      SiteId: rowData.SiteId,
                      Screen: "Contact Primary Mappings",
                    });
                    setOpenSelectCustomer(false);
                    await getData();
                  },
                  label: "Select",
                  startIcon: <CheckCircle />,
                },
              ]}
              contactId={contactId || localData?.ContactId}
              propId={propValue}
            />
            : <></>
        }
      </AppDialog>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Contact"
        maxWidth="md"
      >
        <ConfirmModal
          handleClose={() => { setDialog((prevState) => ({ ...prevState, open: false })); }}
          alertAction={deleteClickAlert}
          message={dialog.message}
          buttonText={"Delete"}
          buttonText2={"Cancel"} />
      </AppDialog>
    </>
  );
}

function GetColumns(deleteClick, CanDoDataMaintenance) {
  return [
    {
      header: "Delete",
      size: 60,
      Cell: function ({ row }) {
        return (
          <> {CanDoDataMaintenance && <NewDataGridAction
            row={row?.original}
            onClick={() => deleteClick(row?.original)}
            label={"Delete"}
          />}
          </>
        );
      },
    },
    {
      header: "Product",
      accessorKey: "Product",
      size: 170,
    },
    {
      header: "Customer",
      accessorKey: "Customer",
      size: 500,
    },
    {
      header: "Site",
      accessorKey: "Site",
      size: 500,
    },
  ];
}

function GetFormSections(addSite, addCustomer, CanDoDataMaintenance) {
  return [
    {
      xsCol: 12,
      fields: [
        { name: "Filter", label: "Filter" },
        CanDoDataMaintenance && {
          variant: "outlined",
          label: "Add Site",
          component: function () {
            return (
              <Button
                sx={{ marginTop: "15px" }}
                variant="outlined"
                onClick={addSite}
              >
                Add Site
              </Button>
            );
          },
        },
        CanDoDataMaintenance && {
          variant: "outlined",
          label: "Add Customer",
          component: function () {
            return (
              <Button
                sx={{ marginTop: "15px" }}
                variant="outlined"
                onClick={addCustomer}
              >
                Add Customer
              </Button>
            );
          },
        },
      ],
    },
  ];
}

function SearchMappingFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
