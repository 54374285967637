import { Check } from "@mui/icons-material";
import { useEffect, useReducer, useState } from "react";
import Form, { SectionTitle } from "../components/Form";
import FormFooter from "../components/FormFooter";
import CustomerReducer, { CustomerActions } from "./reducers/CustomerReducer";
import useFetch from "./../hooks/useFetch";
import { Button, Card, Typography } from "@mui/material";
import { useRef } from "react";
import useMasterData from "../hooks/useMasterData";
import { ValidateCustomer } from "../validation/schemas/CustomerSchema.";
import { DETAILS_APIS } from "../common/apis";
import { NewIcon, ReportLogoIcon } from "../icons/ButtonIcons";
import { useRoot, } from "../rootContext";

import { getStartLetter } from "../utils";
import AppDialog from "../components/AppDialog";
import ManageCustomer from "../features/quick-details/manage/manage-customer/ManageCustomer";

function EditCustomer() {

  const defaultData = {
    ProductId: 2,
    ExportOptionId: 1,
    BlockCustomerEvaluationsFrom: "2100-01-01T00:00:00",
  };

  const [data, dispatch] = useReducer(CustomerReducer, defaultData);
  const { get, post } = useFetch();
  const { getMasterData } = useMasterData();
  const { CanDoDataMaintenance, CanAdministrate } = useRoot();

  const [dropdownData, setDropdownData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [editCheck, setEditCheck] = useState(false);
  const [openManagement, setOpenManagement] = useState(false);
  const [localData, setLocalData] = useState({});
  const [resp10, setResp10] = useState(false)

  const handleUpdateCustomer = async () => {
    const postPayload = {
      // ...data,
      CustomerId: data?.CustomerId,
      ProductId: data?.ProductId,
      Customer: data?.Customer,
      KnownAs: data?.AlternativeNames,
      OwnerUserId: data?.CmsOwnerId,
      SetOwnerToAllSites: data?.SetOwnerToAllSites,

      SapNumber: data?.AccountNumber,
      SapCustomer: data?.SapCustomer,

      ParentId: data?.ParentId,
      Notes: data?.CustomerNotes,
      ExportOptionId: data?.ExportOptionId,
      SetExportToAllSites: data?.SetExportToAllSites,

      PostalAddressId: data?.PostalAddressId,
      PostalStreet1: data?.PostalStreet1,
      PostalStreet2: data?.PostalStreet2,
      PostalCity: data?.PostalCity,
      PostalState: data?.PostalState,
      PostalCountry: data?.PostalCountry,
      PostalPostCode: data?.PostalPostCode,

      PhysicalAddressId: data?.PhysicalAddressId,
      PhysicalStreet1: data?.PhysicalStreet1,
      PhysicalStreet2: data?.PhysicalStreet2,
      PhysicalCity: data?.PhysicalCity,
      PhysicalState: data?.PhysicalState,
      PhysicalCountry: data?.PhysicalCountry,
      PhysicalPostCode: data?.PhysicalPostCode,

      IsArchived: !!data.CustomerArchived,
      IsShowLimitsOnReport: !!data.IsShowLimitsOnReport,
      Screen: "Edit Customer",
    };
    let result = await post(DETAILS_APIS.UPDATE_CUSTOMER, postPayload);
    if (result > 0 || result?.length > 0) {

      const treeData = { ProductId: data?.ProductId, StartLetter: data?.StartLetter, CustomerId: data?.CustomerId, refreshCheck: true }
      localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
      window.dispatchEvent(new Event('storage'));

      if (window.location.pathname === '/add-new-customer' || window.location.pathname === '/edit-customer') {
        localStorage.removeItem("edit-customer");
        window.dispatchEvent(new Event('storage'));
        window.close()
      }
      return;
    }

    if (result !== null && (result < 1 || result?.length < 1)) {
      return;
    }
    return;
  }

  const handleNewCustomer = async () => {

    let slIndex = 0;
    let name = data?.Customer?.trim();
    slIndex = Number(getStartLetter(name[0]));

    let result = await post(DETAILS_APIS.ADD_CUSTOMER, {
      ProductId: data?.ProductId || localData?.ProductId,
      Customer: data?.Customer,
      KnownAs: data?.AlternativeNames,
      OwnerUserId: data?.OwnerUserId,
      SetOwnerToAllSites: data?.SetOwnerToAllSites,

      SapNumber: data?.AccountNumber,
      SapCustomer: data?.SapCustomer,

      ParentId: data?.ParentId,
      Notes: data?.CustomerNotes,
      ExportOptionId: data?.ExportOptionId,
      SetExportToAllSites: data?.SetExportToAllSites,

      PostalAddressId: data?.PostalAddressId,
      PostalStreet1: data?.PostalStreet1,
      PostalStreet2: data?.PostalStreet2,
      PostalCity: data?.PostalCity,
      PostalState: data?.PostalState,
      PostalCountry: data?.PostalCountry,
      PostalPostCode: data?.PostalPostCode,

      PhysicalAddressId: data?.PhysicalAddressId,
      PhysicalStreet1: data?.PhysicalStreet1,
      PhysicalStreet2: data?.PhysicalStreet2,
      PhysicalCity: data?.PhysicalCity,
      PhysicalState: data?.PhysicalState,
      PhysicalCountry: data?.PhysicalCountry,
      PhysicalPostCode: data?.PhysicalPostCode,

      IsArchived: !!data.CustomerArchived,
      IsShowLimitsOnReport: !!data.IsShowLimitsOnReport,
      Screen: "New Customer",
    });
    if (result >= 0 || result?.length > 0) {
      if (result > 0) {
        const treeData = { ProductId: data?.ProductId, StartLetter: slIndex, CustomerId: result, refreshCheck: true }
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));
      }

      if (window.location.pathname === '/add-new-customer' || window.location.pathname === '/edit-customer') {
        window.dispatchEvent(new Event('storage'));
        window.close()
      }
      return;
    }
    if (result === -10) {
      return setResp10(true);
    }
    if (result !== null && (result < 1 || result?.length < 1)) {
      return;
    }
    return;
  }

  const actions = {
    save: async function () {
      if (data.CustomerId) {
        setFormErrors({});
        ValidateCustomer(data).then(async function ({ data: req, errors }) {
          if (req) {
            handleUpdateCustomer()
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      } else {
        setFormErrors({});
        ValidateCustomer(data).then(async function ({ data: req, errors }) {
          if (req) {
            handleNewCustomer()
          }

          if (errors) {
            setFormErrors(errors);
          }
        });
      }
    },
    manage: async function () {
      setOpenManagement(true);
    },
  };

  useEffect(() => {
    (async () => {
      let lData = localStorage.getItem("edit-customer");
      if (lData !== null) {
        lData = JSON.parse(lData);
        setLocalData(lData)
        if (lData?.CustomerId > 0) {
          let data = await get(`/details/customer?custId=${lData?.CustomerId}`);
          data.Users?.unshift({ UserId: 0, FullName: "Default User" });
          setDropdownData(data || {});
          dispatch({ type: CustomerActions.LOAD_DATA, payload: data.Customer });
          setEditCheck(true)
        }
        else {
          let masterData = await getMasterData(`Users,ExportOptions,Countries,Products,Parents&ProductId=${lData?.ProductId}`);
          masterData.Users?.unshift({ UserId: 0, FullName: "Default User" });
          setDropdownData(masterData || {});
          dispatch({ type: CustomerActions.LOAD_DATA, payload: { ...defaultData, ProductId: lData?.ProductId || 2 } });
        }
      }
    })();
  }, []);

  //handle logo upload
  const fileInputRef = useRef(null);

  function handleFileInputClick() {
    fileInputRef.current.click();
  }

  const handleFileChange = (e) => {
    const imgUrl = URL.createObjectURL(e.target.files[0]);
  };

  function handleCopyToPostal() {
    // CanDoDataMaintenance for button
    dispatch({
      type: CustomerActions.LOAD_DATA,
      // CustomerActions.LOAD_DATA,
      payload: {
        ...data,
        PostalStreet1: data?.PhysicalStreet1 || "",
        PostalStreet2: data?.PhysicalStreet2 || "",
        PostalCity: data?.PhysicalCity || "",
        PostalPostCode: data?.PhysicalPostCode || "",
        PostalState: data?.PhysicalState || "",
        PostalCountry: data?.PhysicalCountry || "",
      },
    });
  }

  function handleCopyToPhysical() {
    // CanDoDataMaintenance for button 
    dispatch({
      type: CustomerActions.LOAD_DATA,
      // CustomerActions.LOAD_DATA,
      payload: {
        ...data,
        PhysicalStreet1: data?.PostalStreet1 || "",
        PhysicalStreet2: data?.PostalStreet2 || "",
        PhysicalCity: data?.PostalCity || "",
        PhysicalPostCode: data?.PostalPostCode || "",
        PhysicalState: data?.PostalState || "",
        PhysicalCountry: data?.PostalCountry || "",
      }
    });
  }
  function handleViewPostal() {
    window.open(
      `https://maps.google.com/maps/place/${data.PostalStreet1 || data.PostalStreet2 || ""},${data?.PostalCity || ""},${data?.PostalPostCode || ""},${data?.PostalState || ""},${data?.PostalCountry || ""}`,
      "_blank"
    );
  }

  function handleViewPhysical() {
    window.open(
      `https://maps.google.com/maps/place/${data.PhysicalStreet1 || data.PhysicalStreet2 || ""},${data?.PhysicalCity || ""},${data?.PhysicalPostCode || ""},${data?.PhysicalState || ""},${data?.PhysicalCountry || ""}`,
      "_blank"
    );
  }

  const handleCloseButton = () => {
    localStorage.removeItem("edit-customer");
  }

  useEffect(function () {
    window.addEventListener("beforeunload", handleCloseButton);
    return function () {
      window.removeEventListener("beforeunload", handleCloseButton);
    };
  });

  return (
    <Card sx={{ padding: 1 }}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <Form
        sections={FormSections(
          dropdownData,
          handleCopyToPhysical,
          handleCopyToPostal,
          handleViewPhysical,
          handleViewPostal,
          handleFileInputClick,
          CanDoDataMaintenance, CanAdministrate, resp10
        )}
        data={data}
        spacing={2}
        dispatch={dispatch}
        key={data._key}
        errors={formErrors}
      />
      <FormFooter
        footerText={`ProductId: ${data?.ProductId || localData?.ProductId || ""}, CustomerId: ${data?.CustomerId || ""},`}
        buttons={GetFormButtons(data, handleNewCustomer, actions, editCheck, CanDoDataMaintenance)} />
      <AppDialog
        open={openManagement}
        title={`Customer Management  Customer ${data.CustomerId}`}
        maxWidth="100vw"
        handleClose={() => setOpenManagement(false)}
      >
        <ManageCustomer unit={data} />
      </AppDialog>
    </Card>
  );
}
export default EditCustomer;

const GetFormButtons = (data, handleNewCustomer, { save, manage }, editCheck, CanDoDataMaintenance) => [
  !!editCheck && {
    variant: "outlined",
    label: "Manage",
    onClick: manage,
  },
  !!editCheck && {
    variant: "outlined",
    label: "Save New",
    onClick: handleNewCustomer,
    startIcon: <NewIcon />,
  },
  CanDoDataMaintenance && {
    variant: "contained",
    label: "Save",
    onClick: save,
    startIcon: <Check />,
  },
];

function FormSections(
  dropdownData,
  handleCopyToPhysical,
  handleCopyToPostal,
  handleViewPhysical,
  handleViewPostal,
  handleFileInputClick,
  CanDoDataMaintenance, CanAdministrate, resp10,
) {
  return [
    {
      fields: [
        //Customer & Product Details
        {
          xsCol: 12,
          component: function () {
            return <Typography sx={{ color: "red" }}> {resp10 === true ? "Please provide a UNIQUE name for Customer" : ".  "}</Typography>
          },
        },
        {
          name: "Customer",
          label: "Name",
          xsCol: 6,
        },
        {
          name: "ProductId",
          label: "Product",
          type: "dropdown",
          required: "true",
          selectOptions: (dropdownData.Products || []).map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
          xsCol: 3,
        },
        {
          name: "CustomerArchived",
          label: "Archived - no website",
          type: "switch",
          xsCol: 3,
        },
        { name: "AccountNumber", label: "Account", xsCol: 3 },
        { name: "SapCustomer", label: "Acc Name", xsCol: 3 },
        {
          name: "ParentId",
          label: "Parent",
          type: "dropdown",
          required: "true",
          xsCol: 3,
          selectOptions: (dropdownData.Parents || []).map((x) => ({
            value: x.ParentId,
            text: x.Parent,
          })),
        },
        {
          name: "BlockCustomerEvaluationsFrom",
          label: "Limited From",
          xsCol: 3,
          type: "date",
        },
        {
          name: "ExportOptionId",
          label: "Exports",
          type: "dropdown",
          required: "true",
          xsCol: 3,
          selectOptions: (dropdownData.ExportOptions || []).map((x) => ({
            value: x.ExportOptionId,
            text: x.ExportOption,
          })),
        },

        {
          name: "CmsOwnerId",
          label: "CMS Owner",
          type: "dropdown",
          xsCol: 3,
          selectOptions: (dropdownData.Users || []).map((x) => ({
            value: x.UserId,
            text: x.FullName,
          })),
        },
        {
          name: "OwnerToAllSites",
          label: "Owner To All Sites",
          type: "switch",
          xsCol: 3,
        },
        {
          name: "IsShowLimitsOnReport",
          label: "Show Test Limits on Report",
          type: "switch",
          xsCol: 3,
        },
        {
          name: "AlternativeNames",
          label: "Known As",
          xsCol: 6,
          type: "textarea",
        },
        { name: "CustomerNotes", label: "Comment", xsCol: 6, type: "textarea" },
        {
          xsCol: 12,
          component: function () {
            return <SectionTitle title="Additional Information" />;
          },
        },
      ],
    },

    //Address Details

    {
      xsCol: 6,
      fields: [
        { name: "PhysicalStreet1", label: "Physical Address 1", xsCol: 12 },
        { name: "PhysicalStreet2", label: "Address 2", xsCol: 12 },
        { name: "PhysicalCity", label: "Town", xsCol: 12 },
        { name: "PhysicalState", label: "State", xsCol: 6 },
        { name: "PhysicalPostCode", label: "Postcode", xsCol: 6 },
        {
          name: "PhysicalCountry",
          label: "Country",
          type: "dropdown",
          selectOptions: (dropdownData.Countries || []).map((x) => ({
            value: x.Country,
            text: x.Country,
          })),
          xsCol: 12,
        },
        {
          xsCol: 6,
          component: function () {
            return <Button onClick={handleViewPhysical}>View Physical</Button >;
          },
        },
        {
          xsCol: 6,
          component: function () {
            return <Button onClick={handleCopyToPostal}>Copy to Postal</Button >;
          },
        },
      ],
    },
    {
      xsCol: 6,
      fields: [
        { name: "PostalStreet1", label: "Postal Address 1", xsCol: 12 },
        { name: "PostalStreet2", label: "Address 2", xsCol: 12 },
        { name: "PostalCity", label: "Town", xsCol: 12 },
        { name: "PostalState", label: "State", xsCol: 6 },
        { name: "PostalPostCode", label: "Postcode", xsCol: 6 },
        {
          name: "PostalCountry",
          label: "Country",
          type: "dropdown",
          selectOptions: (dropdownData.Countries || []).map((x) => ({
            value: x.Country,
            text: x.Country,
          })),
          xsCol: 12,
        },
        {
          xsCol: 6,
          component: function () {
            return <Button onClick={handleViewPostal}>View Postal</Button >;
          },
        },
        {
          xsCol: 6,
          component: function () {
            return <Button onClick={handleCopyToPhysical}>Copy to Physical</Button >;
          },
        },
      ],
    },
    CanAdministrate && {
      xsCol: 7,
      fields: [
        {
          variant: "outlined",
          label: "Report Logo",
          xsCol: 3,
          component: function () {
            return (
              <Button
                startIcon={<ReportLogoIcon />}
                variant="outlined"
                onClick={handleFileInputClick}
                disabled={!CanDoDataMaintenance}
              >
                Report Logo
              </Button>
            );
          },
        },
        {
          variant: "outlined",
          label: "Report Logo",
          xsCol: 9,
          component: function () {
            return (
              <Typography style={{ marginTop: 7 }}>
                Image requirements: JPEG, 100px to 1000px high, e.g. 200 x 100
              </Typography>
            );
          },
        },
      ],
    },
  ];
}
