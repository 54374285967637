import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { PRINTER_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";


export default function PrinterSettings({ cancel }) {
  const { outget } = useFetch();

  const [printersList, setPrintersList] = useState([]);
  const [defaultPrinter, setDefaultPrinter] = useState("");

  const getData = async () => {
    let checkKey = localStorage.getItem("defaultPrinter");
    if (checkKey === null || checkKey === undefined) {
      const temp1 = (await outget(`${PRINTER_APIS?.GET_DEFAULT_PRINTER} `)) || {};
      const dp = temp1?.default_printer;
      setDefaultPrinter(dp);
    }
    else { setDefaultPrinter(checkKey) }

    const temp2 = (await outget(`${PRINTER_APIS?.GET_ALL_PRINTERS} `)) || {};
    if (temp2?.printers?.length > 0) {
      setPrintersList(temp2?.printers);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const handleChange = async (i) => {
    setDefaultPrinter(i);
  }

  const handleSaveClick = async () => {
    localStorage.setItem("defaultPrinter", defaultPrinter);
    window.dispatchEvent(new Event('storage'))
    cancel && cancel();
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ p: 1 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>{printersList?.length > 0
            ? "Select a printer for printing your sticker."
            : "Looks like Sticker Printer app is not running."}
          </Typography>
        </Grid>
        {printersList?.map((i, key) => {
          return (
            <Grid item xs={12} key={key} sx={{ mt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={i}
                    checked={i === defaultPrinter}
                    onChange={(e) => handleChange(i)}
                  // sx={{
                  //   color: checkedItems?.filter(item => item?.ProductId === product.ProductId)[0]?.IsDefault === true
                  //     ? "green"
                  //     : "initial",
                  // }}
                  />
                }
                label={i}
                sx={{
                  width: "50%",
                  backgroundColor: "transparent",
                }}
                labelPlacement="end"
              // style={{
              //   backgroundColor: checkedItems?.filter(item => item?.ProductId === product.ProductId)[0]?.IsDefault === true
              //     ? "green"
              //     : "transparent",
              //   color: checkedItems?.filter(item => item?.ProductId === product.ProductId)[0]?.IsDefault === true ? "white" : "initial",
              // }}
              />
            </Grid>
          )
        })}
        {printersList?.length > 0
          ? <Grid item xs={12}>
            <Button
              sx={{ ml: 50 }}
              variant="contained"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Grid>
          : <></>
        }</Grid>
    </Grid>
  );
}
