import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material"
import { MANAGEMENT_APIS } from "../../common/apis";
import useFetch from "../../hooks/useFetch";
import { useEffect } from "react";
import { useState } from "react";

const GlobalSettings = ({ cancel }) => {

    const { get } = useFetch();
    const [useQC, setUseQC] = useState(false);

    const getBillingRates = async () => {
        const bills = await get(`${MANAGEMENT_APIS.GLOBAL_SETTINGS}?SettingsId=${2}`)
        setUseQC(bills?.SettingsValue);
    }

    const handleSaveClick = async () => {
        await get(`${MANAGEMENT_APIS.EDIT_GLOBAL_SETTINGS}?SettingsId=${2}&SettingValue=${useQC}`)
        cancel && cancel()
    }

    useEffect(() => { getBillingRates() }, [])

    return (
        <Grid
            container
            spacing={2}
            sx={{ minWidth: "25vw", minHeight: "25vh", }}
        >
            <Grid item xs={12}>
                <Grid item xs={12} mt={1}>
                    <Typography>Settings for all users and products</Typography>
                    <Grid item xs={6} mt={1}>
                        <FormControlLabel
                            checked={useQC}
                            control={<Checkbox />}
                            label="Use Quality Check"
                            onChange={() => setUseQC(!useQC)}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ position: "absolute", right: "25px", bottom: "25px" }} onClick={handleSaveClick}>
                    <Button>Save</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default GlobalSettings;