import {
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Radio,
  useTheme,
} from "@mui/material";

export default function RadioOption({ id, name, label, value, ...options }) {
  const theme = useTheme();
  return (
    <FormControlLabel
      control={
        <Radio value={value} name={name} id={id} size="small" {...options} />
      }
      label={label}
      // sx={{ mt: theme.spacing(3), ml: 0 }}
    />
  );
}
