import { Check, ControlPointOutlined, Delete } from "@mui/icons-material";
import { useEffect, useReducer, useState } from "react";
import Form, { FormActions } from "../../components/Form";
import FormFooter from "../../components/FormFooter";
import useFetch from "../../hooks/useFetch";
import { DETAILS_APIS } from "../../common/apis";
import { useRoot, useUser } from "../../rootContext";
import moment from "moment";
import ConfirmModal from "../../components/ConfirmModal";
import AppDialog from "../../components/AppDialog";
import { toIsoFormat } from "../../utils";

export default function EditEventForm({
  setOpenEditEvent,
  fetchData,
  formData,
  eventTypes,
  eventFilter,
  cancel,
  setExpandTreeData,
  handleSearchTree,
  startLetter
}) {

  const { CanDoDataMaintenance } = useRoot();
  const user = useUser();
  const { get, post } = useFetch();

  let date = new moment();
  let targetDate = moment(date).subtract(7, 'day'); // date object

  const defaultEventsData = {
    EventTypeId: 3,
    EventDate: (targetDate),// new moment(),
  };

  const [data, dispatch] = useReducer(EventReducer, defaultEventsData);
  const [openAlert, setOpenAlert] = useState(false);

  const handleDeleteEvent = async () => {
    setOpenAlert(false);
    const resp = await get(`${DETAILS_APIS.DELETE_EVENT_LOG}?EventLogId=${data?.EventLogId}`, {});
    if (resp > 0) {
      setOpenEditEvent && setOpenEditEvent(false);
      cancel && cancel();
      const treeData = {
        ProductId: eventFilter?.ProductId,
        StartLetter: eventFilter?.StartLetter >= 0 ? eventFilter?.StartLetter : startLetter,
        CustomerId: eventFilter?.CustomerId,
        SiteId: eventFilter?.SiteId,
        UnitId: eventFilter?.UnitId,
        refreshCheck: true
      }
      fetchData && fetchData({ ...treeData, SampleNumber: data?.SampleNumber, });
      setExpandTreeData && setExpandTreeData(treeData)
      handleSearchTree && handleSearchTree(treeData, null, true)
      if (window.location.pathname === "/add-event" || window.location.pathname === "/edit-event") {
        window.close();
      }
    }
  }

  const actions = {
    clear: function (e) {
      dispatch({
        type: EventActions.RESET_FORM,
        payload: { ...defaultEventsData },
      });
    },
    deleteFluid: function (e) {
      setOpenAlert(true)
    },
    save: async function (e) {
      const treeData = {
        ProductId: eventFilter?.ProductId,
        StartLetter: eventFilter?.StartLetter,
        CustomerId: eventFilter?.CustomerId,
        SiteId: eventFilter?.SiteId,
        UnitId: eventFilter?.UnitId,
        refreshCheck: true
      }

      if (eventFilter?.addNew === true && (data?.EventLogId === null || data?.EventLogId === undefined)) {
        const payLoad = {
          EventLogId: null,
          EventTypeId: data?.EventTypeId,
          MeterReading: data?.MeterReading,
          EventDate: toIsoFormat(data?.EventDate),
          SiteId: (eventFilter.ComponentId) ? null : (eventFilter.SiteId) ? (eventFilter.SiteId) : null,
          UnitId: (eventFilter.ComponentId) ? null : (eventFilter.UnitId) ? (eventFilter.UnitId) : null,
          ComponentId: (eventFilter.ComponentId) ? (eventFilter.ComponentId) : null,
          Note: data?.Event,
          Name: user?.Username,
          SampleNumber: data?.SampleNumber > 0 ? data?.SampleNumber : null,
        };
        const resp = await post(DETAILS_APIS.ADD_EVENT_LOG, payLoad);
        if (resp > 0) {
          setOpenEditEvent && setOpenEditEvent(false);
          fetchData && fetchData({ ...treeData, SampleNumber: data?.SampleNumber, });
          cancel && cancel();

          setExpandTreeData && setExpandTreeData(treeData)
          handleSearchTree && handleSearchTree(treeData, null, true)
        }
        if (window.location.pathname === "/add-event" || window.location.pathname === "/edit-event") {
          window.close();
        }
        return;
      }
      if (data?.EventLogId === null || data?.EventLogId === undefined) {

        const payLoad = {
          EventLogId: null,
          EventTypeId: data?.EventTypeId,
          MeterReading: data?.MeterReading,
          EventDate: toIsoFormat(data?.EventDate),
          SiteId: (eventFilter.ComponentId) ? null : (eventFilter.SiteId) ? (eventFilter.SiteId) : null,
          UnitId: (eventFilter.ComponentId) ? null : (eventFilter.UnitId) ? (eventFilter.UnitId) : null,
          ComponentId: (eventFilter.ComponentId) ? (eventFilter.ComponentId) : null,
          Note: data?.Event,
          Name: user?.Username,
          SampleNumber: data?.SampleNumber > 0 ? data?.SampleNumber : null,
        }
        const resp = await post(DETAILS_APIS.ADD_EVENT_LOG, payLoad);
        if (resp > 0) {
          setOpenEditEvent && setOpenEditEvent(false);
          fetchData && fetchData({ ...treeData, SampleNumber: data?.SampleNumber, });
          cancel && cancel();

          setExpandTreeData && setExpandTreeData(treeData)
          handleSearchTree && handleSearchTree(treeData, null, true)
        }
        if (window.location.pathname === "/add-event" || window.location.pathname === "/edit-event") {
          window.close();
        }
        return;
      }
      if (eventFilter?.addNew !== true || data?.EventLogId !== null) {
        const payLoad = {
          EventLogId: data?.EventLogId,
          EventTypeId: data?.EventTypeId,
          MeterReading: data?.MeterReading,
          EventDate: toIsoFormat(data?.EventDate),
          Note: data?.Event,
          Name: user?.Username || data?.AddedBy,
          SampleNumber: data?.SampleNumber > 0 ? data?.SampleNumber : null,
        };
        const resp = await post(DETAILS_APIS.SAVE_EVENT_LOG, payLoad);

        if (resp > 0) {
          setOpenEditEvent && setOpenEditEvent(false);
          fetchData && fetchData({ ...treeData, SampleNumber: data?.SampleNumber, });
          cancel && cancel();

          setExpandTreeData && setExpandTreeData(treeData)
          handleSearchTree && handleSearchTree(treeData, null, true)
        }
        if (window.location.pathname === "/add-event" || window.location.pathname === "/edit-event") {
          window.close();
        }
      }

    }
  }

  useEffect(
    function () {
      (async function () {
        if (formData?.SampleNumber > 0 || formData?.EventLogId > 0) {
          dispatch({
            type: EventActions.LOAD_DATA,
            payload: { ...defaultEventsData, ...formData, },
          });
        }
      })();
    },
    [formData]
  );

  return (
    <>
      <Form
        sections={FormSections(data, eventTypes)}
        data={data}
        spacing={2}
        dispatch={dispatch}
        key={data?.EventLogId || data?._ts_key}
      />
      <FormFooter
        buttons={GetFormButtons(data, actions, CanDoDataMaintenance)}
        footerText={`ProductId: ${eventFilter?.ProductId || ""}, CustomerId: ${eventFilter?.CustomerId || ""}, SiteId: ${eventFilter?.SiteId || ""}, UnitId: ${eventFilter?.UnitId || ""} , ComponentId: ${eventFilter?.ComponentId || ""} , EventLogId: ${formData?.EventLogId || ""} `}
      />
      <AppDialog
        open={openAlert}
        title={"Delete Event"}
        maxWidth="md"
        handleClose={() => { setOpenAlert(false); }}
      >
        <ConfirmModal
          handleClose={() => {
            setOpenAlert(false);
          }}
          alertAction={handleDeleteEvent}
          message={`Are you sure you wish to Delete the event?`}
          buttonText={"Delete"}
          buttonText2={"Cancel"} />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (data, { deleteFluid, save, clear }, CanDoDataMaintenance) => [

  {
    hidden: !CanDoDataMaintenance,
    label: "Delete",
    color: "danger",
    onClick: deleteFluid,
    startIcon: <Delete />,
    disabled: !data?.EventLogId,
  },
  {
    hidden: !CanDoDataMaintenance,
    variant: "outlined",
    label: "New",
    onClick: clear,
    startIcon: <ControlPointOutlined />,
  },
  {
    hidden: !CanDoDataMaintenance,
    variant: "contained",
    label: "Save",
    onClick: save,
    disabled: !(
      data?.MeterReading &&
      data?.Event &&
      data?.EventDate &&
      data?.EventTypeId
    ),
    startIcon: <Check />,
  },
];

function FormSections(data, eventTypes) {
  return [
    {
      fields: [
        {
          name: "EventTypeId",
          label: "Event Type",
          required: true,
          type: "dropdown",
          xsCol: 3,
          selectOptions: (eventTypes || []).map((x) => ({
            value: x.EventTypeId,
            text: x.EventType,
          })),
        },
        {
          name: "SampleNumber",
          label: "Sample Number",
          xsCol: 2,
        },
        // { name: "EventDate", label: "Occurred", xsCol: 3, type: "datetime" },
        {
          name: "EventDate",
          label: "Occurred",
          type: "date",
          alignSelf: "end",
          xsCol: 3,
          arrowCheck: true,
        },
        {
          name: "MeterReading",
          label: "Meter Reading",
          xsCol: 2,
          type: "number",
        },
        { name: "UnitHours", label: "Unit Hours", xsCol: 2, disabled: "true" },
        {
          name: "Event",
          label: "Description",
          type: "textarea",
          xsCol: 8,
        },
      ],
    },
  ];
}
const EventActions = {
  LOAD_DATA: "load-data",
};
function EventReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload, _ts_key: Date.now() };
  }
  if (action.type === EventActions.RESET_FORM) {
    return { ...action.payload, _ts_key: Date.now() };
  }

  return state;
}
