import { Card, Divider } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ImageList, ImageListItem } from "@mui/material";
import useFetch from "../hooks/useFetch";
import FormFooter from "./FormFooter";
import SelectImageForm from "./SelectImageForm";
import { Box } from "@mui/system";
import { DETAILS_APIS } from "../common/apis";
import { ValidateImageSchema } from "../validation/schemas/ImageSchema";
import { useRoot, useToast, useUser } from "../rootContext";
import { DeleteIcon, SaveIcon } from "./../icons/ButtonIcons";
import AppDialog from "./AppDialog";
import appResources from "../app-resources/en/Resources.json";
import useQuery from "../hooks/useQuery";
import ConfirmModal from "./ConfirmModal";

export default function SelectImage({ sampleId, cancel }) {
  const user = useUser();
  const { urlsampleNumber } = useQuery();
  const { CanDoDataMaintenance } = useRoot();

  const [images, setImages] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [formErrors, setFormErrors] = useState({});
  const { get, post } = useFetch();
  const { showToast, showErrorToast } = useToast();
  const [dialog, setDialog] = useState({
    title: "",
    message: "",
    actionName: "",
    open: false,
    width: "",
  });

  const actions = {
    save: async () => {
      const temp = images.map((x) => ({
        ...x,
        Title: x.Title ? x.Title : "",
        LightSource: isNaN(x.LightSource) ? "0" : x.LightSource,
        Magnification: x.Magnification ? x.Magnification : "0",
        Comment: x.Comment ? x.Comment : "",
        DisplayRank: x.DisplayRank ? x.DisplayRank : 0,
        ImageId: x.ImageId ? x.ImageId : 0,
        Image: x.ImageId ? undefined : x.Image,
        Extension: x.Extension ? x.Extension : "",
        MimeType: x.MimeType ? x.MimeType : "",
        DisplayToContacts: x.DisplayToContacts ? x.DisplayToContacts : false,
        LightSource: x.LightSource ? x.LightSource : "",
        SampleNumber: x.SampleNumber ? x.SampleNumber : "",
        AddedBy: user.Username,
        ModifiedBy: user.Username,
        ...(x.Added !== null && { Added: undefined }),
        ...(x.Added === null && { Added: undefined }),
        ...(x.Modified !== null && { Modified: undefined }),
        ...(x.Modified === null && { Modified: undefined }),
      }));
      setFormErrors({});
      ValidateImageSchema(temp).then(async function ({ temp: req, errors }) {
        //   if (req) {
        const response = await post(DETAILS_APIS.ADD_SAMPLE_IMAGE, temp);
        if (response > 0) {
          showToast(appResources.ADDED_SUCCESSFULLY_MSG);
          getImages();
        } else {
          showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
        }

        // }
        // if (errors) {
        //   setFormErrors(errors);
        // }
      });
      cancel && cancel();
    },

    deleteImage: function () {
      setDialog({
        title: "Delete Image",
        message: appResources.DELETE_CONFIRMATION_MSG,
        actionName: "deleteClickAlert",
        open: true,
        width: "md",
      });
    },
  };

  async function deleteClickAlert() {
    const response = await get(`${DETAILS_APIS.DELETE_SAMPLE_IMAGE}?ImageId=${selectedImage.ImageId}`);
    if (response > 0) {
      showToast(appResources.DELETED_SUCCESSFULLY_MSG);
    } else {
      showErrorToast(appResources.DELETED_SUCCESSFULLY_MSG);
    }
    const previousLength = images.length;
    if (selectedIndex > -1) {
      images.splice(selectedIndex, 1);
      setImages([...images]);

      selectedIndex === previousLength - 1 &&
        setSelectedIndex(previousLength - 2);
    }
    setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));
  }

  const handleImageChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0]; //type, name,
    // if (
    //   file.name.split(".")[1] !== "jpg" &&
    //   file.name.split(".")[1] !== "png"
    // ) {
    //   alert("Invalid Image format!");
    // } else {
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const imgBase64 = e.target.result.split(",")[1];
      const ext = file.name.split(".")[1];
      const previousLength = images.length;
      setImages((images) => [
        ...images,
        {
          Image: imgBase64,
          Extension: ext,
          MimeType: file.type,
        },
      ]);

      setSelectedIndex(previousLength);
    };
    // }
  };

  async function getImages() {
    if (sampleId > 0) {
      const imageData = await get(`${DETAILS_APIS.GET_SAMPLE_IMAGES}?sample=${sampleId}`);
      if (imageData && imageData.length) {
        setImages(imageData);
        setSelectedIndex(0);
      }
    }
    if (urlsampleNumber > 0) {
      const imageData = await get(`${DETAILS_APIS.GET_SAMPLE_IMAGES}?sample=${urlsampleNumber}`);
      if (imageData && imageData.length) {
        setImages(imageData);
        setSelectedIndex(0);
      }
    }
  }

  useEffect(() => {
    setSelectedIndex(null);
    getImages();
  }, [sampleId]);

  const selectedImage = useMemo(
    function () {
      if (images[selectedIndex]) {
        return images[selectedIndex];
      } else if (images.length) {
        return images[images.length - 1];
      }
      return null;
    },
    [selectedIndex, images]
  );

  function SelectImage(item, index) {
    setSelectedIndex(index);
  }

  function onImageDataChanged(imgData) {
    images[selectedIndex] = imgData;
    setImages([...images]);
  }
  const style = {
    border: "2px solid blue",
  };

  return (
    <>
      <Card sx={{ padding: 2, minWidth: "50vw" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleImageChange}
          />

          {(CanDoDataMaintenance && (images && images.length > 0)) && (
            <FormFooter
              buttons={GetFormButtons(actions, images,)}
            />
          )}
        </div>

        {selectedImage && (
          <Card key={selectedIndex} sx={{ p: 1 }}>
            <SelectImageForm
              onImageDataChanged={onImageDataChanged}
              data={{ ...selectedImage, SampleNumber: sampleId || urlsampleNumber }}
              formErrors={formErrors}
            />
          </Card>
        )}
        {selectedImage && (
          <img
            src={"data:image/jpeg;base64," + selectedImage.Image}
            alt="Images of Sample"
            style={{
              height: "300px",
              objectFit: "contain",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
              padding: "10px",
            }}
          />
        )}
        <Divider />

        <ImageList style={{ width: "100%", height: 140 }} cols={12}>
          {images.map((item, i) => (
            <ImageListItem key={i}>
              <Box style={i == selectedIndex ? style : {}}>
                <img
                  src={"data:image/jpeg;base64," + item.Image}
                  alt={"no images"}
                  loading="lazy"
                  style={{ width: "120px", height: "110px" }}
                  onClick={() => SelectImage(item, i)}
                />
              </Box>
            </ImageListItem>
          ))}
        </ImageList>
      </Card>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        title={dialog.title}
        maxWidth={dialog.width}
      >
        <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({
              ...prevState,
              open: false,
            }));
          }} alertAction={deleteClickAlert}
          message={dialog.message}
          buttonText={"Delete"}
          buttonText2={"Cancel"}
        />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (
  { save, deleteImage },
  images,
) => [
    {
      variant: "outlined",
      label: "Delete",
      disabled: images.length <= 0,
      onClick: deleteImage,
      startIcon: <DeleteIcon />,
    },
    {
      label: "Save",
      onClick: save,
      startIcon: <SaveIcon />,
    },
  ];
