import { Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import SwitchInput from "../../components/SwitchInput";
import useFetch from "../../hooks/useFetch";
import EditEventForm from "./EditEventForm";
import EditEventTable from "./EditEventTable";
import { DETAILS_APIS, MASTER_DATA_APIS } from "../../common/apis";

export default function EditEvent({
  eventFilter,
  setOpenEditEvent,
  fetchData,
  cancel,
  setExpandTreeData,
  handleSearchTree,
  startLetter
}) {
  const { get } = useFetch();

  const [hideEvents, setHideEvents] = useState(true);
  const [eventsData, setEventsData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [localData, setLocalData] = useState({})

  async function getEventsData(eF) {
    const params = [];
    if (eF) {
      if (eF.SiteId) {
        params.push(`sId=${eF.SiteId}`);
        if (eF.UnitId) {
          params.push(`uId=${eF.UnitId}`);
          if (eF.ComponentId) {
            params.push(`compId=${eF.ComponentId}`);
            if (eF.sampleNo || eF.SampleNumber) {
              params.push(`sampleNo=${eF.sampleNo || eF.SampleNumber}`);
            }
          }
        }
        const temp = await get(`${DETAILS_APIS.GET_EVENTS_LOGS}?` + params.join("&"));
        setEventsData(temp);
      }

      const data = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=EventTypes&compId=${(eF.ComponentId || 1)}`, { skipBlocker: true });
      if (data?.EventTypes?.length > 0) {
        setEventTypes(data?.EventTypes);
      }
      localStorage.removeItem("edit-event")
    }
  }
  //Event types call
  useEffect(function () {
    let le = JSON.parse(localStorage.getItem("edit-event"))

    if (le?.UnitId > 0 || le?.SiteId > 0) {
      setLocalData(le);
      getEventsData(le)
      setRowData(le)
    } else {
      getEventsData(eventFilter);
      setRowData(eventFilter)
    }
  }, []);

  const data = useMemo(
    function () {
      return hideEvents ? eventsData.filter((x) => x?.EventTypeId !== 3) : eventsData;
    },
    [hideEvents, eventsData]
  );

  function rowSelectedHandler(rowDatas) {
    setRowData(rowDatas);
  }

  return (
    <Card sx={{ p: 1 }}>
      <Grid container>
        <>
          {(eventFilter?.Product || localData?.Product) && (
            <Typography style={{ marginBottom: 10 }}>
              {eventFilter?.Product || localData?.Product}
              ,&nbsp;
              {eventFilter?.Customer || localData?.Customer}
              ,&nbsp;
              {eventFilter?.Site || localData?.Site}
              ,&nbsp;
              {eventFilter?.Unit || localData?.Unit}
              ,&nbsp;
              {eventFilter?.UnitModel || localData?.UnitModel}
              ,&nbsp;
              {eventFilter?.Component || localData?.Component}
            </Typography>
          )}
        </>
        <EditEventForm
          setOpenEditEvent={setOpenEditEvent}
          fetchData={fetchData}
          formData={rowData}
          eventTypes={eventTypes}
          eventFilter={eventFilter || localData}
          key={rowData?.EventLogId}
          cancel={cancel}
          startLetter={startLetter || localData?.StartLetter}
          setExpandTreeData={setExpandTreeData}
          handleSearchTree={handleSearchTree}
        />

        <Grid container>
          <Grid item xs={6}>
            <SwitchInput
              label="Hide Fluid Change Events"
              value={hideEvents}
              onChange={(e) => setHideEvents(e.target.checked)}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {data && data?.length}</b>
          </Typography>
          <EditEventTable rowSelected={rowSelectedHandler} data={data} />
        </Grid>

      </Grid>
    </Card>
  );
}
