import { useState } from "react";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import AppDialog from "../../../components/AppDialog";
import EditContacts from "../../../forms/EditContacts";
import ContactPrimary from "../../reports/critical-logs/contact-permission-overview/ContactPrimary";
import ContactAccessPermission from "../../reports/critical-logs/contact-permission-overview/ContactAccessPermission";
import { useWindowPopups } from "../../../components/useWindowPopups";

export default function SubscriptionOverviewTable({ data,
    rowSelected,
    setOpenDialog,
    subscriptionList,
    ...props
}) {

    const [row, setRow] = useState({});
    const [propId, setPropId] = useState();
    const [dialog, setDialog] = useState({
        open: false,
        title: "",
    });
    function HandleNewWindow(actionName, data) {
        useWindowPopups(actionName, data)
    }
    const tableActions = {
        edit: {
            action: function (row) {
                HandleNewWindow("edit-contact", row)
            },
            label: "Edit",
        },
        access: {
            action: function (row) {
                setRow(row);
                setPropId(2);
                setDialog({
                    open: true,
                    title: `Contacts Access Permissions for ${row.Username}`,
                });
            },
            label: "Access",
        },
        reports: {
            action: function (row) {
                setRow(row);
                setPropId(3);
                setDialog({
                    open: true,
                    title: `Contact Sample Reporting for ${row.DisplayName} ${row.Username}`,
                });
            },
            label: "Reports",
        },
        subscriptions: {
            action: function (row) {
                setRow(row);
                setPropId(4);
                setDialog({
                    open: true,
                    title: `Contact Subscriptions for ${row.Username}`,
                });
            },
            label: "Subscriptions",
        },
        primary: {
            action: function (row) {
                setRow(row);
                setPropId(5);
                setDialog({
                    open: true,
                    title: `Contact Primary Mappings for ${row.Username}`,
                });
            },
            label: "Primary",
        },
        delete: {
            action: function (row) {
                // alert("Delete");
            },
            label: "Delete",
        },
    };

    const options = {
        columns: GetColumns(tableActions),
        sx: { minWidth: "85vw", minHeight: "37vw" },
        dataSource: subscriptionList,
        rowIdProperty: "SubscriptionId",
        isDisableColumnFilter: true,
        cellStylingHandler: "accessPermissionOverviewForm",
        rowsSelected: function (r) {
            r.length && rowSelected(r[0]);
        },
    };

    return (
        <>
            <DataTable
                {...options}
            />
            <AppDialog
                open={dialog.open}
                handleClose={() =>
                    setDialog((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
                title={dialog.title}
                maxWidth="100vw"
            >
                {dialog.title === "Edit Contact" && (
                    <EditContacts
                        contactId={row.ContactId}
                        setEditContactsDialog={setDialog}
                    />
                )}
                {dialog.title === `Contact Primary Mappings for ${row.Username}` && (
                    <ContactPrimary contactId={row.ContactId} />
                )}
                {(dialog.title === `Contacts Access Permissions for ${row.Username}` ||
                    dialog.title ===
                    `Contact Sample Reporting for ${row.DisplayName} ${row.Username}` ||
                    dialog.title === `Contact Subscriptions for ${row.Username}`) && (
                        <ContactAccessPermission
                            contactId={row.ContactId}
                            propId={propId}
                            username={row && row.DisplayName}
                        />
                    )}
            </AppDialog>
        </>
    )
}

const GetColumns = (tableActions) => [
    {
        headerName: "Edit",
        field: "Edit",
        width: 60,
        renderCell: function ({ row }) {
            return (
                <DataGridAction
                    minWidth={"50px"}
                    row={row}
                    onClick={tableActions.edit.action}
                    label={tableActions.edit.label}
                />
            );
        },
    },
    {
        headerName: "Access",
        field: "Access",
        width: 65,
        renderCell: function ({ row }) {
            return (
                <DataGridAction
                    minWidth={"55px"}
                    row={row}
                    onClick={tableActions.access.action}
                    label={tableActions.access.label}
                />
            );
        },
    },
    {
        headerName: "Reports",
        field: "Reports",
        width: 65,
        renderCell: function ({ row }) {
            return (
                <DataGridAction
                    row={row}
                    minWidth={"55px"}
                    onClick={tableActions.reports.action}
                    label={tableActions.reports.label}
                />
            );
        },
    },
    {
        headerName: "Subscriptions",
        field: "Subscriptions",
        width: 96,
        renderCell: function ({ row }) {
            return (
                <DataGridAction
                    minWidth={"80px"}
                    row={row}
                    onClick={tableActions.subscriptions.action}
                    label={tableActions.subscriptions.label}
                />
            );
        },
    },
    {
        headerName: "Primary",
        field: "Primary",
        width: 65,
        renderCell: function ({ row }) {
            return (
                <DataGridAction
                    row={row}
                    minWidth={"55px"}
                    onClick={tableActions.primary.action}
                    label={tableActions.primary.label}
                />
            );
        },
    },
    {
        headerName: "Email",
        field: "Username",
        width: 180,
    },
    {
        headerName: "Name",
        field: "DisplayName",
        width: 98,
    },
    {
        headerName: "Scope",
        field: "Scope",
        width: 65,
    },
    {
        headerName: "Report",
        field: "Report",
        width: 100,
    },
    {
        headerName: "Weekly",
        field: "Weekly",
        width: 65,
    },
    {
        headerName: "Monthly",
        field: "Monthly",
        width: 65,
    },
    {
        headerName: "Regarding",
        field: "Regarding",
        width: 76,
    },
    {
        headerName: "Source",
        field: "Source",
        width: 70,
    },
    {
        headerName: "Mobile",
        field: "Mobile",
        width: 104,
    },
    {
        headerName: "Telephone",
        field: "Telephone",
        width: 97,
    },
    {
        headerName: "Role",
        field: "ContactRole",
        width: 60,
    },
    {
        headerName: "Archived",
        field: "Archived",
        width: 69,
    },
    {
        headerName: "Company",
        field: "Company",
        minWidth: 90,
        flex: 1
    },
    {
        headerName: "Delete",
        field: "Delete",
        width: 65,
        renderCell: function ({ row }) {
            return (
                <DataGridAction
                    minWidth={"55px"}
                    row={row}
                    onClick={tableActions.delete.action}
                    label={tableActions.delete.label}
                />
            );
        },
    },
];
