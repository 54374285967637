import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useEffect } from "react";
import appResources from "../../../app-resources/en/Resources.json";
import { SaveIcon } from "../../../icons/ButtonIcons";
import { MANAGEMENT_APIS, MASTER_DATA_APIS } from "../../../common/apis";

const headingMessage = appResources.PRODUCT_NOTIFICATIONS_MESSAGE;
const successMessage = { color: "green", message: "Your product notifications have been updated" };
const errorMessage = { color: "purple", message: "No changes were made to your product notifications" };

export default function ProductNotifications() {
  const { get, post } = useFetch();

  const [checkedItems, setCheckedItems] = useState([]);
  const [infoMessage, setInfoMessage] = useState({})
  const [products, setProducts] = useState([]);

  const getData = async () => {
    let resData = await get(MANAGEMENT_APIS.USER_PRODUCT_TYPE_NOTIFICATIONS);
    if (resData?.length > 0) {
      let a = resData.map(item => { return { ...item, IsDefault: true, IsExisting: true } })
      setCheckedItems(a);
      localStorage.setItem("ptn", JSON.stringify(a));
    }
    else { localStorage.setItem("ptn", JSON.stringify([])); }
  }

  useEffect(() => {
    getData();
    (async function () {
      const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Products`);
      setProducts(response?.Products);
    })();
  }, []);

  function removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.ProductId === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }

  const handleProductChange = (productId, isChecked) => {
    removeObjectWithId(checkedItems, productId);

    setCheckedItems((prevCheckedItems) => (
      [...prevCheckedItems,
      { "ProductId": productId, "IsDefault": isChecked, }
      ]));
  };

  let compareTwoArrayOfObjects = (arr1, arr2) => {
    const res1 = arr1.filter((page1) => arr2.find(page2 => page1.ProductId === page2.ProductId))
    return res1
  };

  const handleSaveClick = async () => {
    let ptn = localStorage.getItem("ptn");
    let ids = []
    let newArray = [];
    let results = [];
    let isExistingArr = []
    ptn = JSON.parse(ptn)
    const a = compareTwoArrayOfObjects(checkedItems, ptn);

    if (a?.length > 0) {
      isExistingArr = a.map(item => { return { ...item, IsExisting: true } })
      ids = new Set(isExistingArr.map(item => { return (item.ProductId) }));
      newArray = checkedItems.filter(obj => !ids.has(obj.ProductId));
      results = [...isExistingArr, ...newArray];
      let resData = await post(MANAGEMENT_APIS.MANAGE_PRODUCT_NOTIFICATIONS, results);
      if (resData > 0) {
        setInfoMessage(successMessage)
        getData()
      }
      if (resData < 1) {
        setInfoMessage(errorMessage)
        return getData()
      } return;
    }
    if (checkedItems?.length > 0) {
      let resData = await post(MANAGEMENT_APIS.MANAGE_PRODUCT_NOTIFICATIONS, checkedItems);
      if (resData > 0) {
        setInfoMessage(successMessage)
        return getData()
      }
      if (resData < 1) {
        setInfoMessage(errorMessage)
        return getData()
      } return;
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ p: 1 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>{headingMessage}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: infoMessage?.color || "" }}>{infoMessage?.message || ""}</Typography>
        </Grid>
        {products?.map((product) => {
          return (
            <Grid item xs={12} key={product.ProductId} sx={{ mt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={product.Product}
                    checked={checkedItems?.filter(item => item?.ProductId === product.ProductId)[0]?.IsDefault === true ? true : false}
                    onChange={(e) => handleProductChange(product.ProductId, e.target.checked)}
                    sx={{
                      color: checkedItems?.filter(item => item?.ProductId === product.ProductId)[0]?.IsDefault === true
                        ? "green"
                        : "initial",
                    }}
                  />
                }
                label={
                  product.Product + (product.IsArchived ? "  :  Archived" : "")
                }
                sx={{
                  width: "50%",
                  backgroundColor: "transparent",
                }}
                labelPlacement="end"
                style={{
                  backgroundColor: checkedItems?.filter(item => item?.ProductId === product.ProductId)[0]?.IsDefault === true
                    ? "green"
                    : "transparent",
                  color: checkedItems?.filter(item => item?.ProductId === product.ProductId)[0]?.IsDefault === true ? "white" : "initial",
                }}
              />
            </Grid>
          )
        })}
        <Grid item xs={12}>
          <Button
            sx={{ ml: 50 }}
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
