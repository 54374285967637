import { Grid, Typography } from "@mui/material";
import DataTable, { DataGridAction } from "../../../../components/DataTable";
import { useEffect, useState } from "react";
import useMasterData from "../../../../hooks/useMasterData";

export default function SearchProduct({ actions, rowSelected, }) {
    const [products, setProducts] = useState([]);
    const { getMasterData } = useMasterData();

    const tblOptions = {
        columns: GetColumns(actions),
        dataSource: products,
        rowIdProperty: "ProductId",
        cellStylingHandler: "productCommonCellColour",
        isDisableColumnFilter: true,
        sx: { height: "400px" },
        rowsSelected: function (rows) {
            rowSelected && rowSelected(rows[0]);
        },
    };
    useEffect(() => {
        (async function () {
            let p = await getMasterData(`Products`);
            setProducts(p?.Products);
        })();
    }, []);

    return (
        <>
            <Grid container minWidth={800}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        Total Records: <b> {products && products.length}</b>
                    </Typography>
                    <DataTable {...tblOptions} />
                </Grid>
            </Grid>
        </>
    );
};

function GetColumns(actions) {
    return [
        {
            headerName: "Actions",
            field: "Actions",
            sortable: false,
            width: 80,
            hide: !actions || !actions.length,
            renderCell: function ({ row }) {
                return (actions || []).map(function (ac, i) {
                    return (
                        <DataGridAction
                            row={row}
                            onClick={ac.action}
                            label={ac.label}
                        // startIcon={ac.startIcon}
                        />
                    );
                });
            },
        },
        {
            headerName: "Product",
            field: "DisplayProduct",
            minWidth: 130,
            sortable: false,
        },
        {
            headerName: "AutoEval",
            field: "CanAutoEvaluate",
            valueFormatter: ({ value }) => (value ? "Yes" : "No"),
            minWidth: 200,
            sortable: false,
        },
        {
            headerName: "Evaluate",
            field: "NoEvaluations",
            valueFormatter: ({ value }) => (value ? "Yes" : "No"),
            minWidth: 100,
            sortable: false,
        },
        {
            headerName: "Archived",
            field: "IsArchived",
            valueFormatter: ({ value }) => (value ? "Yes" : "No"),
            minWidth: 130,
            sortable: false,
        },
        {
            headerName: "Hidden",
            field: "HideProduct",
            valueFormatter: ({ value }) => (value ? "Yes" : "No"),
            minWidth: 200,
            sortable: false,
        },
        {
            headerName: "Display",
            field: "DisplayRank",
            minWidth: 100,
            sortable: false,
        },
    ];
}
