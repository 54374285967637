import React from "react";
import DataTable from "../../../components/DataTable";

export default function UnitHistoryTable({ columns, data }) {
  const options = {
    columns: columns || [],
    dataSource: data || [],
    sx: { height: 650 },
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    rowStylingHandler: "sample",
  };
  return <DataTable {...options} />;
}
