import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CONTACTS_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import SubscriptionOverviewTable from "./SubscriptionOverviewTable";

const SubscriptionOverview = ({ formData }) => {
  const { post } = useFetch();
  const [subscriptionList, setSubscriptionList] = useState([]);

  useEffect(() => {
    (async function () {
      let parseData = {};
      let formDatas = localStorage.getItem("subscription-overview")

      if (formDatas !== null && formDatas !== "null" && formDatas !== undefined) {
        parseData = JSON.parse(formDatas);
        document.title = "Subscriptions Overview - " + (parseData?.Customer || "");
      }
      const contactSubscriptionsResponse = await post(`${CONTACTS_APIS.CONTACT_SUBSCRIPTIONS}`,
        {
          ProductId: formData?.ProductId || parseData?.ProductId,
          ParentId: formData?.ParentId || parseData?.ParentId,
          CustomerId: formData?.CustomerId || parseData?.CustomerId,
          SiteSetId: formData?.SiteSetId || parseData?.SiteSetId,
          SiteId: formData?.SiteId || parseData?.SiteId,
          UnitId: formData?.UnitId || parseData?.UnitId,
          ComponentId: formData?.ComponentId || parseData?.ComponentId,
        },
      );
      setSubscriptionList(contactSubscriptionsResponse);
      // localStorage.removeItem("subscription-overview");
    })();
  }, []);

  return (
    <>
      <Typography>
        There are {subscriptionList?.length} subscription(s)
      </Typography>
      <SubscriptionOverviewTable subscriptionList={subscriptionList} />
    </>
  );
};

export default SubscriptionOverview;

