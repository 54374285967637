import { FormActions } from "../../components/Form";
export const ComponentActions = {
  LOAD_DATA: "load-data",
};
export default function ComponentReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === ComponentActions.LOAD_DATA) {
    return { ...action.payload, _ts_key: Date.now() };
  }
  return state;
}
