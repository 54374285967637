import { Button, Grid, Typography } from "@mui/material";
import { useReducer } from "react";
import { MASTER_DATA_APIS } from "../../common/apis";
import Form, { FormActions } from "../../components/Form";
import useFetch from "../../hooks/useFetch";
import { SaveIcon } from "../../icons/ButtonIcons";
import { useRoot } from "../../rootContext";

export default function EditLaboratory({ formData }) {

  const [data, dispatch] = useReducer(SearchFilterReducer, formData);
  const { post } = useFetch();
  const { CanAdministrate } = useRoot();
  //call api for search audit data
  function save() {
    (async function () {
      await post(MASTER_DATA_APIS.EDIT_LABORATORY, {
        ...data,
      });
    })();
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ marginBottom: "20px" }}>
            Please update the laboratory email and mobile contact details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(save, CanAdministrate)}
            data={data}
            dispatch={dispatch}
          />
        </Grid>
      </Grid>
    </>
  );
}

function GetFormSections(save, CanAdministrate) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "Laboratory",
          label: "Laboratory",
          xsCol: 3,
        },
        {
          name: "SimpleLaboratory",
          label: "Simple Name",
          xsCol: 3,
        },
        {
          name: "IsDefault",
          label: "Default",
          xsCol: 2,
          type: "switch",
          disabled: true,
        },
        {
          name: "DisplayRank",
          label: "Display Rank",
          xsCol: 4,
        },
        {
          name: "Email1",
          label: "Email 1",
          xsCol: 4,
        },
        {
          name: "Email2",
          label: "Email 2",
          xsCol: 4,
        },
        {
          name: "Email3",
          label: "Email 3",
          xsCol: 4,
        },
        {
          name: "Mobile1",
          label: "Mobile 1",
          xsCol: 4,
        },
        {
          name: "Mobile2",
          label: "Mobile 2",
          xsCol: 4,
        },
        {
          name: "Mobile3",
          label: "Mobile 3",
          xsCol: 4,
        },
        CanAdministrate && {
          group: [
            {
              xsCol: 10,
              component: function () {
                return (
                  <Typography variant="body1" style={{ fontSize: 13 }}>
                    AU +61 and 1 area code and 8 digits
                  </Typography>
                );
              },
            },
            {
              xsCol: 2,
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={save}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
