import { useTheme } from "@emotion/react";
import { Search, } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useReducer, useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import useMasterData from "../../../hooks/useMasterData";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function SearchComponentModels({
  cancel,
  actions,
  rowSelected,
}) {
  const curTheme = useTheme();
  const { getMasterData } = useMasterData();
  const [fluidsData, setFluidsData] = useState([]);
  const { post } = useFetch();
  const [data, dispatch] = useReducer(SearchFluidsFilterReducer, {});
  const tblOptions = {
    columns: GetColumns(actions),
    dataSource: fluidsData,
    rowIdProperty: "ComponentMakeFormatted",
    isDisableColumnFilter: true,
    rowsSelected: function (rows) {
      rowSelected && rowSelected(rows[0]);
    },
  };

  const formActions = {
    cancel: cancel,
    search: async function () {
      const tempFluidsData = await post(FLEET_APIS.SEARCH_COMPONENT_MODELS, {
        ...data,
        MakeOption: 1,
        ModelOption: 1,
      });
      setFluidsData(tempFluidsData);
    },
  };

  const [masterData, setMasterData] = useState({});

  useEffect(function () {
    (async function () {
      const resp = await getMasterData("FluidTypes");
      setMasterData(resp);
    })();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(data, masterData)}
            data={data}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFooter
            buttons={GetFormButtons(data, formActions)}
            hideDivider
            gmt={0}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}
const GetFormButtons = (data, { addNew, cancel, search }) => [
  {
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <Search />,
  },
];

function GetColumns(actions) {
  return [
    {
      headerName: "Make",
      field: "ComponentMake",
      minWidth: 140,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Model",
      field: "ComponentModel",
      minWidth: 140,
      sortable: false,
      renderCell: renderCellExpand,
    },

    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      flex: 1,
      hide: !actions || !actions.length,
      renderCell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <DataGridAction
              row={row}
              onClick={ac.action}
              label={ac.label}
              startIcon={ac.startIcon}
            />
          );
        });
      },
    },
  ];
}

function GetFormSections(_, { FluidTypes }) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 6,
          name: "Make",
          label: "Make",
        },
        {
          xsCol: 6,
          name: "Model",
          label: "Model",
        },
      ],
    },
  ];
}

function SearchFluidsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
