import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useUser } from "../rootContext";
import useFetch from "../hooks/useFetch";
import EmailIcon from "@mui/icons-material/Email";

const UserMessagesDialog = () => {
  const [msgDetails, setMsgDetails] = useState([]);
  const [initialMsg, setInitialMsg] = useState("Loading...");
  const user = useUser();
  const { get } = useFetch();

  useEffect(() => {
    (async function () {
      const temp = await get(
        `/Management/user-messages?LaboratoryId=${user.DefaultLaboratoryId}`
      );
      setMsgDetails(temp);
      temp.length === 0 && setInitialMsg("No Messages to Display");
    })();
  }, []);

  return (
    <>
      <List
        sx={{
          width: "100%",
          minWidth: "30vw",
          overflow: "auto",
          minHeight: "25vh",
          maxWidth: 360,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {msgDetails?.length > 0 ? (
          msgDetails?.map((msgItem, index) => {
            return (
              <ListItem
                alignItems="flex-start"
                key={index}
                sx={{
                  border: "1px solid #C6C6C6",
                  my: "10px",
                  borderRadius: "5px",
                }}
              >
                <ListItemAvatar>
                  <EmailIcon
                    sx={{
                      marginTop: "-7px",
                      borderRadius: "25px",
                      padding: "10px",
                      backgroundColor: "#D5E3FE",
                      color: "#3666D2",
                      width: "26px",
                      height: "26px",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${msgItem.CreatedBy}: ${new Date(
                    msgItem.Created
                  ).toLocaleString(undefined, {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                  })}`}
                  secondary={
                    <>
                      <Typography
                        sx={{
                          display: "inline",
                          color: "#3666D2",
                          fontWeight: "600",
                        }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {msgItem.Message.split("\r\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            );
          })
        ) : (
          <Typography sx={{ textAlign: "center", fontWeight: "600" }}>
            {initialMsg}
          </Typography>
        )}
      </List>
    </>
  );
};

export default UserMessagesDialog;
