import DataTable from "../../components/DataTable";
import { toLocalDayDateTime } from "../../utils";

export default function EditEventTable({ data, rowSelected, ...props }) {
  const options = {
    ...props,
    columns: Columns,
    dataSource: data,
    scroll: { x: 500, y: 200 },
    rowIdProperty: "EventLogId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
  };
  return <DataTable {...options}></DataTable>;
}

const Columns = [
  {
    headerName: "Scope",
    field: "Scope",
    width: 150,
  },
  {
    headerName: "Type",
    field: "EventType",
    width: 150,
    cellClassName: ({ row }) => {
      if (row.BackColour === "ForestGreen") {
        return "cell-fg-wt";
      }
      if (row.BackColour === "DimGray") {
        return "cell-dg-wt";
      }
      if (row.BackColour === "Red") {
        return "cell-rd-wt";
      }
      if (row.BackColour === "Maroon") {
        return "cell-mn-wt";
      }
      if (row.BackColour === "Purple") {
        return "cell-pr-wt";
      }
      return "";
    },
  },
  {
    headerName: "Occurred",
    field: "EventDate",
    valueFormatter: ({ value }) => toLocalDayDateTime(value),
    width: 180,
  },
  {
    headerName: "Hours",
    field: "UnitHours",
    width: 100,
  },
  {
    headerName: "Meter",
    field: "MeterReading",
    width: 100,
  },
  {
    headerName: "Event",
    field: "Event",
    width: 200,
    flex: 1,
  },
];
