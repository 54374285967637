import { useTheme } from "@emotion/react";
import { Close, Search } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useReducer, useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function SearchUnitModels({ actions, rowSelected }) {

  const curTheme = useTheme();
  const [fluidsData, setFluidsData] = useState([]);
  const { post } = useFetch();
  const [data, dispatch] = useReducer(SearchFluidsFilterReducer, {});
  const tblOptions = {
    columns: GetColumns(actions),
    dataSource: (fluidsData || []).map((x) => ({
      ...x,
      keyProp: x.UnitMakeId + "" + (x.UnitModelFormatted || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    rowsSelected: function (rows) {
      rowSelected && rowSelected(rows[0]);
    },
  };

  const formActions = {
    clear: async function () {
      dispatch({ type: FormActions.RESET_FORM });
    },

    search: async function () {
      const tempFluidsData = await post(FLEET_APIS.SEARCH_UNIT_MODELS, {
        ...data,
        MakeOption: 1,
        ModelOption: 1,
      });
      setFluidsData(tempFluidsData);
    },
  };


  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formActions?.search(data)
    }
  }

  return (
    <>
      <Grid container minWidth={800}>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(handleEnterPress)}
            data={data}
            dispatch={dispatch}
            key={data && data._key}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFooter
            buttons={GetFormButtons(data, formActions)}
            hideDivider
            gmt={0}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}
const GetFormButtons = (data, { clear, search }) => [
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
    startIcon: <Close />,
  },
  {
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <Search />,
  },
];

function GetColumns(actions) {
  return [
    {
      headerName: "Make",
      field: "UnitMake",
      minWidth: 150,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Model",
      field: "UnitModel",
      minWidth: 120,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Formatted Model",
      field: "UnitModelFormatted",
      minWidth: 200,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Verified",
      field: "Verified",
      minWidth: 75,
      maxWidth: 75,
      sortable: false,
    },
    {
      headerName: "Komatsu",
      field: "IsKomatsu",
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: "Komatsu Group",
      field: "IsKomatsuGroup",
      minWidth: 120,
      sortable: false,
    },
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      flex: 1,
      hide: !actions || !actions.length,
      renderCell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <DataGridAction
              row={row}
              onClick={ac.action}
              label={ac.label}
              startIcon={ac.startIcon}
            />
          );
        });
      },
    },
  ];
}

function GetFormSections(handleEnterPress) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 3,
          name: "Make",
          label: "Make",
          handleEnterPress: handleEnterPress,
        },
        {
          xsCol: 3,
          name: "Model",
          label: "Model",
          handleEnterPress: handleEnterPress,
        },
      ],
    },
  ];
}

function SearchFluidsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { _key: Date.now() };
  }
  return state;
}
