import AppDialog from "./AppDialog";

export default function GroupDialog({ open, component, width, title, close }) {
  return (
    <AppDialog
      open={open}
      title={title}
      maxWidth={width || "lg"}
      handleClose={close}
    >
      {component}
    </AppDialog>
  );
}
