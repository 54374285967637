import { Check, Settings } from "@mui/icons-material";
import { Card, Grid, Typography } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { useRoot, useToast } from "../rootContext";
import useFetch from "../hooks/useFetch";
import { FLEET_APIS } from "../common/apis";
import Form, { FormActions } from "../components/Form";
import AppDialog from "../components/AppDialog";
import FormFooter from "../components/FormFooter";
import appResources from "../app-resources/en/Resources.json";
import { ValidateMakeModel } from "../validation/schemas/Make_ModelSchema";
import ManageComponentModels from "../features/quick-details/manage/ManageComponentModels";

export default function EditComponentModel({ componentModel, fetchComponentModels, cancel }) {

    const { post } = useFetch();

    const { CanDoDataMaintenance } = useRoot();
    const { showToast, showErrorToast } = useToast();
    const [data, dispatch] = useReducer(FluidReducer, {});

    const [formErrors, setFormErrors] = useState({});
    const [manageUnitOpen, setManageUnitOpen] = useState(false);

    const actions = {
        save: async function () {
            setFormErrors({});
            ValidateMakeModel(data).then(async function ({ data: req, errors }) {
                if (req) {
                    const response = await post(FLEET_APIS.SAVE_COMPONENT_MODELS, {
                        ComponentMakeId: data?.ComponentMakeId,
                        Make: data?.ComponentMake,
                        Model: data?.ComponentModel,
                        Notes: data?.ComponentMakesNotes,
                        Screen: "Component Models",
                    });
                    if (response.length > 0) {
                        showToast(appResources.COMPONENT_SAVED_SUCCESSFULLY_MSG);
                        await fetchComponentModels(response[0]);
                        cancel && cancel();
                    } else {
                        showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
                    }
                }
                if (errors) {
                    setFormErrors(errors);
                }
            });
        },

        manage: function () {
            setManageUnitOpen(true);
        },
    };

    async function refreshGrid() {
        await fetchComponentModels();
        dispatch({ type: FormActions.RESET_FORM });
    }

    useEffect(() => {
        if (componentModel?.ComponentMakeId > 0) {
            dispatch({ type: FormActions.LOAD_DATA, payload: { ...componentModel } });
        }
    }, [componentModel]);

    return (
        <Card sx={{ p: 1 }}>
            <Typography
                sx={{
                    mb: 0.5,
                    color: "#8B4513",
                }}
            >
                This is for components / parts, and not units : do NOT use unit
                makes and models
            </Typography>
            <Grid container>
                <Grid item xs={12}>
                    <Form
                        sections={GetFormSections()}
                        data={data}
                        dispatch={dispatch}
                        key={data && data._key}
                        errors={formErrors}
                    />
                </Grid>

                {CanDoDataMaintenance && <Grid item xs={12}>
                    <FormFooter
                        footerText={`ComponentMakeId: ${data?.ComponentMakeId || ""}`}
                        buttons={GetFormButtons(actions, componentModel, data,)}
                        hideDivider
                        gmt={0}
                    />
                </Grid>}
            </Grid>

            <AppDialog
                open={manageUnitOpen}
                handleClose={() => setManageUnitOpen(false)}
                title={componentModel?.ComponentMakeFormatted}
                maxWidth="100vw"
            >
                <ManageComponentModels
                    SelectedUnit={componentModel}
                    refreshGrid={refreshGrid}
                    setManageUnitOpen={setManageUnitOpen}
                />
            </AppDialog>
        </Card>
    );
}

const GetFormButtons = ({ manage, save }, componentModel, data,) => [
    {
        variant: "outlined",
        label: "Manage",
        onClick: manage,
        disabled: (componentModel && !componentModel?.ComponentMakeId),
        startIcon: <Settings />,
    },
    {
        variant: "contained",
        label: "Save",
        type: "submit",
        onClick: save,
        disabled: !data.ComponentMake || !data.ComponentModel,
        startIcon: <Check />,
    },
];

function GetFormSections() {
    return [
        {
            xsCol: 12,
            fields: [
                {
                    xsCol: 6,
                    name: "ComponentMake",
                    label: "Make",
                },
                {
                    xsCol: 6,
                    name: "ComponentModel",
                    label: "Model",
                },

                {
                    xsCol: 12,
                    name: "ComponentMakesNotes",
                    label: "Notes",
                    type: "textarea",
                },
            ],
        },
    ];
}

function FluidReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { ...state, [action.payload.name]: action.payload.value };
    }
    if (action.type === FormActions.RESET_FORM) {
        return { _key: Date.now() };
    }
    if (action.type === FormActions.LOAD_DATA) {
        return { ...action.payload, _key: Date.now() };
    }
    return state;
}
