import Form, { FormActions } from "./Form";
import { useEffect, useReducer } from "react";

export default function SelectImageForm({
  onImageDataChanged,
  data: defaultData,
  formErrors,
}) {
  const [data, dispatch] = useReducer(ImageReducer, { DisplayToContacts: true, ...defaultData });
  useEffect(
    function () {
      onImageDataChanged(data);
    },
    [data]
  );

  return (
    <>
      <Form
        sections={GetFormSections(data)}
        data={data}
        spacing={2}
        dispatch={dispatch}
        errors={formErrors}
      />
    </>
  );
}

function GetFormSections(data) {
  return [
    {
      fields: [
        {
          name: "Title",
          label: "Title",
          xsCol: 3,
          autoComplete: true,
        },

        {
          name: "SampleNumber",
          label: "Sample Number",
          xsCol: 3,
          readOnly: "true",
        },
        {
          name: "LightSource",
          label: "Light Source",
          xsCol: 2,
          autoComplete: true,
        },
        {
          name: "Magnification",
          label: "Magnification",
          xsCol: 2,
          autoComplete: true,
        },
        {
          name: "DisplayRank",
          label: "Display Rank",
          type: "number",
          xsCol: 2,
        },
        {
          name: "DisplayToContacts",
          label: "Include in Report",
          type: "switch",
          // className: data.DisplayToContacts ? "red" : "",
          xsCol: 3,
        },

        {
          name: "Comment",
          label: "Description - Leave Empty if the same as title",
          xsCol: 9,
          type: "textarea",
          autoComplete: true,
        },
      ],
    },
  ];
}
const ImageActions = {
  LOAD_DATA: "load-data",
  SELECT_IMAGE: "select-image",
};
function ImageReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === ImageActions.LOAD_DATA) {
    return action.payload;
  }
  return state;
}
